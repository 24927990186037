import React from 'react';
import { ApplicationRoutes } from './routes/routes';
import { SnackbarProvider } from 'notistack';
import GlobalStyle from '../src/style/global';
import { VotesContextProvider } from './hooks/useVotesCount';
import { FiltersContextProvider } from './hooks/useContextFilters';
import { UserProvider } from './hooks/userContext';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <FiltersContextProvider>
          <VotesContextProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              autoHideDuration={3500}
            >
              <ApplicationRoutes />
              <GlobalStyle />
            </SnackbarProvider>
          </VotesContextProvider>
        </FiltersContextProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
