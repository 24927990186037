import styled from '@emotion/styled';

export const TitleBox = styled.div`
  padding: 10px 20px;
`;

export const Title = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
`;

export const OppacityTitle = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const HeaderBox = styled.div`
  height: 70px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  background: #fff;
`;

export const ErrorText = styled.p`
  padding-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #d32f2f;
`;

export const HeaderLeftContent = styled.div``;

export const HeaderCenterContent = styled.div``;

export const HeaderRightContent = styled.div``;

export const HeaderName = styled.h1`
  font-size: 20px;
`;

export const ButtonBox = styled.div`
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  gap: 15px;
  width: 350px;
  height: 76px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  color: #1f1f1f;
  transition: border 0.2s;
  :hover {
    border: 1px solid #afafaf;
  }
`;

export const ButtonRadius = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 330px;
  background: #333333;
  border: none;
  font-size: 100%;
  border-radius: 100px;
  color: #fff;
  transition: all 0.5s;

  :disabled {
    background-color: #e5e5e5;
    color: #999999;
    cursor: auto !important;
    :hover {
      filter: brightness(1) !important;
    }
  }

  cursor: pointer;
  :hover {
    filter: brightness(0.8);
  }
`;
