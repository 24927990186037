import api from "./api";

export const findMatrix = async (params = {}) => {
  return api()
    .get(`/v0/matrix`, {
      params,
    })
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      throw err;
    });
};

export const getMatrixPDF = async (data) => {
  return api().post('/v0/matrix/pdf', data, {
    responseType: 'blob',
  });
};
