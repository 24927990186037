import React from 'react';
import { useStyles } from "./style";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';

import ImageUtils from '../../../imageUrl';

export default function Mappings(props) {
  const classes = useStyles();
  let selectedProductsArray = props.selectedProducts;
  let selectedLabelsArray = props.labels;

  function handleMappingSelect(name) {
    if (selectedProductsArray.includes(name[props.params.id])) {
      const index = selectedProductsArray.indexOf(name[props.params.id]);
      const indexLabel = props.labels.indexOf(name[props.params.label]);
      selectedProductsArray.splice(index, 1);
      selectedLabelsArray.splice(indexLabel, 1);
      props.setLabels([...selectedLabelsArray]);
      return props.setSelectedProducts([...selectedProductsArray]);
    } else {
      props.setLabels([...props.labels, name[props.params.label]]);
      return props.setSelectedProducts([
        ...props.selectedProducts,
        name[props.params.id],
      ]);
    }
  }

  return (
    <Grid
      container
      justify="center"
      style={{
        overflowY: props.loading ? 'hidden' : 'auto',
        maxHeight: '75vh',
        maxWidth: '100vw',
      }}
    >
      {props.loading ? (
        <CircularProgress style={{ marginTop: '20vh' }} />
      ) : props.mappings.Produtos?.length > 0 ? (
        props.mappings.Produtos.map(
          (mappings) =>
            mappings[props.params.id] && (
              <Grid
                container
                direction="row"
                justify="center"
                key={mappings[props.params.id]}
                style={{ flexGrow: 1, heigth: 150, maxWidth: 400, margin: 5 }}
              >
                <Card
                  className={
                    props.selectedProducts.length > 0 &&
                    props.selectedProducts.includes(mappings[props.params.id])
                      ? classes.opacity
                      : classes.root
                  }
                  onClick={() => handleMappingSelect(mappings)}
                  style={{ display: 'flex', cursor: 'pointer' }}
                >
                  <Grid item xs={4}>
                    <img
                      style={{
                        width: 120,
                        height: 120,
                        borderRadius: 100,
                        margin: 5,
                        objectFit: 'cover',
                      }}
                      src={ImageUtils.getImage(mappings.nome_arquivo)}
                      crossOrigin="anonymous"
                      alt=""
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CardContent style={{ padding: 16 }}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        style={{ height: 80 }}
                      >
                        <Grid item xs={9}>
                          <Typography className={classes.cardText}>
                            {mappings[props.params.label]}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            className={classes.cardText}
                            style={{ opacity: 0.9 }}
                          >
                            {mappings.Qt_votos_aprovados +
                              mappings.Qt_votos_reprovados}
                            /{mappings.Qt_produtos}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <LinearProgress
                            value={
                              ((mappings.Qt_votos_aprovados +
                                mappings.Qt_votos_reprovados) *
                                100) /
                              mappings.Qt_produtos
                            }
                            variant="determinate"
                            classes={{
                              root: classes.progressLabel,
                              bar: classes.bar,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Card>
              </Grid>
            )
        )
      ) : (
        <Grid container justify="center" alignItems="center" direction="row">
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: 'Flama',
                color: '#4C4C4C',
                display: 'flex',
                justifyContent: 'center',
                fontSize: 32,
              }}
            >
              Sem Opções Para Essa Coleção
            </Typography>
          </Grid>
          <span role="img" className={classes.emoji} aria-label="Shrug">
            🤷
          </span>
        </Grid>
      )}
    </Grid>
  );
}
