import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  DialogHeader,
  Description,
  AutocompleteGroup,
  PriceTextField
} from './utils';

import addIcon from '../../../assets/icons/add-icon.svg';

import {
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { createProduct as createProductService } from '../../../services/duplicateProductService';

import useFilters from '../../../hooks/useFilters';
import { useUser } from '../../../hooks/useUser';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

function CreateProduct({
  lines,
  groups,
  subGroups,
  colors,
  stylists,
  productTypes,
  tiposFarm,
  suppliers,
  racks,
  segments,
  coordinates,
  getMappings
}) {
  const { getMainBrandFromUser } = useUser();
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const { isUserFromFabula, isUserFromFarmBR } = useUser();
  const filterData = useFilters();
  const collection = filterData.getParamFromUrl('filter[colecao]');
  const { id_marca: brandFromUser } = getMainBrandFromUser();
  const initialCreatedProduct = {
    ds_produto: null,
    estampa_cor: null,
    grupo_produto: null,
    id_estampa_cor: null,
    id_grupo_produto: null,
    id_linha: null,
    id_marca: brandFromUser,
    id_estilista: null,
    id_fornecedor: null,
    linha: null,
    no_subgrupo: null,
    no_estampa_farm: null,
    no_colecao: collection,
    no_tipo_produto: null,
    no_arara: null,
    no_tipo_farm: null,
    produto: null,
    vl_preco: null,
    no_segmento: null
  };

  const [createdProduct, setCreatedProduct] = useState(initialCreatedProduct);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const filterOptions = createFilterOptions({
    limit: 200
  });

  const updateProduct = async (event, value, label, id) => {
    setCreatedProduct({
      ...createdProduct,
      [label]: value[label],
      [id]: value[id]
    });
  };

  const createProductFunction = async () => {
    const { id_marca: brandFromUser } = getMainBrandFromUser();
    await createProductService({
      brandFromUser,
      ...createdProduct
    });

    setOpen(false);

    return await getMappings();
  };

  const requiredFields = [
    'ds_produto',
    'grupo_produto',
    'no_subgrupo',
    'desc_estampa_cor',
    'estilista',
    'linha',
    'vl_preco'
  ];

  useEffect(() => {
    const allFieldsFilled = requiredFields.every(
      (field) => createdProduct[field] !== null && createdProduct[field] !== ''
    );
    setDisable(!allFieldsFilled);
  }, [createdProduct, requiredFields]);

  const autocompleteConfig = {
    filterOptions,
    size: 'small',
    width: 300,
    variant: 'outlined'
  };

  const mappingConfig = [
    {
      label: 'Linhas*',
      value: 'linha',
      id: 'id_linha',
      options: lines,
      getOptionLabel: (options) => options.linha
    },
    {
      label: 'Grupos*',
      value: 'grupo_produto',
      id: 'id_grupo_produto',
      options: groups,
      getOptionLabel: (options) => options.grupo_produto
    },
    {
      label: 'Subgrupos*',
      value: 'no_subgrupo',
      id: 'no_subgrupo',
      options: subGroups,
      getOptionLabel: (options) => options.no_subgrupo
    },
    {
      label: 'Cores*',
      value: 'desc_estampa_cor',
      id: 'id_estampa_cor',
      options: colors,
      getOptionLabel: (option) => option.desc_estampa_cor
    },
    {
      label: 'Estilistas*',
      value: 'estilista',
      id: 'id_estilista',
      options: stylists,
      getOptionLabel: (options) => options.estilista
    },
    {
      label: 'Tipos de Produto',
      value: 'no_tipo_produto',
      id: 'no_tipo_produto',
      options: productTypes,
      getOptionLabel: (options) => options.no_tipo_produto
    },
    {
      label: 'Tipo Farm',
      value: 'no_tipo_farm',
      id: 'no_tipo_farm',
      options: tiposFarm,
      getOptionLabel: (options) => options.no_tipo_farm
    },
    {
      label: 'Fornecedor',
      value: 'fornecedor',
      id: 'id_fornecedor',
      options: suppliers,
      getOptionLabel: (options) => options.fornecedor
    },
    {
      label: 'Arara',
      value: 'no_arara',
      id: 'no_arara',
      options: racks,
      getOptionLabel: (options) => options.no_arara
    }
  ];

  if (isUserFromFabula()) {
    const estilistasIndex = mappingConfig.findIndex(
      (item) => item.id === 'id_estilista'
    );
    if (estilistasIndex !== -1) {
      mappingConfig.splice(estilistasIndex + 1, 0, {
        label: 'Segmentos',
        value: 'no_segmento',
        id: 'no_segmento',
        options: segments,
        getOptionLabel: (options) => options.no_segmento
      });
    }
  }

  if (isUserFromFarmBR()) {
    const subgruposIndex = mappingConfig.findIndex(
      (item) => item.id === 'no_subgrupo'
    );

    mappingConfig.splice(3, 1);

    mappingConfig.splice(subgruposIndex + 1, 0, {
      label: 'Coordenados*',
      value: 'no_estampa_farm',
      id: 'no_estampa_farm',
      options: coordinates,
      getOptionLabel: (options) => options.no_estampa_farm
    });

    mappingConfig.splice(subgruposIndex + 2, 0, {
      label: 'Cores*',
      value: 'desc_estampa_cor',
      id: 'id_estampa_cor',
      options: colors,
      getOptionLabel: (options) => options.desc_estampa_cor.toString()
    });
  }

  return (
    <div>
      <Tooltip title="Criar Produto">
        <IconButton
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          <img src={addIcon} alt="create button" style={{ height: '15px' }} />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="xs" onClose={handleClose} open={open}>
        <DialogHeader
          handleClose={handleClose}
          disable={disable}
          createProductFunction={createProductFunction}
        />
        <DialogContent dividers>
          <Grid container justify="center">
            <Grid item xs={12} style={{ marginTop: -10 }}>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{ color: 'grey' }}
              >
                * Campos Obrigatórios
              </Typography>
            </Grid>
            <Description
              createdProduct={createdProduct}
              setCreatedProduct={setCreatedProduct}
            />
            <PriceTextField
              createdProduct={createdProduct}
              setCreatedProduct={setCreatedProduct}
            />
            <Grid item style={{ marginTop: 10 }}>
              {mappingConfig.map((mapping) => (
                <Grid item style={{ marginTop: 10 }} key={mapping.label}>
                  <AutocompleteGroup
                    {...autocompleteConfig}
                    label={mapping.label}
                    onChange={(e, v) =>
                      updateProduct(e, v, mapping.value, mapping.id)
                    }
                    getOptionLabel={(option) => option[mapping.value] || ''}
                    options={mapping.options}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default withSnackbar(CreateProduct);
