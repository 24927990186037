import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    cardRoot: {
      width: '20vw',
      minHeight: '16vh',
      margin: '10px',
      backgroundColor: '#white'
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center'
    }
  }),
  { index: 1 }
);
