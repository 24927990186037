import React from 'react';

import { useStyles } from "./style";

import { Dialog, IconButton, Grid, List, Slide } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Mousewheel } from 'swiper/core';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css';
// swiper core styles
import 'swiper/swiper.min.css';
// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import SwipeableTemporaryDrawer from '../swipeableTemporaryDrawer';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getResizedImage } from '../../../consts';

SwiperCore.use([Pagination, Navigation, Mousewheel]);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PDPSwiperZoom({
  product,
  images,
  openZoom,
  firstImage,
  renderVote,
  setOpenZoom,
  setFirstImage,
  obs,
  preco,
  precoAlvo,
  comment,
  grouping,
  classification,
  classificationOpts,
  vote,
  voteOpts,
}) {
  const classes = useStyles();
  const slides = [];

  let imageHeight = parseInt(window.innerHeight);

  const handleClose = () => {
    setFirstImage(null);
    setOpenZoom(false);
  };

  function handleImageZoom(image, firstImage) {
    if (image) {
      image = image.split(',');
      image.splice(image.indexOf(firstImage), 1);
      image.unshift(firstImage);
      image.forEach((element) => {
        let cover = getResizedImage(element);
        slides.push(
          <SwiperSlide key={`${element}`} className={classes.swipperSlide}>
            <TransformWrapper>
              <TransformComponent>
                <Grid
                  container
                  style={{
                    height: imageHeight,
                    width: window.innerWidth,
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'grab',
                  }}
                >
                  <img
                    src={cover}
                    style={{ height: imageHeight }}
                    alt={`product-${element}`}
                    crossOrigin="anonymous"
                  />
                </Grid>
              </TransformComponent>
            </TransformWrapper>
          </SwiperSlide>
        );
      });
      return slides;
    }
  }

  function swiperProps(images, prop) {
    if (prop === 'navigation') {
      if (images) {
        if (images.split(',').length > 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (prop === 'loop') {
      if (images) {
        if (images.split(',').length > 3) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  return (
    <Dialog
      fullScreen
      open={openZoom}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <List className={classes.list}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          className={classes.btnsContainer}
        >
          <Grid item className={classes.headerIconBtn}>
            {renderVote}
          </Grid>

          <SwipeableTemporaryDrawer
            product={product}
            obs={obs}
            vote={vote}
            preco={preco}
            precoAlvo={precoAlvo}
            comment={comment}
            grouping={grouping}
            voteOpts={voteOpts}
            classification={classification}
            classificationOpts={classificationOpts}
          />
          <IconButton
            className={classes.headerIconBtn}
            edge="start"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Swiper
          id="main"
          slidesPerView={1}
          loop={swiperProps(images, 'loop')}
          navigation={swiperProps(images, 'navigation')}
          allowTouchMove={false}
        >
          {handleImageZoom(images, firstImage)}
        </Swiper>
      </List>
    </Dialog>
  );
}
