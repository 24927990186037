import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      position: 'relative',
      backgroundColor: '#fff'
    },
    toolBar: {
      padding: '0',
      minHeight: 40,
      color: 'black',
      backgroundColor: 'white'
    },
    productTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '100%',
      fontSize: 11,
      textTarnsform: 'uppercase'
    },
    productInfo: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '150%',
      fontSize: 14,
      marginLeft: 10
    },
    voteIcon: {
      width: 24,
      height: 24
    },
    menuItem: {
      width: 120,
      height: 40
    },
    menuVoteOpt: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontStyle: 'normal',
      lineHeight: 14,
      letterSpacing: '0em'
    }
  }),
  { index: 1 }
);
