import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontFamily: 'Flama medium',
      fontSize: 16
    },
    chip: {
      margin: theme.spacing(0.5),
      fontFamily: 'Flama',
      fontSize: 12
    },
    panel: {
      boxShadow: 'none',
      minWidth: 300
    }
  }),
  { index: 1 }
);
