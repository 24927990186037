import React from 'react';
import { Button, Title } from './style';
import LogoSoma from '../../../assets/img/LogoSoma.svg';

export default function LoginButton(props) {
  return (
    // eslint-disable-next-line react/prop-types
    <Button onClick={props.handleClick}>
      <img src={LogoSoma} alt="Logo-Soma" />
      <Title>Iniciar sessão pelo Soma Auth</Title>
    </Button>
  );
}
