import React, { useState, useEffect } from 'react';
import { useStyles } from './style';
import useFilters from '../../hooks/useFilters';
import Personalizado from '../../components/pages/personalizado/index';
import Mappings from '../../components/group/mappings/index';

import { withSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';

import arrowLeft from '../../assets/icons/arrowLeft-icon.svg';
import download from '../../assets/icons/download-icon.svg';
import summary from '../../assets/icons/summary-icon.svg';

import {
  findMappings,
  findMappingOptions
} from '../../services/mappingService';
import { importCSV } from '../../services/voteService';
import { useRoutes } from '../../hooks/useRoutes';
import { useUser } from '../../hooks/useUser';

function Aprovacao() {
  const { isUserFromFarmBR } = useUser();
  const routes = useRoutes();
  const classes = useStyles();
  const filterData = useFilters();
  const [loadingFilterOptions, setLoadingFilterOptions] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mappings, setMappings] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const params = {
    Coordenados: {
      label: 'coordenado',
      id: isUserFromFarmBR() ? 'coordenado' : 'id_estampa_cor'
    },
    Linhas: { label: 'Linha', id: 'id_linha' },
    Segmentos: { label: 'Segmento', id: 'Segmento' },
    Personalizado: { label: 'Personalizado', id: 'Personalizado' }
  };

  async function getCSV() {
    setLoadingCSV(true);
    const { getFilterCollection, getFilterBrand } = filterData;
    const response = await importCSV({
      ...getFilterBrand(),
      ...getFilterCollection()
    });
    if (response !== [] && response.data?.length > 0) {
      setLoadingCSV(false);
      const csvData = objectToCSV(response.data);
      const blob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv;charset=ANSI'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', `${Object.values(getFilterCollection())}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      setLoadingCSV(false);
    }
  }

  function objectToCSV(data) {
    const csvRows = [];

    // get headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(';'));

    // loop over values
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(';'));
    }

    // join and return all lines
    return csvRows.join('\n');
  }

  async function getOptions() {
    setLoadingFilterOptions(true);
    const response = await findMappingOptions();
    setFilterOptions(response);
    return setLoadingFilterOptions(false);
  }

  async function getMappings(grouping) {
    const order = grouping;
    let filtros = filterData.buildFilters();

    if (!filterData.isGroupingPersonalizado()) {
      filtros = {
        agrupamento: filtros['agrupamento'],
        'filter[colecao]': filtros['filter[colecao]'],
        'filter[id_marca]': filtros['filter[id_marca]'],
        XAxis: isUserFromFarmBR()
          ? { label: 'Coordenado', value: 'C' }
          : { label: 'Cor', value: 'C' },
        YAxis: { label: 'Grupo', value: 'G' }
      };
    }
    const orderParam = !filterData.isGroupingPersonalizado() && {
      [`order[${order}]`]: 'asc'
    };
    if (
      filterData.isGroupingPersonalizado() &&
      Object.keys(filtros).length <= 5
    ) {
      setMappings([]);
      return;
    } else {
      setLoading(true);
      try {
        const response = await findMappings({
          pageStart: 1,
          pageSize: 500,
          ...orderParam,
          ...filtros
        });

        if (response.data.Produtos.length > 0) {
          setMappings(response.data);
        } else {
          setMappings([]);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const colecao = filterData.getParamFromUrl('filter[colecao]');
    const agrupamento = filterData.getParamFromUrl('agrupamento').join();
    if (colecao.length < 1 || agrupamento.length < 1) {
      return routes.home();
    }
    filterData.setFilters({
      ...filterData.filters,
      collection: colecao,
      grouping: agrupamento
    });
    getOptions();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (filterData.filters.collection) {
      getMappings(filterData.filters.grouping);
    }
  }, [filterData.filters]); //eslint-disable-line

  useEffect(() => {
    if (filterOptions) {
      let collection = filterData.loadCollection();
      let grouping = filterData.loadGrouping();

      if (collection && grouping) {
        filterData.setFilters({
          ...filterData.filters,
          collection: collection || '',
          grouping: grouping || ''
        });
      }

      if (filterData.isGroupingPersonalizado()) {
        filterData.clearFilters({
          grouping: filterData.filters.grouping,
          collection: filterData.filters.collection,
          selecteds: filterData.filters.selecteds,
          XAxis: isUserFromFarmBR()
            ? { label: 'Coordenado', value: 'C' }
            : { label: 'Cor', value: 'C' },
          YAxis: { label: 'Grupo', value: 'G' }
        });
      }
    }
    // eslint-disable-next-line
  }, [filterData.filters.grouping.length > 0]);

  function SelectAll() {
    let arr = [];
    let arrLabels = [];
    if (selectedProducts.length === Object.keys(mappings.Produtos).length) {
      setLabels([]);
      return setSelectedProducts([]);
    } else {
      Object.values(mappings.Produtos).forEach((opt) => {
        arr.push(opt[params[filterData.filters.grouping].id]);
        arrLabels.push(opt[params[filterData.filters.grouping].label]);
      });
      setLabels([...arrLabels]);
      setSelectedProducts([...arr]);
    }
  }

  function checkedSelectAll() {
    if (
      mappings &&
      mappings.Produtos &&
      selectedProducts.length === Object.keys(mappings.Produtos).length
    ) {
      return true;
    } else {
      return false;
    }
  }

  const vote = () => {
    let filters = filterData.buildFilters();

    if (filterData.isGroupingPersonalizado()) {
      const params = new URLSearchParams(filters);
      routes.routes.goToEspecifcRoute(`/aprovacao/vote?${params}`);
    } else {
      filterData.setFilters({
        ...filterData.filters,
        lines: []
      });
      delete filters['filter[id_linha]'];

      if (filterData.isGroupingSegmentos()) {
        filters = {
          ...filters,
          'filter[XAxis]': 'Tipo Farm',
          'filter[YAxis]': 'Preço Venda'
        };
      } else {
        filters = {
          ...filters,
          'filter[XAxis]': isUserFromFarmBR() ? 'Coordenado' : 'Cor',
          'filter[YAxis]': 'Grupo'
        };
      }

      const params = new URLSearchParams(filters);
      routes.routes.goToEspecifcRoute(
        `/aprovacao/vote?selecionados=${selectedProducts}&label=${labels.join()}&${params}`
      );
    }
  };

  useEffect(() => {
    if (filterData.isGroupingPersonalizado()) {
      setIsDisabled(!(mappings.Produtos?.length > 0));
    } else {
      setIsDisabled(!!(selectedProducts.length <= 0));
    }
  }, [selectedProducts, mappings]); //eslint-disable-line

  function Header() {
    return (
      <Grid
        item
        container
        justify="space-around"
        alignItems="center"
        className={classes.painel}
        xs={12}
      >
        <Grid item container justify="flex-start" xs={4}>
          <IconButton
            aria-label="Voltar"
            className={classes.whiteButton}
            onClick={() => routes.routes.home()}
          >
            <img src={arrowLeft} alt="arrow-left" className={classes.icon} />
          </IconButton>
        </Grid>
        <Grid item container justify="center" xs={4}>
          <Typography className={classes.title}>APROVAÇÃO DE VISUAL</Typography>
        </Grid>
        <Grid item container justify="flex-end" xs={4}>
          {filterData.filters.collection &&
            !filterData.isGroupingPersonalizado() && (
              <>
                <Tooltip title="Página de Resumo" placement="top">
                  {
                    <IconButton
                      onClick={() =>
                        routes.routes.goToEspecifcRoute(
                          `/aprovacao/summary?filter[colecao]=${filterData.filters.collection}&agrupamento=Linhas`
                        )
                      }
                      style={{ marginRight: 20 }}
                    >
                      <img
                        src={summary}
                        alt="summary"
                        className={classes.icon}
                      />
                    </IconButton>
                  }
                </Tooltip>
                <Tooltip title="Download .csv" placement="top">
                  {loadingCSV ? (
                    <CircularProgress />
                  ) : (
                    <IconButton
                      onClick={() => getCSV()}
                      style={{ marginRight: 20 }}
                    >
                      <img
                        src={download}
                        alt="download"
                        className={classes.icon}
                      />
                    </IconButton>
                  )}
                </Tooltip>
              </>
            )}
          <Button
            disabled={isDisabled}
            variant="contained"
            className={classes.voteBtn}
            style={{
              color: 'white',
              background: !isDisabled ? 'rgb(0, 0, 0, 1)' : 'rgb(0, 0, 0, 0.4)'
            }}
            onClick={vote}
          >
            INICIAR
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {Header()}
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="flex-start"
        className={classes.root}
      >
        <Grid item xs={12} className={classes.gridContainer}>
          {!loadingFilterOptions && filterOptions.length > 0 ? (
            filterOptions.map((opt) => {
              return (
                <Button
                  key={`${opt}-grouping`}
                  disabled={filterData.filters.grouping === opt || loading}
                  className={
                    filterData.filters.grouping !== opt
                      ? classes.chip
                      : classes.selectedChip
                  }
                  onClick={() => {
                    setSelectedProducts([]);
                    setMappings([]);
                    filterData.clearFilters({
                      grouping: opt,
                      collection: filterData.filters.collection,
                      selecteds: filterData.filters.selecteds,
                      XAxis: isUserFromFarmBR()
                        ? { label: 'Coordenado', value: 'C' }
                        : { label: 'Cor', value: 'C' },
                      YAxis: { label: 'Grupo', value: 'G' }
                    });
                  }}
                >
                  <Typography className={classes.chipText}>{opt}</Typography>
                </Button>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </Grid>

        {!loading && !filterData.isGroupingPersonalizado() && (
          <Grid item xs={3} className={classes.gridContainer}></Grid>
        )}

        <Grid item xs={6} className={classes.gridContainer}>
          {filterData.isGroupingPersonalizado() ? (
            loading ? (
              <CircularProgress />
            ) : (
              mappings.Produtos?.length > 0 && (
                <Typography className={classes.infoLabel}>
                  {mappings['Total Produtos Inicial']} Cadastrados /{' '}
                  {mappings['Total Produtos Votados']} Votados /{' '}
                  {mappings['Total Produtos Reprovados']} Reprovados /{' '}
                  {mappings['Total Produtos Novos']} Novos
                </Typography>
              )
            )
          ) : (
            !loading &&
            mappings.Produtos?.length > 0 && (
              <Typography className={classes.infoLabel}>
                {mappings['Total Produtos Inicial']} Cadastrados /{' '}
                {mappings['Total Produtos Votados']} Votados /{' '}
                {mappings['Total Produtos Reprovados']} Reprovados /{' '}
                {mappings['Total Produtos Novos']} Novos
              </Typography>
            )
          )}
        </Grid>

        {!loading &&
          !filterData.isGroupingPersonalizado() &&
          mappings.Produtos?.length > 0 && (
            <Grid item xs={3} className={classes.gridContainer}>
              <Checkbox
                style={{ color: 'black' }}
                onClick={() => SelectAll()}
                checked={checkedSelectAll()}
              />
              <Typography className={classes.infoLabel}>
                Selecionar Todos
              </Typography>
            </Grid>
          )}

        <Grid item xs={12} className={classes.gridContainer}>
          {filterData.isGroupingPersonalizado() ? (
            <>
              <Personalizado filterData={filterData} />
            </>
          ) : (
            <Mappings
              params={params[filterData.filters.grouping]}
              loading={loading}
              mappings={mappings}
              setLabels={setLabels}
              labels={labels}
              setSelectedProducts={setSelectedProducts}
              selectedProducts={selectedProducts}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default withSnackbar(Aprovacao);
