import { ButtonRadius, ErrorText } from '../../styled/main';
import { Loading } from '../Loading/index';
import React from 'react';

export default function NoAuthorizedButton({
  title,
  handleClick,
  disabled,
  errorText,
  isLoading
}) {
  return (
    <>
      <ButtonRadius onClick={handleClick} disabled={disabled}>
        {isLoading ? <Loading size={20} /> : title}
      </ButtonRadius>
      <ErrorText>{errorText}</ErrorText>
    </>
  );
}
