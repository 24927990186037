import { useCallback, useContext, useMemo } from 'react';
import jwt from 'jwt-decode';
import { UserContext } from './userContext';
import { authGuard } from '../guard/AuthGuard';
import { useRoutes } from './useRoutes';
import { validEmails } from '../utils/usersValidEmails';
import { maisControleService, reloadServices } from '../services/axiosServer';

export function useUser() {
  const { user, setUser, loading, setLoading, handleUserRoles } =
    useContext(UserContext);

  const { routes } = useRoutes();

  const roles = useMemo(() => {
    if (user) {
      return user.roles;
    }
    return [];
  }, [user]);

  const checkRedirectPage = useCallback(() => {
    const redirect = localStorage.getItem('redirect-page');
    localStorage.removeItem('redirect-page');
    if (
      redirect &&
      !redirect.includes('login') &&
      !redirect.includes('logout')
    ) {
      window.location.href = redirect;
    } else {
      routes.home();
    }
  }, [routes]);

  const validateUserEmail = (userEmail) => {
    const userEmailAdress = userEmail.split('@')[1];
    const validation = validEmails.find((email) =>
      userEmailAdress.includes(email)
    );
    if (!validation) return false;
    return true;
  };

  const handleLogin = useCallback(
    async (userCode) => {
      setLoading(true);
      try {
        if (userCode !== undefined && userCode !== null) {
          const response = await authGuard.requestToken(userCode);
          const userCoded = await jwt(response.id_token);
          if (!response) {
            throw new Error('Failed to load token');
          }
          const validation = validateUserEmail(userCoded.email);
          if (validation) {
            authGuard.setTokenToCookie({
              id_usuario_soma_auth: userCoded.sub,
              token: response.id_token,
              refresh_token: response.refresh_token,
              exp: userCoded.exp
            });
            reloadServices();
            await handleUserRoles(true);
            checkRedirectPage();
          } else {
            routes.emailInvalid();
          }
        } else {
          authGuard.redirectToLogin();
        }
      } catch (error) {
        console.error(error);
        let errMessage = 'Falha ao realizar o login.';
        if (error.message) {
          errMessage = error.message;
          return errMessage;
        }
        if (error.response) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          errMessage =
            typeof error.response.data.message === 'string'
              ? error.response.data.message
              : JSON.stringify(error.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [checkRedirectPage, setLoading, setUser, routes]
  );

  const getUserSomaAuthId = () => {
    const token = authGuard.getLocalToken();
    return token?.id_usuario_soma_auth;
  };

  const buildUserRegisterBody = (userValue, isUserType) => {
    if (isUserType) {
      const userSelected = userValue;
      return {
        no_login: `${userSelected.given_name.toLowerCase()}.${userSelected.family_name.toLowerCase()}`,
        no_usuario: `${userSelected.given_name} ${userSelected.family_name}`,
        id_usuario_soma_auth: userSelected.sub,
        no_apelido: `${userSelected.given_name.toLowerCase()}.${userSelected.family_name.toLowerCase()}`,
        email: userSelected.email,
        inativo: false
      };
    } else {
      const userSelected = userValue;
      return {
        no_login: `${userSelected.firstName.toLowerCase()}.${userSelected.surName.toLowerCase()}`,
        no_usuario: `${userSelected.firstName} ${userSelected.surName}`,
        id_usuario_soma_auth: userSelected.id,
        no_apelido: `${userSelected.firstName.toLowerCase()}.${userSelected.surName.toLowerCase()}`,
        email: userSelected.email,
        inativo: false
      };
    }
  };

  const registerUserToAccessControl = async (user, isUserType) => {
    try {
      const userData = buildUserRegisterBody(user, isUserType);
      const isUserAlreadyRegistered =
        await maisControleService.getUsersBySomaAuthId(
          userData.id_usuario_soma_auth
        );
      if (isUserAlreadyRegistered) {
        return isUserAlreadyRegistered;
      } else {
        const userResponse = await maisControleService.createUser(userData);
        return userResponse;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = () => {
    const userData = localStorage.getItem('user');
    try {
      if (userData) return JSON.parse(userData);
    } catch (error) {
      console.error('getUser', error);
    }
  };

  const isUserAdmin = useMemo(
    () =>
      roles && roles.find((role) => role.papel.no_papel === 'Administrador'),
    [roles]
  );

  const isUserFromFarmBR = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'FARM BR');
    }
  }, []);

  const isUserFromNV = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'BYNV');
    }
  }, []);

  const isUserFromHering = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'HERING');
    }
  }, []);

  const isUserFromFarmUsa = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.includes((role) => role.no_marca === 'FARM USA');
    }
  }, []);

  const isUserFromAnimaleJeans = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.includes(
        (role) =>
          role.no_marca === 'ANIMALE' || role.no_marca === 'ANIMALE JEANS'
      );
    }
  }, []);

  const isUserFromAnimaleBR = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.includes((role) => role.no_marca === 'ANIMALE');
    }
  }, []);

  const isUserFromMariaFilo = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'MARIA FILO');
    }
  }, []);

  const isUserFromCrisBarros = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'CRIS BARROS');
    }
  }, []);

  const isUserFromFabula = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'FABULA');
    }
  }, []);

  const isUserFromFoxton = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      return rolesLocale.some((role) => role.no_marca === 'FOXTON');
    }
  }, []);

  const getMainBrandFromUser = useCallback(() => {
    const rolesLocale = authGuard.getLocalUser()?.roles;
    if (rolesLocale && rolesLocale.length > 0) {
      if (isUserFromFarmBR()) {
        const farmBrand = rolesLocale.find(
          (role) => role.id_marca_atelier === 12
        );
        return {
          id_marca: farmBrand.id_marca_atelier,
          no_marca: farmBrand.no_marca
        };
      }
      if (isUserFromAnimaleBR()) {
        const animaleBrand = rolesLocale.find(
          (role) => role.id_marca_atelier === 6
        );
        return {
          id_marca: animaleBrand.id_marca_atelier,
          no_marca: animaleBrand.no_marca
        };
      }
      return {
        id_marca: rolesLocale[0].id_marca_atelier,
        no_marca: rolesLocale[0].no_marca
      };
    }
    return {
      id_marca: undefined,
      no_marca: 'undefined'
    };
  }, [isUserFromFarmBR, isUserFromAnimaleBR]);

  return {
    getUser,
    user,
    setUser,
    roles,
    getUserSomaAuthId,
    handleLogin,
    registerUserToAccessControl,
    isUserAdmin,
    loading,
    setLoading,
    getMainBrandFromUser,
    isUserFromFabula,
    isUserFromMariaFilo,
    isUserFromAnimaleBR,
    isUserFromFarmUsa,
    isUserFromAnimaleJeans,
    isUserFromHering,
    isUserFromFarmBR,
    isUserFromNV,
    isUserFromCrisBarros,
    isUserFromFoxton
  };
}
