import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import {
  Checkbox,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Pagination from "@material-ui/lab/Pagination";

export default function FilterData(props) {
  const classes = useStyles();
  const types = props.types
  const [currentTypes, setCurrentTypes] = useState([])

  const filterOptions = createFilterOptions({
    limit: 10,
  });

  const getUniqueList = (list = [], otherList = []) => {
    return [...new Set([...list, ...otherList])];
  };

  const getFromList = (value, list, fieldName) => {
    return list.find((item) => `${item[fieldName]}` === `${value}`);
  };

  const includes = (value, filterName) => {
    return props.filters[filterName]?.find((vl) => `${vl}` === `${value}`);
  };

  useEffect(() => {
    setCurrentTypes(props.currentTypes.map(type => {
      type.checked = !!includes(type[props.value], props.type, type[props.label]) || (props.isGroupingType() && !!includes(type[props.value], "selecteds", type[props.label]))
      if (type.checked) {
        if (props.isGroupingType()) {
          props.filterData.addFilterLabel({ id: type[props.value], label: type[props.label], filtro: "selecteds", value: type[props.value] })
        }
      }
      return type
    }))
  }, [])//eslint-disable-line

  useEffect(() => {
    setCurrentTypes(props.currentTypes.map(type => {
      type.checked = !!includes(type[props.value], props.type, type[props.label]) || (props.isGroupingType() && !!includes(type[props.value], "selecteds", type[props.label]))
      if (type.checked) {
        if (props.isGroupingType()) {
          props.filterData.addFilterLabel({ id: type[props.value], label: type[props.label], filtro: "selecteds", value: type[props.value] })
        }
      }
      return type
    }))
  }, [props.currentTypes])//eslint-disable-line

  return (
    <>
      <ExpansionPanel
        style={{ marginTop: 1 }}
        className={classes.panel}
        expanded={props.blockType ? false : undefined}
      >
        <ExpansionPanelSummary style={{ minHeight: 60 }} expandIcon={!props.blockType && <ExpandMoreIcon />}>
          {(props.isGroupingType() && props.filters.selecteds?.length > 0) ||
            props.filters[props.type]?.length > 0 ? (
            <div style={{ maxWidth: 300 }}>
              <Grid container justify="flex-start">
                <Typography className={classes.text} > {props.title}</Typography>
                <Grid item xs={12}>
                  {types?.length > 0 &&
                    getUniqueList(
                      props.filters[props.type],
                      props.isGroupingType() ? props.filters.selecteds : []
                    ).map((type, i) => {
                      const typeData = getFromList(type, types, props.value);

                      return (
                        typeData && typeData[props.label] && (
                          <Chip
                            key={`chip${typeData[props.label]}`}
                            className={classes.chip}
                            index={i}
                            label={typeData[props.label]}
                            color='primary'
                          />
                        )
                      );
                    })}
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography className={classes.text}> {props.title} </Typography>
          )}
        </ExpansionPanelSummary>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Pagination
              page={props.getCurrentPage(props.type)}
              count={props.TypesNumbers.length}
              onChange={(e, newPage) => props.handlePage(props.type, newPage)}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              filterOptions={filterOptions}
              size="small"
              disableClearable
              options={props.types}
              getOptionLabel={options => options[props.label] && options[props.label]}
              renderInput={(type) => <TextField {...type} style={{ width: 150, marginRight: 150 }} label={props.title} variant="outlined" />}
              onChange={(e, v) => props.changeFilter(v[props.value], props.type, v[props.label])}
            />
          </Grid>
        </Grid>
        <ExpansionPanelDetails>
          <List>
            {currentTypes.map((type) => (
              type[props.label] && <ListItem
                onClick={() => {
                  let checked = props.changeFilter(type[props.value], props.type, type[props.label])
                  type.checked = checked
                }}
                button
                key={type[props.value]}
              >
                <ListItemIcon>
                  <Checkbox
                    color='primary'
                    checked={type.checked}
                  />
                </ListItemIcon>
                <ListItemText primary={type[props.label]} />
              </ListItem>
            ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel >
    </>
  )
}