/* eslint-disable react/prop-types */
import React from 'react';
import { useStyles } from './style';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import { useRoutes } from '../../../hooks/useRoutes';

function MenuCard({title, redirectTo}) {
  const classes = useStyles();
  const { routes } = useRoutes();

  const handleRedirect = () => {
    routes.goToEspecifcRoute(
      `/aprovacao?filter[colecao]=${redirectTo}&agrupamento=Linhas`
    );
  };

  return (
    <div className={classes.menuCardRoot}>
    <Grid container className={classes.card}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        onClick={handleRedirect}
        className={classes.hover}
      >
        <Typography className={classes.fontWhite} variant="h4">
        <> {title} </>
        </Typography>
        <ArrowForwardIosIcon />
      </Grid>
    </Grid>
    </div>
  );
}

export default MenuCard;
