import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontFamily: 'Flama medium',
      fontSize: 16
    }
  }),
  { index: 1 }
);
