import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import FilterData from '../filterData/index.js';
import { useStyles } from './style';
import FilterDataSwitch from '../filterDataSwitch/index.js';
import {
  Button,
  CircularProgress,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowFwIcon from '@material-ui/icons/ArrowForward';
import filterIcon from '../../../assets/icons/filter-icon.svg';
import FilterListIcon from '@material-ui/icons/FilterList';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { findLines } from '../../../services/lineService.js';
import { findGroups } from '../../../services/groupService.js';
import { findSubgroups } from '../../../services/subGroupService.js';
import { findSegments } from '../../../services/segmentService.js';
import { findCoordinateds } from '../../../services/coordinatedService.js';
import { findColors } from '../../../services/colorService.js';
import { findStylists } from '../../../services/stylistService.js';
import { findProductType } from '../../../services/productTypeService.js';
import { findTipoFarm } from '../../../services/tipoFarmService.js';
import { findRack } from '../../../services/rackService.js';
import { findWardrobe } from '../../../services/wardrobeService.js';
import { findPcpPeriod } from '../../../services/pcpPeriodService.js';
import { findMixedLine } from '../../../services/mixedLineService.js';
import { findSupplier } from '../../../services/supplierService.js';
import { findMaterial } from '../../../services/materialService.js';
import { findClassification } from '../../../services/classificationService.js';
import { findBusiness } from '../../../services/businessService.js';
import { useUser } from '../../../hooks/useUser.jsx';

import useContextFilters from '../../../hooks/useContextFilters.jsx';

export default function FilterAV({
  color,
  filterData,
  getMappings,
  blockSelecteds = false,
  setLockSelects
}) {
  const {
    isUserFromAnimaleBR,
    isUserFromFabula,
    isUserFromHering,
    isUserFromFarmBR
  } = useUser();
  const classes = useStyles();
  const useFilter = useContextFilters();
  const [state, setState] = useState({
    right: false,
    loading: true
  });
  const [currentPage, setCurrentPage] = useState({});
  const [rowsPerPage] = useState(10);
  const [lines, setLines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [segments, setSegments] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [colors, setColors] = useState([]);
  const [stylists, setStylists] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [precoInputKey, setPrecoInputKey] = useState(0);

  const [tiposFarm, setTiposFarm] = useState([]);
  const [racks, setRacks] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [wardrobes, setWardrobes] = useState([]);
  const [pcpPeriods, setPcpPeriods] = useState([]);
  const [mixedLines, setMixedLine] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [business, setBusiness] = useState([]);
  const {
    filters,
    getFilterBrand,
    getFilterCollection,
    getFilterCoordinates,
    getFilterColors,
    getFilterGrouping,
    getFilterGroups,
    getFilterLines,
    getFilterPrice,
    getFilterStylist,
    getFilterSegments,
    getFilterSubGroups,
    isGroupingLinhas,
    isGroupingCoordenados,
    isGroupingSegmentos,
    getFilterProductType,
    getFilterTipoFarm,
    getFilterRack,
    getFilterMaterial,
    getFilterWardrobe,
    getFilterPcpPeriod,
    getFilterMixedLine,
    getFilterSupplier,
    getFilterClassification,
    getFilterBusiness
  } = filterData;
  const [handleFilters, setHandleFilters] = useState({ ...filters });
  const [handleFiltersLabels, setHandleFiltersLabels] = useState([]);
  const [precoInicial, setPrecoInicial] = useState(filters.preco[0]);
  const [precoLimite, setPrecoLimite] = useState(filters.preco[1]);
  const colecao = filterData.getParamFromUrl('filter[colecao]');

  const loadFilter = async (findFilter, getFilter) => {
    const getFiltersObj = {
      getFilterBrand: getFilterBrand,
      getFilterCollection: getFilterCollection,
      getFilterStylist: getFilterStylist,
      getFilterGroups: getFilterGroups,
      getFilterPrice: getFilterPrice,
      getFilterProductType: getFilterProductType,
      getFilterTipoFarm: getFilterTipoFarm,
      getFilterRack: getFilterRack,
      getFilterMaterial: getFilterMaterial,
      getFilterWardrobe: getFilterWardrobe,
      getFilterPcpPeriod: getFilterPcpPeriod,
      getFilterMixedLine: getFilterMixedLine,
      getFilterSupplier: getFilterSupplier,
      getFilterClassification: getFilterClassification,
      getFilterGrouping: getFilterGrouping
    };

    delete getFiltersObj?.[getFilter];

    let params = {};

    Object.keys(getFiltersObj).forEach((getFilter) => {
      params = {
        ...params,
        ...getFiltersObj[getFilter]()
      };
    });

    if (isGroupingLinhas()) {
      params = {
        ...params,
        ...getFilterCoordinates(),
        ...getFilterColors(),
        ...filterData.formatFilter(
          filterData.buildFilterList(['selecteds', 'lines']),
          'id_linha'
        )
      };
    } else if (isGroupingSegmentos()) {
      params = {
        ...params,
        ...getFilterCoordinates(),
        ...getFilterColors(),
        ...filterData.formatFilter(
          filterData.buildFilter('selecteds'),
          'no_segmento'
        )
      };
    } else if (isGroupingCoordenados()) {
      params = {
        ...params,
        ...getFilterLines(),
        ...(isUserFromFarmBR()
          ? filterData.formatFilter(
              filterData.buildFilterList(['selecteds', 'coordinates']),
              'no_estampa_farm'
            )
          : filterData.formatFilter(
              filterData.buildFilterList(['selecteds', 'colors']),
              'id_estampa_cor'
            ))
      };
    } else {
      params = {
        ...params,
        ...getFilterCoordinates(),
        ...getFilterColors(),
        ...getFilterLines()
      };
    }

    return await findFilter(params);
  };

  const loadFilters = async () => {
    if (colecao[0].length > 0) {
      Promise.all([
        loadFilter(findLines, getFilterLines),
        loadFilter(findGroups, getFilterGroups),
        loadFilter(findSubgroups, getFilterSubGroups),
        isUserFromFabula() ? loadFilter(findSegments, getFilterSegments) : [],
        loadFilter(findStylists, getFilterStylist),
        loadFilter(findProductType, getFilterProductType),
        isUserFromFarmBR()
          ? loadFilter(findCoordinateds, getFilterCoordinates)
          : loadFilter(findColors, getFilterColors),
        loadFilter(findTipoFarm, getFilterTipoFarm),
        loadFilter(findRack, getFilterRack),
        loadFilter(findMaterial, getFilterMaterial),
        loadFilter(findSupplier, getFilterSupplier),
        loadFilter(findWardrobe, getFilterWardrobe),
        loadFilter(findPcpPeriod, getFilterPcpPeriod),
        loadFilter(findClassification, getFilterClassification),
        isUserFromHering() ? loadFilter(findBusiness, getFilterBusiness) : [],
        isUserFromFarmBR() && loadFilter(findMixedLine, getFilterMixedLine)
      ])
        .then(
          ([
            linesResponse,
            groupsResponse,
            subgroupsResponse,
            segmentsResponse,
            stylistsResponse,
            productTypesResponse,
            coordinatesResponse,
            tiposFarmResponse,
            racksResponse,
            materialsResponse,
            suppliersResponse,
            wardrobesResponse,
            pcpPeriodsResponse,
            classificationsResponse,
            businessResponse,
            mixedLinesResponse
          ]) => {
            setLines(linesResponse ? linesResponse : []);
            setGroups(groupsResponse ? groupsResponse : []);
            setSegments(segmentsResponse ? segmentsResponse : []);
            setSubGroups(subgroupsResponse ? subgroupsResponse : []);
            if (isUserFromFarmBR()) {
              let newCoordinates;
              if (
                coordinatesResponse.find((coordinate) => {
                  return coordinate.no_estampa_farm === 'INDEFINIDO';
                })
              ) {
                newCoordinates = coordinatesResponse;
              } else {
                newCoordinates = [
                  { no_estampa_farm: 'INDEFINIDO' },
                  ...coordinatesResponse
                ];
              }
              setCoordinates(newCoordinates);
            } else {
              let newColor;
              if (
                coordinatesResponse.find((color) => {
                  return color.desc_estampa_cor === 'XXXX - INDEFINIDO';
                })
              ) {
                newColor = coordinatesResponse;
              } else {
                newColor = [
                  { id_estampa_cor: -1, desc_estampa_cor: 'XXXX - INDEFINIDO' },
                  ...coordinatesResponse
                ];
              }
              setColors(newColor);
            }
            setStylists(stylistsResponse ? stylistsResponse : []);
            setProductTypes(productTypesResponse ? productTypesResponse : []);
            setTiposFarm(tiposFarmResponse ? tiposFarmResponse : []);
            setRacks(racksResponse ? racksResponse : []);
            setMaterials(materialsResponse ? materialsResponse : []);
            setSuppliers(suppliersResponse ? suppliersResponse : []);
            setWardrobes(wardrobesResponse ? wardrobesResponse : []);
            setPcpPeriods(pcpPeriodsResponse ? pcpPeriodsResponse : []);
            setClassifications(
              classificationsResponse ? classificationsResponse : []
            );
            setBusiness(businessResponse ? businessResponse : []);
            isUserFromFarmBR() &&
              setMixedLine(mixedLinesResponse ? mixedLinesResponse : []);
            setCurrentPage({});

            const removeInvalidFilters = (response, objectName, fieldName) => {
              if (filters[objectName]?.length > 0) {
                const listaOpcoes = response.map((obj) =>
                  fieldName ? obj[fieldName] : obj
                );

                const validOptions = filters[objectName].filter(
                  (filterItem) =>
                    !!listaOpcoes.find(
                      (option) => `${option}` === `${filterItem}`
                    )
                );
                return validOptions || [];
              }

              return [];
            };

            // TODO: Melhorar isso....
            // Testar os lines quando estiver filtrando Coordenado
            const optionsLines = removeInvalidFilters(
              linesResponse,
              'lines',
              'id_linha'
            );
            const optionsGroups = removeInvalidFilters(
              groupsResponse,
              'groups',
              'id_grupo_produto'
            );
            const optionsSegments = removeInvalidFilters(
              segmentsResponse,
              'segments',
              'no_segmento'
            );
            const optionsSubgroups = removeInvalidFilters(
              subgroupsResponse,
              'subGroups',
              'no_subgrupo'
            );
            const optionsStylists = removeInvalidFilters(
              stylistsResponse,
              'stylists',
              'id_estilista'
            );
            const optionsProductTypes = removeInvalidFilters(
              productTypesResponse,
              'productTypes',
              'no_tipo_produto'
            );
            let optionsCoordinates = [];
            let optionsColors = [];
            if (isUserFromFarmBR()) {
              optionsCoordinates = removeInvalidFilters(
                coordinatesResponse,
                'coordinates',
                'no_estampa_farm'
              );
            } else {
              optionsColors = removeInvalidFilters(
                coordinatesResponse,
                'colors',
                'id_estampa_cor'
              );
            }
            const optionsTiposFarm = removeInvalidFilters(
              tiposFarmResponse,
              'tiposFarm',
              'no_tipo_farm'
            );
            const optionsRacks = removeInvalidFilters(
              racksResponse,
              'racks',
              'no_arara'
            );
            const optionsMaterials = removeInvalidFilters(
              materialsResponse,
              'materials',
              'cd_material'
            );
            const optionsWardrobes = removeInvalidFilters(
              wardrobesResponse,
              'wardrobes',
              'id_armario'
            );
            const optionsPcpPeriods = removeInvalidFilters(
              pcpPeriodsResponse,
              'pcpPeriods',
              'periodo_pcp'
            );
            const optionsMixedLine = removeInvalidFilters(
              mixedLinesResponse,
              'mixedLines',
              'linha_mix'
            );
            const optionsSuppliers = removeInvalidFilters(
              suppliersResponse,
              'suppliers',
              'id_fornecedor'
            );
            const optionsClassifications = removeInvalidFilters(
              classificationsResponse,
              'classifications',
              'id_classificacao'
            );

            const optionsBusiness = removeInvalidFilters(
              businessResponse,
              'business',
              'id_sexo_publico_alvo'
            );

            filterData.setFilters({
              ...filters,
              lines: optionsLines,
              coordinates: optionsCoordinates,
              colors: optionsColors,
              groups: optionsGroups,
              segments: optionsSegments,
              subGroups: optionsSubgroups,
              stylists: optionsStylists,
              productTypes: optionsProductTypes,
              tiposFarm: optionsTiposFarm,
              racks: optionsRacks,
              materials: optionsMaterials,
              wardrobes: optionsWardrobes,
              pcpPeriods: optionsPcpPeriods,
              mixedLines: optionsMixedLine,
              suppliers: optionsSuppliers,
              classifications: optionsClassifications,
              business: optionsBusiness
            });
          }
        )
        .finally(() => {
          setState({
            ...state,
            loading: false
          });
        });
    }
  };

  useEffect(() => {
    colecao && filters.grouping && loadFilters();
    // eslint-disable-next-line
  }, [filters.collection]);

  const toggleDrawer = (anchor, open) => (event) => {
    setHandleFilters({ ...filterData.filters });
    setHandleFiltersLabels([]);
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const closeDrawer = (anchor) => {
    setState({
      ...state,
      [anchor]: false
    });
  };

  const changeFilter = (valor, filtro, label) => {
    let ret = false;
    if (valor !== '') {
      if (!handleFilters[filtro].find((filter) => `${filter}` === `${valor}`)) {
        setHandleFilters({
          ...handleFilters,
          [filtro]: [...handleFilters[filtro], valor]
        });
        ret = true;
      } else {
        setHandleFilters({
          ...handleFilters,
          [filtro]: handleFilters[filtro].filter((f) => `${f}` !== `${valor}`)
        });
        ret = false;
      }
    }
    addHandleFilterLabel({ id: valor, label: label, filtro, value: valor });
    return ret;
  };

  const addHandleFilterLabel = (filterWithLabel) => {
    if (!filterWithLabel.value || !filterWithLabel.label) {
      removeHandleFilterLabel(filterWithLabel);
      return;
    }
    let newFilter = handleFiltersLabels;
    if (filterWithLabel.filtroAux === 'precoInicial') {
      newFilter = handleFiltersLabels.filter(
        (el) => el.filtroAux !== 'precoInicial'
      );
    }
    if (filterWithLabel.filtroAux === 'precoLimite') {
      newFilter = handleFiltersLabels.filter(
        (el) => el.filtroAux !== 'precoLimite'
      );
    }

    let r = newFilter.find((el) => el.id === filterWithLabel.id);
    if (!r) {
      newFilter.push(filterWithLabel);
      setHandleFiltersLabels(newFilter.map((e) => e));
    } else {
      if (filterWithLabel.filtro !== 'selecteds') {
        removeHandleFilterLabel(filterWithLabel);
      }
    }
  };

  const removeHandleFilterLabel = (filterWithLabel) => {
    let filter = handleFiltersLabels.filter(
      (el) => el.id !== filterWithLabel.id
    );
    setHandleFiltersLabels(filter.map((e) => e));
  };

  const onChangePrecoInicial = (value) => {
    setPrecoInicial(value);
  };

  const onChangePrecoLimite = (value) => {
    setPrecoLimite(value);
  };

  useEffect(() => {
    const updateFilter = (value) => {
      const filter = { ...handleFilters };

      if (!filter['preco'] || filter['preco'].length === 0) {
        filter['preco'] = [value];
      } else {
        if (filter['preco'].length > 1) {
          if (value > filter['preco'][1]) {
            filter['preco'].splice(1, 1);
          }
        }

        if (value > 0) {
          filter['preco'][0] = value;
        } else {
          filter['preco'] = [];
          setPrecoLimite(0);
        }
      }
      filter.preco = filter.preco.map((v) => {
        return v;
      });
      setHandleFilters({ ...filter });
    };
    filterData.addFilterLabel({
      id: 'precoInicial',
      label: 'De R$' + precoInicial,
      filtro: 'preco',
      filtroAux: 'precoInicial',
      value: precoInicial
    });
    useFilter.addFilterLabel({
      id: 'precoInicial',
      label: 'De R$' + precoInicial,
      filtro: 'preco',
      filtroAux: 'precoInicial',
      value: precoInicial
    });
    updateFilter(precoInicial);

    // eslint-disable-next-line
  }, [precoInicial]);

  useEffect(() => {
    const updateFilter = (value) => {
      const filter = { ...handleFilters };
      if (value > 0) {
        filter['preco'][1] = value;
      } else {
        filter['preco'].splice(1, 1);
      }
      filter.preco = filter.preco.map((v) => {
        return v;
      });

      if (handleFilters.collection && handleFilters.grouping) {
        setHandleFilters({ ...filter });
      }
    };
    filterData.addFilterLabel({
      id: 'precoLimite',
      label: 'Até R$' + precoLimite,
      filtro: 'preco',
      filtroAux: 'precoLimite',
      value: precoLimite
    });
    useFilter.addFilterLabel({
      id: 'precoLimite',
      label: 'Até R$' + precoLimite,
      filtro: 'preco',
      filtroAux: 'precoLimite',
      value: precoLimite
    });
    updateFilter(precoLimite);
    // eslint-disable-next-line
  }, [precoLimite]);

  useEffect(() => {
    if (!state.loading && window.location.pathname === '/aprovacao/vote') {
      setState({
        ...state,
        loading: true
      });
      loadFilters();
      getMappings();
    }

    // eslint-disable-next-line
  }, [
    filterData.filters.colors,
    filterData.filters.coordinates,
    filterData.filters.groups,
    filterData.filters.segments,
    filterData.filters.lines,
    filterData.filters.materials,
    filterData.filters.mixedLines,
    filterData.filters.vitrine,
    filterData.filters.productTypes,
    filterData.filters.racks,
    filterData.filters.stylists,
    filterData.filters.subGroups,
    filterData.filters.suppliers,
    filterData.filters.tiposFarm,
    filterData.filters.wardrobes,
    filterData.filters.pcpPeriods,
    filterData.filters.preco,
    filterData.filters.classifications
  ]);

  useEffect(() => {
    if (filterData.filters.clean) {
      filterData.setFilters((oldFilters) => {
        return {
          ...oldFilters,
          clean: false
        };
      });
    }
  }, [filterData.filters.clean, filterData]);

  const handlePage = (filter, newPage) => {
    setCurrentPage({
      ...currentPage,
      [filter]: newPage
    });
  };

  const getCurrentPage = (filter) => {
    return currentPage[filter] || 1;
  };

  const indexOfLastLines = getCurrentPage('lines') * rowsPerPage;
  const indexOfFirstLines = indexOfLastLines - rowsPerPage;
  const currentLines = lines.slice(indexOfFirstLines, indexOfLastLines);

  const indexOfLastGroups = getCurrentPage('groups') * rowsPerPage;
  const indexOfFirstGroups = indexOfLastGroups - rowsPerPage;
  const currentGroups = groups.slice(indexOfFirstGroups, indexOfLastGroups);

  const indexOfLastSubGroups = getCurrentPage('subGroups') * rowsPerPage;
  const indexOfFirstSubGroups = indexOfLastSubGroups - rowsPerPage;
  const currentSubGroups = subGroups.slice(
    indexOfFirstSubGroups,
    indexOfLastSubGroups
  );

  const indexOfLastSegments = getCurrentPage('segments') * rowsPerPage;
  const indexOfFirstSegments = indexOfLastSegments - rowsPerPage;
  const currentSegments = segments.slice(
    indexOfFirstSegments,
    indexOfLastSegments
  );

  const indexOfLastCoordinates = getCurrentPage('coordinates') * rowsPerPage;
  const indexOfFirstCoordinates = indexOfLastCoordinates - rowsPerPage;
  const currentCoordinates = coordinates.slice(
    indexOfFirstCoordinates,
    indexOfLastCoordinates
  );

  const indexOfLastColors = getCurrentPage('colors') * rowsPerPage;
  const indexOfFirstColors = indexOfLastColors - rowsPerPage;
  const currentColors = colors.slice(indexOfFirstColors, indexOfLastColors);

  const indexOfLastStylists = getCurrentPage('stylists') * rowsPerPage;
  const indexOfFirstStylists = indexOfLastStylists - rowsPerPage;
  const currentStylists = stylists.slice(
    indexOfFirstStylists,
    indexOfLastStylists
  );

  const indexOfLastProductTypes = getCurrentPage('productTypes') * rowsPerPage;
  const indexOfFirstProductTypes = indexOfLastProductTypes - rowsPerPage;
  const currentProductTypes = productTypes.slice(
    indexOfFirstProductTypes,
    indexOfLastProductTypes
  );

  const indexOfLastTiposFarm = getCurrentPage('tiposFarm') * rowsPerPage;
  const indexOfFirstTiposFarm = indexOfLastTiposFarm - rowsPerPage;
  const currentTiposFarm = tiposFarm.slice(
    indexOfFirstTiposFarm,
    indexOfLastTiposFarm
  );

  const indexOfLastRacks = getCurrentPage('racks') * rowsPerPage;
  const indexOfFirstRacks = indexOfLastRacks - rowsPerPage;
  const currentRacks = racks.slice(indexOfFirstRacks, indexOfLastRacks);

  const indexOfLastMaterials = getCurrentPage('materials') * rowsPerPage;
  const indexOfFirstMaterials = indexOfLastMaterials - rowsPerPage;
  const currentMaterials = materials.slice(
    indexOfFirstMaterials,
    indexOfLastMaterials
  );

  const indexOfLastWardrobes = getCurrentPage('wardrobes') * rowsPerPage;
  const indexOfFirstWardrobes = indexOfLastWardrobes - rowsPerPage;
  const currentWardrobes = wardrobes.slice(
    indexOfFirstWardrobes,
    indexOfLastWardrobes
  );

  const indexOfLastPcpPeriods = getCurrentPage('pcpPeriods') * rowsPerPage;
  const indexOfFirstPcpPeriods = indexOfLastPcpPeriods - rowsPerPage;
  const currentPcpPeriods = pcpPeriods.slice(
    indexOfFirstPcpPeriods,
    indexOfLastPcpPeriods
  );

  const indexOfLastMixedLines = getCurrentPage('mixedLines') * rowsPerPage;
  const indexOfFirstMixedLines = indexOfLastMixedLines - rowsPerPage;
  const currentMixedLines = mixedLines.slice(
    indexOfFirstMixedLines,
    indexOfLastMixedLines
  );

  const indexOfLastSuppliers = getCurrentPage('suppliers') * rowsPerPage;
  const indexOfFirstSuppliers = indexOfLastSuppliers - rowsPerPage;
  const currentSuppliers = suppliers.slice(
    indexOfFirstSuppliers,
    indexOfLastSuppliers
  );

  const indexOfLastClassifications =
    getCurrentPage('classifications') * rowsPerPage;
  const indexOfFirstClassifications = indexOfLastClassifications - rowsPerPage;
  const currentClassifications = classifications.slice(
    indexOfFirstClassifications,
    indexOfLastClassifications
  );

  const indexOfLastBusiness = getCurrentPage('business') * rowsPerPage;
  const indexOfFirstBusiness = indexOfLastBusiness - rowsPerPage;
  const currentBusiness = business.slice(
    indexOfFirstBusiness,
    indexOfLastBusiness
  );

  const LinesNumbers = [];
  let totalLines = lines.length;
  for (let i = 1; i <= Math.ceil(totalLines / rowsPerPage); i++) {
    LinesNumbers.push(i);
  }

  const GroupsNumbers = [];
  let totalGroups = groups.length;
  for (let i = 1; i <= Math.ceil(totalGroups / rowsPerPage); i++) {
    GroupsNumbers.push(i);
  }

  const SegmentsNumbers = [];
  let totalSegments = segments.length;
  for (let i = 1; i <= Math.ceil(totalSegments / rowsPerPage); i++) {
    SegmentsNumbers.push(i);
  }

  const SubGroupsNumbers = [];
  let totalSubGroups = subGroups.length;
  for (let i = 1; i <= Math.ceil(totalSubGroups / rowsPerPage); i++) {
    SubGroupsNumbers.push(i);
  }

  const CoordinatesNumbers = [];
  let totalCoordinates = coordinates.length;
  for (let i = 1; i <= Math.ceil(totalCoordinates / rowsPerPage); i++) {
    CoordinatesNumbers.push(i);
  }

  const ColorsNumber = [];
  let totalColors = colors.length;
  for (let i = 1; i <= Math.ceil(totalColors / rowsPerPage); i++) {
    ColorsNumber.push(i);
  }

  const StylistsNumbers = [];
  let totalStylists = stylists.length;
  for (let i = 1; i <= Math.ceil(totalStylists / rowsPerPage); i++) {
    StylistsNumbers.push(i);
  }

  const ProductTypesNumbers = [];
  let totalProductTypes = productTypes.length;
  for (let i = 1; i <= Math.ceil(totalProductTypes / rowsPerPage); i++) {
    ProductTypesNumbers.push(i);
  }

  const TiposFarmNumbers = [];
  let totalTiposFarm = tiposFarm.length;
  for (let i = 1; i <= Math.ceil(totalTiposFarm / rowsPerPage); i++) {
    TiposFarmNumbers.push(i);
  }

  const RacksNumbers = [];
  let totalRacks = racks.length;
  for (let i = 1; i <= Math.ceil(totalRacks / rowsPerPage); i++) {
    RacksNumbers.push(i);
  }

  const MaterialsNumbers = [];
  let totalMaterials = materials.length;
  for (let i = 1; i <= Math.ceil(totalMaterials / rowsPerPage); i++) {
    MaterialsNumbers.push(i);
  }

  const WardrobesNumbers = [];
  let totalWardrobes = wardrobes.length;
  for (let i = 1; i <= Math.ceil(totalWardrobes / rowsPerPage); i++) {
    WardrobesNumbers.push(i);
  }

  const PcpPeriodsNumbers = [];
  let totalPcpPeriods = pcpPeriods.length;
  for (let i = 1; i <= Math.ceil(totalPcpPeriods / rowsPerPage); i++) {
    PcpPeriodsNumbers.push(i);
  }

  const MixedLinesNumbers = [];
  let totalMixedLines = mixedLines.length;
  for (let i = 1; i <= Math.ceil(totalMixedLines / rowsPerPage); i++) {
    MixedLinesNumbers.push(i);
  }

  const SuppliersNumbers = [];
  let totalSuppliers = suppliers.length;
  for (let i = 1; i <= Math.ceil(totalSuppliers / rowsPerPage); i++) {
    SuppliersNumbers.push(i);
  }

  const ClassificationsNumbers = [];
  let totalClassifications = classifications.length;
  for (let i = 1; i <= Math.ceil(totalClassifications / rowsPerPage); i++) {
    ClassificationsNumbers.push(i);
  }

  const BusinessNumbers = [];
  let totalBusiness = business.length;
  for (let i = 1; i <= Math.ceil(totalBusiness / rowsPerPage); i++) {
    BusinessNumbers.push(i);
  }

  const limpar = () => {
    useFilter.cleanFilterLabels();

    filterData.clearFilters({
      grouping: filters.grouping,
      collection: filters.collection,
      selecteds: filters.selecteds,
      XAxis: filters.XAxis,
      YAxis: filters.YAxis,
      clean: true
    });
    setPrecoInputKey(precoInputKey + 1);
  };

  const precoHasError = () => {
    return (
      handleFilters.preco?.length > 1 &&
      handleFilters.preco[0] > handleFilters.preco[1]
    );
  };

  const blockCoordenados =
    blockSelecteds && isGroupingCoordenados() && filters.selecteds.length > 0;

  const blockLinhas =
    blockSelecteds && isGroupingLinhas() && filters.selecteds.length > 0;

  const blockSegmentos =
    blockSelecteds && isGroupingSegmentos() && filters.selecteds.length > 0;

  const returnFalse = () => {
    return false;
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
    >
      <Grid container className={classes.header}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ maxWidth: 220 }}
        >
          <Grid item xs={3}>
            <IconButton onClick={() => closeDrawer(anchor)} title="Fechar">
              <ArrowFwIcon />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                textAlign: 'flex-start',
                fontFamily: 'Flama medium',
                fontSize: 14,
                lineHeight: '100%'
              }}
            >
              FILTROS
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ maxWidth: 220 }}
        >
          <Grid item xs={5}>
            <Button
              variant="outlined"
              classes={{ label: classes.buttonLabel }}
              style={{ color: 'black', borderColor: 'white' }}
              onClick={() => limpar()}
            >
              <Typography
                style={{
                  fontFamily: 'Flama',
                  fontSize: 14
                }}
              >
                Limpar
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              style={{
                backgroundColor: 'black',
                borderColor: 'black',
                color: 'white'
              }}
              onClick={() => {
                useFilter.cleanFilterLabels();
                handleFiltersLabels.forEach((opt) => {
                  useFilter.addFilterLabel({ ...opt });
                  filterData.addFilterLabel({ ...opt });
                });
                filterData.setFilters({ ...handleFilters });
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Flama',
                  fontSize: 14
                }}
              >
                FILTRAR
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={blockLinhas}
        isGroupingType={isGroupingLinhas}
        filters={handleFilters}
        types={lines}
        TypesNumbers={LinesNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentLines}
        changeFilter={changeFilter}
        type="lines"
        label="linha"
        value="id_linha"
        title="Linhas"
      />

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={groups}
        TypesNumbers={GroupsNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentGroups}
        changeFilter={changeFilter}
        type="groups"
        label="grupo_produto"
        value="id_grupo_produto"
        title="Grupos"
      />

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={subGroups}
        TypesNumbers={SubGroupsNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentSubGroups}
        changeFilter={changeFilter}
        type="subGroups"
        label="no_subgrupo"
        value="no_subgrupo"
        title="Subgrupos"
      />

      {isUserFromFarmBR() ? (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={blockCoordenados}
          isGroupingType={isGroupingCoordenados}
          filters={handleFilters}
          types={coordinates}
          TypesNumbers={CoordinatesNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentCoordinates}
          changeFilter={changeFilter}
          type="coordinates"
          label="no_estampa_farm"
          value="no_estampa_farm"
          title="Coordenados"
        />
      ) : (
        <>
          <FilterData
            loading={state.loading}
            filterData={filterData}
            blockType={blockCoordenados}
            isGroupingType={returnFalse}
            filters={handleFilters}
            types={colors}
            TypesNumbers={ColorsNumber}
            getCurrentPage={getCurrentPage}
            handlePage={handlePage}
            currentTypes={currentColors}
            changeFilter={changeFilter}
            type="colors"
            label="desc_estampa_cor"
            value="id_estampa_cor"
            title="Cores"
          />
        </>
      )}

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={stylists}
        TypesNumbers={StylistsNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentStylists}
        changeFilter={changeFilter}
        type="stylists"
        label="estilista"
        value="id_estilista"
        title="Estilistas"
      />

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={productTypes}
        TypesNumbers={ProductTypesNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentProductTypes}
        changeFilter={changeFilter}
        type="productTypes"
        label="no_tipo_produto"
        value="no_tipo_produto"
        title="Tipos de Produto"
      />

      {isUserFromFabula() && (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={false}
          isGroupingType={returnFalse}
          filters={handleFilters}
          types={tiposFarm}
          TypesNumbers={TiposFarmNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentTiposFarm}
          changeFilter={changeFilter}
          type="tiposFarm"
          label="no_tipo_farm"
          value="no_tipo_farm"
          title="Tipo Farm"
        />
      )}

      {isUserFromFabula() && (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={blockSegmentos}
          isGroupingType={isGroupingSegmentos}
          filters={handleFilters}
          types={segments}
          TypesNumbers={SegmentsNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentSegments}
          changeFilter={changeFilter}
          type="segments"
          label="no_segmento"
          value="no_segmento"
          title="Segmentos"
        />
      )}

      {isUserFromAnimaleBR() && (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={false}
          isGroupingType={returnFalse}
          filters={handleFilters}
          types={wardrobes}
          TypesNumbers={WardrobesNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentWardrobes}
          changeFilter={changeFilter}
          type="wardrobes"
          label="armario"
          value="id_armario"
          title="Armário"
        />
      )}

      {isUserFromFarmBR() && (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={false}
          isGroupingType={returnFalse}
          filters={handleFilters}
          types={mixedLines}
          TypesNumbers={MixedLinesNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentMixedLines}
          changeFilter={changeFilter}
          type="mixedLines"
          label="linha_mix"
          value="linha_mix"
          title="Linha Mix"
        />
      )}

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={racks}
        TypesNumbers={RacksNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentRacks}
        changeFilter={changeFilter}
        type="racks"
        label="no_arara"
        value="no_arara"
        title="Arara"
      />

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={pcpPeriods}
        TypesNumbers={PcpPeriodsNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentPcpPeriods}
        changeFilter={changeFilter}
        type="pcpPeriods"
        label="periodo_pcp"
        value="periodo_pcp"
        title="Período PCP"
      />

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={materials}
        TypesNumbers={MaterialsNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentMaterials}
        changeFilter={changeFilter}
        type="materials"
        label="no_material"
        value="cd_material"
        title="Base"
      />

      <FilterData
        loading={state.loading}
        filterData={filterData}
        blockType={false}
        isGroupingType={returnFalse}
        filters={handleFilters}
        types={suppliers}
        TypesNumbers={SuppliersNumbers}
        getCurrentPage={getCurrentPage}
        handlePage={handlePage}
        currentTypes={currentSuppliers}
        changeFilter={changeFilter}
        type="suppliers"
        label="fornecedor"
        value="id_fornecedor"
        title="Fornecedor"
      />

      {!isUserFromFabula() && (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={false}
          isGroupingType={returnFalse}
          filters={handleFilters}
          types={classifications}
          TypesNumbers={ClassificationsNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentClassifications}
          changeFilter={changeFilter}
          type="classifications"
          label="no_classificacao"
          value="id_classificacao"
          title={isUserFromFarmBR() ? 'Atributo' : 'Classificação'}
        />
      )}

      {isUserFromHering() && (
        <FilterData
          loading={state.loading}
          filterData={filterData}
          blockType={false}
          isGroupingType={returnFalse}
          filters={handleFilters}
          types={business}
          TypesNumbers={BusinessNumbers}
          getCurrentPage={getCurrentPage}
          handlePage={handlePage}
          currentTypes={currentBusiness}
          changeFilter={changeFilter}
          type="business"
          label="no_sexo_publico_alvo"
          value="id_sexo_publico_alvo"
          title={'Negócio'}
        />
      )}

      <FilterDataSwitch
        title="Vitrine"
        type="vitrine"
        blockType={false}
        filters={handleFilters}
        changeFilter={changeFilter}
        filterData={filterData}
      />

      <ExpansionPanel className={classes.panel} style={{ marginTop: 10 }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {handleFilters['preco']?.length > 0 ? (
            <div style={{ maxWidth: 300 }}>
              <Grid container justify="flex-start">
                <Typography className={classes.text}> Preço </Typography>
                <Grid item xs={12}>
                  {handleFilters['preco'].map((preco, i) => (
                    <Chip
                      key={`chip${i}${preco}`}
                      className={classes.chip}
                      index={i}
                      label={`${i === 0 ? 'A partir de' : 'Até'} R$ ${preco
                        ?.toFixed(2)
                        ?.replaceAll('.', ',')}`}
                      color="primary"
                    />
                  ))}
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography className={classes.text}> Preço </Typography>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid>
            <CurrencyTextField
              id="aPartirDe"
              key={`aPartirDe-${precoInputKey}`}
              label="A partir de"
              variant="standard"
              style={{ marginRight: 5 }}
              currencySymbol="R$"
              minimumValue="0"
              decimalPlaces={0}
              value={
                handleFilters['preco']?.length > 0
                  ? handleFilters['preco'][0]
                  : ''
              }
              outputFormat="number"
              decimalCharacter=","
              digitGroupSeparator="."
              onChange={(event, value) => onChangePrecoInicial(value)}
            />
            <CurrencyTextField
              id="ate"
              label="Até"
              key={`ate-${precoInputKey}`}
              variant="standard"
              style={{ marginLeft: 5 }}
              error={precoHasError()}
              helperText={precoHasError() ? 'Limite inválido' : ''}
              currencySymbol="R$"
              decimalPlaces={0}
              value={
                handleFilters['preco']?.length > 1
                  ? handleFilters['preco'][1]
                  : ''
              }
              outputFormat="number"
              decimalCharacter=","
              digitGroupSeparator="."
              onChange={(event, value) => onChangePrecoLimite(value)}
            />
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );

  return (
    <div>
      {['Filtrar'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            disabled={state.loading}
            onClick={toggleDrawer(anchor, true)}
          >
            {color === 'white' ? (
              state.loading ? (
                <CircularProgress
                  size={20}
                  style={{ color: '#C4C4C4', marginTop: -1 }}
                />
              ) : (
                <FilterListIcon style={{ color: color }} />
              )
            ) : state.loading ? (
              <CircularProgress
                size={20}
                style={{ color: 'black', marginTop: 0 }}
              />
            ) : (
              <img
                src={filterIcon}
                alt="filter icon"
                style={{ height: '13px', marginTop: 0 }}
              />
            )}
          </IconButton>
          <SwipeableDrawer
            anchor={'right'}
            open={state.forceOpen ? state.forceOpen : state[anchor] || false}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            PaperProps={{
              style: {
                overflowY: state.loading ? 'hidden' : 'auto',
                width: 450,
                maxWidth: state.forceOpen ? 0 : 450
              }
            }}
          >
            <div style={{ position: 'relative' }}>
              {state.loading && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  style={{
                    background: 'rgba(50, 50, 50, 0.733)',
                    position: 'fixed',
                    zIndex: 9999,
                    right: 0,
                    top: 0,
                    width: 450,
                    height: '100vh'
                  }}
                  className={classes.panel}
                >
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Typography
                      variant="h6"
                      style={{ color: '#fafafa', marginBottom: 5 }}
                    >
                      Atualizando opções de filtro
                    </Typography>
                    <CircularProgress style={{ color: '#fafafa' }} />
                  </Grid>
                </Grid>
              )}
              {list(anchor)}
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
