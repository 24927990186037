import React from 'react';
import { authGuard } from '../guard/AuthGuard';
import { DeniedRedirect } from '../guard/DeniedRedirect';
import { HomeRedirect } from '../guard/Redirect';
import { NoRoleRedirect } from '../guard/NoRoleRedirect';

export const PrivateRoute = ({ element: Element }) => {
  if (!authGuard.isAuthenticated()) {
    return <DeniedRedirect />;
  }

  if (
    !authGuard.isAuthorized() &&
    !window.location.href.includes('no-authorized') &&
    !window.location.href.includes('login') &&
    !window.location.href.includes('logout')
  ) {
    return <NoRoleRedirect />;
  }

  if (
    authGuard.isAuthorized() &&
    window.location.href.includes('no-authorized')
  ) {
    return <HomeRedirect />;
  }

  return Element;
};
