const DUPLICATE_ID_PRODUCT = 1;
const MOVE_ID_PRODUCT = 2;
const CREATE_ID_PRODUCT = 3;
const DUPLICATE_LABEL_PRODUCT = 'Duplicado';
const MOVE_LABEL_PRODUCT = 'Movimentado';
const CREATE_LABEL_PRODUCT = 'Criado';

const getDuplicationTypeId = (product) => {

  if (product.id_tipo_produto_duplicado) {
    const id_tipo_produto_duplicado = product.id_tipo_produto_duplicado;

    return id_tipo_produto_duplicado;
  }

  return null;
}

export const isDuplicatedProduct = (product) => DUPLICATE_ID_PRODUCT === getDuplicationTypeId(product);

export const isMovedProduct = (product) => MOVE_ID_PRODUCT === getDuplicationTypeId(product);

export const isCreatedProduct = (product) => CREATE_ID_PRODUCT === getDuplicationTypeId(product);

export const getDuplicationTypeLabel = (product) => {

  if (isDuplicatedProduct(product)) return DUPLICATE_LABEL_PRODUCT
  if (isMovedProduct(product)) return MOVE_LABEL_PRODUCT
  if (isCreatedProduct(product)) return CREATE_LABEL_PRODUCT
}

export const getStringClassification = (classification, classificationOpts) => {
  const selectedClassification = classificationOpts
    .map((opt) => {
      if (classification.id_classificacao.some((e) => e === opt.id_classificacao)) {
        return opt.no_classificacao;
      }
      return null;
    })
    .filter((opt) => opt);

  if (selectedClassification.length > 1) {
    return selectedClassification.join(" , ");
  }

  return selectedClassification;
}
