import '../src/config/ReactotronConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
//import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store from '../src/store/store';
// import yellow from '@material-ui/core/colors/yellow'
// import amber from '@material-ui/core/colors/amber'
// import red from '@material-ui/core/colors/red'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#f50057'
    }
  }
});

theme = responsiveFontSizes(theme);
const target = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service w   orkers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
