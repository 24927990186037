import React, { useState } from 'react'

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from 'recharts';

export default function SummaryChart({ summary }) {
  const [activeOutsideIndex, setActiveOutsideIndex] = useState(0);
  const [activeInsideIndex, setActiveInsideIndex] = useState(0);

  const outsideColors = [];
  const insideColors = ['#74a1e5'];

  let insideChartData = [
    { name: `Inicial - ${(summary.totalCount.total - summary.totalCount.duplicados)}`, value: (summary.totalCount.total - summary.totalCount.duplicados), total: summary.totalCount.total }
  ]

  if (summary.totalCount.duplicados > 0) {
    insideChartData.unshift({
      name: `Novos - ${summary.totalCount.duplicados}`,
      value: summary.totalCount.duplicados,
      total: summary.totalCount.total
    })
    insideColors.unshift('#2ee5e5')
  }

  const outsideChartData = () => {
    const votes = summary.totalCount.votes
    let votesArr = []
    Object.keys(votes).forEach(vote => {
      votesArr.push({ name: `${votes[vote]['no_voto']} - ${votes[vote]['total']}`, value: votes[vote]['total'], total: summary.totalCount.total })
      if (votes[vote]['cd_cor_voto']) {
        outsideColors.push(votes[vote]['cd_cor_voto'])
      } else {
        if (votes[vote]['no_voto'] === 'Reprovado') {
          outsideColors.push('#996b6b')
        } else {
          outsideColors.push('#8d8d8d')
        }
      }
    })
    return votesArr
  }

  function onOutsidePieEnter(_, index) {
    setActiveOutsideIndex(index);
  };

  function onInsidePieEnter(_, index) {
    setActiveInsideIndex(index);
  };

  const renderCustomizedOutsideLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, name, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 14;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={'rgba(0, 0, 0)'}>
          {payload.total}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={'rgba(0, 0, 0)'}>
          Total
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 4}
          outerRadius={outerRadius + 6}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${name}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#333">
          {`${(value / payload.total * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  const renderCustomizedInsideLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, name, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 2) * cos;
    const sy = cy + (outerRadius + 2) * sin;
    const mx = cx + (outerRadius + 6) * cos;
    const my = cy + (outerRadius + 6) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 6;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={'rgba(0, 0, 0)'}>
          {payload.total}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={'rgba(0, 0, 0)'}>
          Total
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 4}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${name}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#333">
          {`${(value / payload.total * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  const renderColorfulLegendText = (value) => {
    return <span style={{ color: "#000" }}>{value}</span>;
  };

  return (
    <div style={{ height: "calc(100vh - 123px)" }}>

      <ResponsiveContainer width="100%" height="95%">
        <PieChart width={400} height={400}>
          <Legend formatter={renderColorfulLegendText} />
          <Pie
            dataKey="value"
            cx="50%" cy="50%"
            data={insideChartData}
            fill="#808080"
            labelLine={false}
            activeShape={renderCustomizedInsideLabel}
            onMouseEnter={onInsidePieEnter}
            activeIndex={activeInsideIndex}
            innerRadius={27} outerRadius={30}
          >
            {insideChartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={insideColors[index % insideColors.length]} />
            ))}
          </Pie>
          <Pie
            dataKey="value"
            cx="50%" cy="50%"
            data={outsideChartData()}
            fill="#CCCCCC"
            labelLine={false}
            activeShape={renderCustomizedOutsideLabel}
            onMouseEnter={onOutsidePieEnter}
            activeIndex={activeOutsideIndex}
            innerRadius={170} outerRadius={175}
          >
            {outsideChartData().map((entry, index) => (
              <Cell key={`cell-${index}`} fill={outsideColors[index % outsideColors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

    </div>
  )
}