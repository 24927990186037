import React, { useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../pages/login/index';
import Home from '../pages/home/index';
import Aprovacao from '../pages/aprovacao/index';
import Matrix from '../pages/matrix/index';
import Summary from '../pages/summary/index';
import Logout from '../guard/Logout';
import NoAuthorized from '../pages/NoAuthorized';
import { PrivateRoute } from './privateRoute';
import { useUser } from '../hooks/useUser';
import { authGuard } from '../guard/AuthGuard';

export const ROUTES_PATH = {
  default: { route: '/', use: '/' },
  home: { route: '/home', use: '/home' },
  login: { route: '/login', use: '/login' },
 // search: { route: '/search', use: '/search' },
  logout: { route: '/logout', use: '/logout' },
  aprovacao: { route: '/aprovacao', use: '/aprovacao' },
  summary: { route: '/aprovacao/summary', use: '/aprovacao/summary' },
  matrix: { route: '/aprovacao/vote', use: '/aprovacao/vote' },
  noAuthorized: { route: '/no-authorized', use: '/no-authorized' },
  emailInvalid: { route: '/email-invalid', use: '/email-invalid' }
};

export const ROUTES = [
  {
    path: ROUTES_PATH.default.route,
    isPublic: false,
    element: <Home />
  },
  {
    path: ROUTES_PATH.home.route,
    exact: true,
    isPublic: true,
    element: <Home />
  },
  {
    path: ROUTES_PATH.login.route,
    exact: true,
    isPublic: true,
    element: <Login />
  },
  {
    path: ROUTES_PATH.logout.route,
    exact: true,
    isPublic: false,
    element: <Logout />
  },
  {
    path: ROUTES_PATH.aprovacao.route,
    exact: true,
    isPublic: true,
    element: <Aprovacao />
  },
  {
    path: ROUTES_PATH.summary.route,
    exact: true,
    isPublic: false,
    element: <Summary />
  },
  {
    path: ROUTES_PATH.matrix.route,
    exact: true,
    isPublic: false,
    element: <Matrix />
  },
  {
    path: ROUTES_PATH.noAuthorized.route,
    exact: true,
    isPublic: false,
    element: <NoAuthorized />
  },
  {
    path: ROUTES_PATH.emailInvalid.route,
    exact: true,
    isPublic: true,
    element: <NoAuthorized />
  }
];

export function ApplicationRoutes() {
  const { roles } = useUser();

  const getPrivateRoute = (r, index) => {
    return (
      <Route
        key={index}
        {...r}
        element={<PrivateRoute {...r} element={r.element} />}
      />
    );
  };

  const getRoutes = useCallback(() => {
    const rt = ROUTES.map((r, i) => {
      if (r.isPublic) {
        return <Route key={i} {...r} />;
      } else {
        if (
          roles?.length > 0 ||
          window.location.href.includes('no-authorized') ||
          window.location.href.includes('logout') ||
          !authGuard.isAuthenticated()
        ) {
          return getPrivateRoute(r, i);
        } else {
          return (
            <Route
              key={i}
              {...r}
              element={<PrivateRoute {...r}>{r.element}</PrivateRoute>}
            />
          );
        }
      }
    });

    return rt;
  }, [roles]);

  return <Routes>{getRoutes()}</Routes>;
}
