import axios from 'axios';
import { BASE_URL_APROVACAO } from '../consts';
import { authGuard } from '../guard/AuthGuard';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthorizationHeader = () => {
  const tokenJson = cookies.get(authGuard.getCookieTokenNameByAmbient());
 
  if (tokenJson) {
    const serializedToken = tokenJson.token;
    return { Authorization: `Bearer ${serializedToken}` };
  }
};

const apiAprovacao = () =>
  axios.create({
    baseURL: BASE_URL_APROVACAO,
    headers: {
      'Content-Type': 'application/json',
      client_access: 'mais-aprovacao',
      ...getAuthorizationHeader()
    }
  });

export default apiAprovacao;
