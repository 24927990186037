import React, { useState } from 'react';

import { useStyles } from "./style";
import '../../styled/styles.css';

import PDPSwiperZoom from '../pdpSwiperZoom';

import { getResizedImage } from '../../../consts';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Mousewheel } from 'swiper/core';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css';

// swiper core styles
import 'swiper/swiper.min.css';

// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import noImage from '../../../assets/icons/no-img-448x672.svg';

import { Grid } from '@material-ui/core';

SwiperCore.use([Pagination, Navigation, Mousewheel]);

export default function PDPSwiper({
  images,
  product,
  voteOpts,
  vote,
  renderVote,
  obs,
  preco,
  precoAlvo,
  comment,
  grouping,
  classification,
  classificationOpts,
}) {
  const classes = useStyles();
  const [openZoom, setOpenZoom] = useState(false);
  const [firstImage, setFirstImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const slides = [];

  let imageWidth = parseInt(window.innerWidth / 3);
  let imageHeight = parseInt(
    ((window.innerHeight - 65) / window.innerHeight) * 100
  );
  let imageHeightPX = window.innerHeight - 65;

  function handleImage(image) {
    if (image) {
      let imageArray = image.split(',');

      imageArray.forEach(async (element) => {
        slides.push(
          <SwiperSlide
            key={`${element}`}
            className={classes.slide}
            style={{ width: `auto` }}
          >
            <Grid
              container
              justify="center"
              style={{
                minHeight: `${imageHeight}%`,
                maxHeight: `${imageHeight}%`,
                cursor: 'pointer',
              }}
            >
              <div className={classes.imageContainer}>
                <div
                  className={
                    !imageLoaded ? 'skeleton-box' : 'skeleton-box-static'
                  }
                  style={{
                    minHeight: `${imageHeight}%`,
                    minWidth: `30vw`,
                    height: imageHeightPX,
                    backgroundSize: `415px ${imageHeightPX}px`,
                    backgroundPosition: `-${imageWidth}px 1000px, 0 0, 0px 190px, 50px 195px`,
                  }}
                >
                  <img
                    alt={`Slide ${element}`}
                    src={getResizedImage(element, 700)}
                    crossOrigin="anonymous"
                    style={{ height: `100%`, backgroundColor: 'white' }}
                    onClick={() => {
                      setFirstImage(element);
                      setOpenZoom(true);
                    }}
                    onLoad={() => setImageLoaded(true)}
                  />
                </div>
              </div>
            </Grid>
          </SwiperSlide>
        );
      });

      return <>{slides}</>;
    } else {
      slides.push(
        <SwiperSlide
          key={`noImage`}
          className="mySwiper"
          style={{
            justifyContent: 'center',
            minWidth: '100%',
            display: 'flex',
          }}
        >
          <img src={noImage} alt={`Slide noImage`} crossOrigin="anonymous" />
        </SwiperSlide>
      );
      return slides;
    }
  }

  function swiperLoop(images) {
    if (images) {
      if (images.split(',').length > 3) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {openZoom && firstImage && (
        <PDPSwiperZoom
          product={product}
          images={images}
          openZoom={openZoom}
          firstImage={firstImage}
          renderVote={renderVote}
          setOpenZoom={setOpenZoom}
          setFirstImage={setFirstImage}
          obs={obs}
          preco={preco}
          precoAlvo={precoAlvo}
          comment={comment}
          grouping={grouping}
          classification={classification}
          classificationOpts={classificationOpts}
          vote={vote}
          voteOpts={voteOpts}
        />
      )}
      <Swiper
        id="main"
        mousewheel={true}
        loop={swiperLoop(images)}
        navigation={swiperLoop(images)}
        slidesPerView={'auto'}
        style={{ maxHeight: '100%' }}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        {handleImage(images)}
      </Swiper>
    </>
  );
}
