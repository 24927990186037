import React from 'react';
import { ROUTES_PATH } from '../routes/routes';
import { Navigate } from 'react-router-dom';
import { authGuard } from './AuthGuard';

export const DeniedRedirect = () => {
  const authed = authGuard.isAuthenticated();

  if (
    !window.location.href.includes('login') &&
    !window.location.href.includes('logout')
  ) {
    localStorage.setItem('redirect-page', window.location.href);
  }

  if (!authed) {
    return <Navigate to={ROUTES_PATH.login.use} />;
  }

  localStorage.setItem('loggingLoading', false);

  return <Navigate to={{ pathname: ROUTES_PATH.login.use }} />;
};
