import React, { useState, useEffect } from 'react';
import { useStyles } from './style';
import { useSnackbar as useSnackbarNotistack } from 'notistack';
import { withSnackbar } from 'notistack';
import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';

import { findCollections } from '../../services/collectionService';
import MenuCard from '../../components/group/menuCard';

import HomeBackgroundImage from '../../components/group/homeBackgroundImage';
import ResponsiveNavbar from '../../components/group/responsiveNavbar/index';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MenuIcon from '../../assets/icons/menu-icon.svg';
import { useRoutes } from '../../hooks/useRoutes';
import { useUser } from '../../hooks/useUser';

function Home() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbarNotistack();
  const { routes } = useRoutes();
  const { getMainBrandFromUser } = useUser();
  const [open, setOpen] = useState(false);
  const [collections, setCollections] = useState([]);
  const [prevCollections, setPrevCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [showCollectionMenu, setShowCollectionMenu] = useState(false);

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
    setShowCollectionMenu((s) => !s);
  };
  const handleClose = () => {
    setAnchor(null);
    setShowCollectionMenu((s) => !s);
  };

  async function getCollections() {
    setLoading(true);

    const { id_marca: brandFromUser } = getMainBrandFromUser();
    const response = await findCollections({
      'filter[id_marca]': brandFromUser
    });
    if (response) {
      if (response.length > 1) {
        setPrevCollections([
          response[response.length - 1],
          response[response.length - 2]
        ]);
      } else {
        setPrevCollections(response);
      }
      setLoading(false);
      return setCollections(response);
    } else {
      return enqueueSnackbar('Problema ao buscar coleções', {
        variant: 'error'
      });
    }
  }

  useEffect(() => {
    getCollections();
  }, []); //eslint-disable-line

  function openMenu() {
    return setOpen(!open);
  }

  return (
    <>
      <Grid container direction="row" justify="center">
        <ResponsiveNavbar openMenu={openMenu} open={open} />
        <Grid item xs={6} className={classes.homeContainer}>
          <IconButton onClick={() => openMenu()}>
            <img
              src={MenuIcon}
              style={{ width: 20, height: 20 }}
              alt="Menu-Icon"
            />
          </IconButton>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography className={classes.title}>Bem vindo à</Typography>
            <Typography className={classes.title}>
              Aprovação de Visual
            </Typography>
          </Grid>
          {loading ? (
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{
                minHeight: 200,
                minWidth: 300,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            prevCollections.length > 0 && (
              <>
                <Typography className={classes.subTitle}>
                  Selecionar coleção
                </Typography>
                <Grid item xs={12} className={classes.gridItem}>
                  {prevCollections.map((colecao, index) => (
                    <MenuCard
                      title={colecao.colecao}
                      key={`collection-${index}`}
                      redirectTo={colecao.colecao}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} style={{ margin: '36px 0px' }}>
                  <Typography
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className={classes.subTitle}
                    onClick={handleClick}
                  >
                    Coleções disponíveis
                    <ArrowForwardIosIcon
                      className={
                        showCollectionMenu
                          ? classes.arrowDropUp
                          : classes.arrowDropDown
                      }
                    />
                  </Typography>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchor}
                    open={showCollectionMenu}
                    onClose={handleClose}
                    style={{ marginLeft: 300 }}
                  >
                    {collections.map((colecao) => (
                      <MenuItem
                        key={`collection-${colecao}-item`}
                        onClick={() =>
                          routes.goToEspecifcRoute(
                            `/aprovacao?filter[colecao]=${colecao.colecao}&agrupamento=Linhas`
                          )
                        }
                      >
                        {colecao?.colecao}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </>
            )
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            background: `url(${HomeBackgroundImage()}) center center / cover no-repeat`,
            height: '100vh'
          }}
        ></Grid>
      </Grid>
    </>
  );
}
export default withSnackbar(Home);
