import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from './useUser';

const useFilters = () => {
  const { isUserFromFarmBR, isUserFromFabula} = useUser();
  const { getMainBrandFromUser } = useUser();
  const user =  useUser();
  const initialFilterOptions = {
    grouping: '',
    collection: '',
    selecteds: [],
    lines: [],
    groups: [],
    subGroups: [],
    segments: [],
    coordinates: [],
    stylists: [],
    preco: [],
    colors: [],
    productTypes: [],
    tiposFarm: [],
    racks: [],
    materials: [],
    wardrobes: [],
    pcpPeriods: [],
    mixedLines: [],
    suppliers: [],
    vitrine: [],
    classifications: [],
    business: [],
    clean: false,
    XAxis: {},
    YAxis: {}
  };

  const [loaded, setLoaded] = useState(false);
  const [filters, setFilters] = useState(initialFilterOptions);
  const [filtersLabels, setFiltersLabels] = useState([]);
  const location = useLocation();

  const addFilterLabel = (filterWithLabel) => {
    if (!filterWithLabel.value || !filterWithLabel.label) {
      removeFilterLabel(filterWithLabel);
      return;
    }
    let newFilter = filtersLabels;
    if (filterWithLabel.filtroAux === 'precoInicial') {
      newFilter = filtersLabels.filter((el) => el.filtroAux !== 'precoInicial');
    }
    if (filterWithLabel.filtroAux === 'precoLimite') {
      newFilter = filtersLabels.filter((el) => el.filtroAux !== 'precoLimite');
    }

    let r = newFilter.find((el) => el.id === filterWithLabel.id);
    if (!r) {
      newFilter.push(filterWithLabel);
      setFiltersLabels(newFilter.map((e) => e));
    } else {
      if (filterWithLabel.filtro !== 'selecteds') {
        removeFilterLabel(filterWithLabel);
      }
    }
  };

  const removeFilterLabel = (filterWithLabel) => {
    let filter = filtersLabels.filter((el) => el.id !== filterWithLabel.id);
    setFiltersLabels(filter.map((e) => e));
  };

  const cleanFilterLabels = () => {
    let filter = filtersLabels.filter((el) => el.filtro === 'selecteds');
    setFiltersLabels(filter);
  };

  const isGroupingLinhas = () => {
    return filters.grouping === 'Linhas';
  };

  const isGroupingCoordenados = () => {
    return filters.grouping === 'Coordenados';
  };

  const isGroupingSegmentos = () => {
    return filters.grouping === 'Segmentos';
  };

  const isGroupingPersonalizado = () => {
    return filters.grouping === 'Personalizado';
  };

  function getSingleParamFromUrl(param) {
    const urlSearch = new URLSearchParams(location.search);
    const value = urlSearch.get(param);
    if (value) {
      return value;
    }

    return '';
  }

  function getParamFromUrl(param) {
    const urlSearch = new URLSearchParams(location.search);
    const value = urlSearch.get(param);
    if (value) {
      return value.split(',');
    }
    return [];
  }

  const clearFilters = (keep = {}) => {
    setFilters({
      ...initialFilterOptions,
      ...keep
    });
  };

  const buildFilter = (objectName, fieldName) => {
    if (Array.isArray(filters[objectName]) && filters[objectName]?.length > 0) {
      return filters[objectName]
        .map((obj) => {
          if (fieldName) {
            return obj[fieldName];
          } else {
            return obj;
          }
        })
        .join(',');
    }

    return filters[objectName];
  };

  const buildFilterList = (objectNames) => {
    return objectNames
      .map((obj) => buildFilter(obj))
      .filter((item) => item?.length > 0)
      .join(',');
  };

  const formatFilter = (value, fieldName) => {
    if (value?.length > 0) {
      return {
        [`filter[${fieldName}]`]: value
      };
    }

    return {};
  };

  const getFilterGrouping = () => {
    const grouping = buildFilter('grouping');
    return { agrupamento: grouping };
  };

  const getFilterBrand = () => {
    const { id_marca: brandFromUser } = getMainBrandFromUser();
    if (user && brandFromUser) {
      return formatFilter(`${brandFromUser}`, 'id_marca');
    } else {
      return null;
    }
  };

  const getFilterCollection = () => {
    if (buildFilter('collection')) {
      const collection = buildFilter('collection');
      return formatFilter(collection, 'colecao');
    } else {
      const collection = getParamFromUrl('filter[colecao]');
      return formatFilter(...collection, 'colecao');
    }
  };

  const getFilterStylist = () => {
    const estilistas = buildFilter('stylists');
    return formatFilter(estilistas, 'id_estilista');
  };

  const getFilterProductType = () => {
    const tiposProduto = buildFilter('productTypes');
    return formatFilter(tiposProduto, 'no_tipo_produto');
  };

  const getFilterLines = () => {
    const linhas = buildFilter('lines');
    return formatFilter(linhas, 'id_linha');
  };

  const getFilterGroups = () => {
    const grupos = buildFilter('groups');
    return formatFilter(grupos, 'id_grupo_produto');
  };

  const getFilterCoordinates = () => {
    if (isUserFromFarmBR()) {
      const coordenados = buildFilter('coordinates');
      return formatFilter(coordenados, 'no_estampa_farm');
    }
    return {};
  };

  const getFilterColors = () => {
    if (!isUserFromFarmBR()) {
      const colors = buildFilter('colors');
      return formatFilter(colors, 'id_estampa_cor');
    }
    return {};
  };

  const getFilterSubGroups = () => {
    const subgrupo = buildFilter('subGroups');
    return formatFilter(subgrupo, 'no_subgrupo');
  };

  const getFilterSegments = () => {
    const segmento = buildFilter('segments');
    return formatFilter(segmento, 'no_segmento');
  };

  const getFilterPrice = () => {
    const preco = buildFilter('preco');
    return formatFilter(preco, 'preco');
  };

  const getFilterTipoFarm = () => {
    if (isUserFromFabula()) {
      const tiposFarm = buildFilter('tiposFarm');
      return formatFilter(tiposFarm, 'no_tipo_farm');
    }

    return {};
  };

  const getFilterRack = () => {
    const racks = buildFilter('racks');
    return formatFilter(racks, 'no_arara');
  };

  const getFilterMaterial = () => {
    const materials = buildFilter('materials');
    return formatFilter(materials, 'cd_material');
  };

  const getFilterWardrobe = () => {
    const wardrobes = buildFilter('wardrobes');
    return formatFilter(wardrobes, 'id_armario');
  };

  const getFilterPcpPeriod = () => {
    const pcpPeriods = buildFilter('pcpPeriods');
    return formatFilter(pcpPeriods, 'periodo_pcp');
  };

  const getFilterMixedLine = () => {
    const linhaMix = buildFilter('mixedLines');
    return formatFilter(linhaMix, 'linha_mix');
  };

  const getFilterVitrine = () => {
    const vitrine = buildFilter('vitrine');
    return formatFilter(vitrine, 'vitrine');
  };

  const getFilterSupplier = () => {
    const fornecedor = buildFilter('suppliers');
    return formatFilter(fornecedor, 'id_fornecedor');
  };

  const getFilterBusiness = () => {
    const negocio = buildFilter('business');
    return formatFilter(negocio, 'id_sexo_publico_alvo');
  };

  const getFilterClassification = () => {
    const classifications = buildFilter('classifications');
    return formatFilter(classifications, 'id_classificacao');
  };

  const getFilterXAxis = () => {
    const XAxis = buildFilter('XAxis');
    return formatFilter(XAxis.label, 'XAxis');
  };

  const getFilterYAxis = () => {
    const YAxis = buildFilter('YAxis');
    return formatFilter(YAxis.label, 'YAxis');
  };

  const buildFilters = () => ({
    ...getFilterXAxis(),
    ...getFilterYAxis(),
    ...getFilterGrouping(),
    ...getFilterBrand(),
    ...getFilterCollection(),
    ...getFilterStylist(),
    ...getFilterProductType(),
    ...getFilterLines(),
    ...getFilterGroups(),
    ...getFilterSubGroups(),
    ...getFilterSegments(),
    ...getFilterCoordinates(),
    ...getFilterColors(),
    ...getFilterPrice(),
    ...getFilterTipoFarm(),
    ...getFilterRack(),
    ...getFilterMaterial(),
    ...getFilterWardrobe(),
    ...getFilterPcpPeriod(),
    ...getFilterMixedLine(),
    ...getFilterVitrine(),
    ...getFilterSupplier(),
    ...getFilterBusiness(),
    ...getFilterClassification()
  });

  const loadCollection = () => {
    let localCollection = sessionStorage.getItem('aproveDataCollection');
    let collection;
    if (localCollection) {
      localCollection = JSON.parse(localCollection);
      collection = localCollection;
    }

    return collection || '';
  };

  const loadGrouping = () => {
    let grouping;
    let localGrouping = sessionStorage.getItem('aproveDataOption');
    localGrouping = JSON.parse(localGrouping);
    if (localGrouping) {
      grouping = localGrouping;
    } else {
      grouping = getSingleParamFromUrl('agrupamento');
    }

    return grouping;
  };

  const filtersFromURL = () => {
    const collection = loadCollection();
    const grouping = loadGrouping();
    const martixAxesDic = [
      { label: 'Arara', value: 'A' },
      { label: 'Grupo', value: 'G' },
      { label: 'Preço Venda', value: 'PV' },
      { label: 'Tipo Farm', value: 'TF' },
      { label: 'Linha', value: 'L' },
      { label: 'Periodo Pcp', value: 'PP' },
      isUserFromFarmBR()
        ? { label: 'Coordenado', value: 'C' }
        : { label: 'Cor', value: 'C' }
    ];

    const selecteds = getParamFromUrl('selecionados');
    const grupos = getParamFromUrl('filter[id_grupo_produto]');
    const subGrupos = getParamFromUrl('filter[no_subgrupo]');
    const segmentos = getParamFromUrl('filter[no_segmento]');
    const estilistas = getParamFromUrl('filter[id_estilista]');
    const linhas = getParamFromUrl('filter[id_linha]');
    const coordenados = getParamFromUrl('filter[no_estampa_farm]');
    const cores = getParamFromUrl('filter[id_estampa_cor]');
    const preco = getParamFromUrl('filter[preco]').map((pr) => parseInt(pr));
    const tiposProduto = getParamFromUrl('filter[no_tipo_produto]');
    const tiposFarm = getParamFromUrl('filter[no_tipo_farm]');
    const araras = getParamFromUrl('filter[no_arara]');
    const armarios = getParamFromUrl('filter[id_armario]');
    const priodosPCP = getParamFromUrl('filter[periodo_pcp]');
    const linhaMix = getParamFromUrl('filter[linha_mix]');
    const fornecedores = getParamFromUrl('filter[id_fornecedor]');
    const negocios = getParamFromUrl('filter[id_sexo_publico_alvo]');
    const classificacoes = getParamFromUrl('filter[id_classificacao]');
    const vitrine = getParamFromUrl('filter[vitrine]');
    const bases = getParamFromUrl('filter[cd_material]');
    let xAxis = getParamFromUrl('filter[XAxis]');
    let yAxis = getParamFromUrl('filter[YAxis]');

    martixAxesDic.forEach((axis) => {
      //eslint-disable-next-line
      if (axis.label == xAxis) {
        xAxis = axis;
      } //eslint-disable-next-line
      if (axis.label == yAxis) {
        yAxis = axis;
      }
    });

    setFilters({
      collection: collection,
      grouping,
      selecteds,
      colors: cores,
      lines: linhas,
      groups: grupos,
      subGroups: subGrupos,
      segments: segmentos,
      coordinates: coordenados,
      stylists: estilistas,
      productTypes: tiposProduto,
      preco,
      tiposFarm,
      racks: araras,
      wardrobes: armarios,
      pcpPeriods: priodosPCP,
      materials: bases,
      mixedLines: linhaMix,
      suppliers: fornecedores,
      business: negocios,
      classifications: classificacoes,
      vitrine: vitrine,
      XAxis: xAxis,
      YAxis: yAxis
    });
  };

  useEffect(() => {
    filtersFromURL();
    setLoaded(true);
    // eslint-disable-next-line
  }, []);

  return {
    filters,
    setFilters,
    buildFilters,
    buildFilter,
    buildFilterList,
    formatFilter,
    getFilterBrand,
    getFilterCollection,
    getFilterCoordinates,
    getFilterColors,
    getFilterGrouping,
    getFilterGroups,
    getFilterLines,
    getFilterPrice,
    getFilterStylist,
    getFilterProductType,
    getFilterSubGroups,
    getFilterSegments,
    getFilterTipoFarm,
    getFilterRack,
    getFilterMaterial,
    getFilterWardrobe,
    getFilterPcpPeriod,
    getFilterMixedLine,
    getFilterVitrine,
    getFilterSupplier,
    getFilterBusiness,
    getFilterClassification,
    getFilterXAxis,
    getFilterYAxis,
    getParamFromUrl,
    loadGrouping,
    loadCollection,
    clearFilters,
    isGroupingLinhas,
    isGroupingCoordenados,
    isGroupingSegmentos,
    isGroupingPersonalizado,
    addFilterLabel,
    removeFilterLabel,
    cleanFilterLabels,
    filtersLabels,
    loaded
  };
};

export default useFilters;
