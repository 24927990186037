import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      flexGrow: 1
    },
    matrix: {
      backgroundColor: '#FFF',
      height: 'calc(100vh - 93px)',
      display: 'flex',
      '&::after': {
        display: 'block'
      },
      '&::before': {
        display: 'block'
      }
    },
    text: {
      color: 'rgba(0, 0, 0, 1)',
      fontFamily: 'Flama medium',
      borderColor: 'primary',
      fontSize: 12,
      opacity: '0.5'
    },
    columnLabel: {
      transform: `rotate(270deg)`,
      minWidth: '260px',
      textAlign: 'center',
      padding: '10px'
    },
    text2: {
      color: '#000',
      opacity: '0.5',
      fontFamily: 'Flama'
    },
    text3: {
      color: '#FFF',
      fontFamily: 'Flama',
      borderColor: 'primary',
      fontSize: 12
    },
    painel: {
      background: '#FFFFFF',
      height: 48,
      position: 'sticky',
      top: 0,
      zIndex: 999
    },
    blackButton: {
      color: 'black',
      marginTop: -3
    },
    emoji: {
      marginLeft: 40,
      fontSize: '250px'
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '100%',
      fontSize: 14,
      letterSpacing: '0.1em'
    }
  }),
  { index: 1 }
);
