import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../../../hooks/useUser.jsx';
import {
  isDuplicatedProduct,
  isMovedProduct,
  isCreatedProduct,
  getStringClassification,
  getDuplicationTypeLabel
} from '../../../utils/productProperties.js';
import { formatPrice } from '../../../actions/utils.js';
import { useStyles } from './style.js';
import { withSnackbar } from 'notistack';
import useVotes from '../../../hooks/useVotesCount.jsx';

import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import deleteForeverOutlinedIcon from '../../../assets/icons/delete-icon.svg';
import shareIcon from '../../../assets/icons/share-icon.svg';
import undoIcon from '../../../assets/icons/undo-icon.svg';
import noImage from '../../../assets/img/no-img.png';

import FavoriteIcon from '@material-ui/icons/Favorite';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';

import SwipeableCarrousel from '../../group/swipeableViews/index.js';
import ProductPanel from '../../group/productPanel/index.js';
import { voteProduct, updateVote } from '../../../services/voteService.js';
import { duplicateProduct as duplicateProductService } from '../../../services/duplicateProductService.js';
import { moveProduct as manipulateProductService } from '../../../services/duplicateProductService.js';
import { deleteProduct as deleteProductService } from '../../../services/duplicateProductService.js';
import { getResizedImage } from '../../../consts.js';

function VoteCardProduct(props) {
  const {
    isUserFromFabula,
    isUserFromMariaFilo,
    isUserFromFarmBR,
    isUserFromAnimaleBR
  } = useUser();
  const classes = useStyles();
  const [vote, setVote] = useState(props.product.no_voto);
  const [voteOpts, setVoteOpts] = useState([]);
  const [voteId, setVoteId] = useState(props.product.id_voto || '');
  const [productVoteId, setProductVoteId] = useState(
    props.product.id_voto_produto_duplicado ||
      props.product.id_voto_produto ||
      ''
  );
  const [duplicateOpt, setDuplicateOpt] = useState('Grupo');
  const [manipulateOpt, setManipulateOpt] = useState('Grupo');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElHistory, setAnchorElHistory] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openPDP, setOpenPDP] = useState(false);
  const [info, setInfo] = useState(false);
  const [createComment, setCreateComment] = useState(false);
  const [comment, setComment] = useState(props.product.ds_comentario);
  const [createObs, setCreateObs] = useState(false);
  const [obs, setObs] = useState(props.product.ds_observacao);
  const [createPreco, setCreatePreco] = useState(false);
  const [createPrecoAlvo, setCreatePrecoAlvo] = useState(false);
  const [preco, setPreco] = useState(props.product.vl_percebido / 100 || null);
  const [precoAlvo, setPrecoAlvo] = useState(
    props.product.vl_preco_alvo / 100 || null
  );
  const [classification, setClassification] = useState({
    id_classificacao: props.handleClassificationVotes(
      props.product.classificacao
    )
  });
  const [createClassification, setCreateClassification] = useState(false);
  const [isNewVoteParam, setIsNewVoteParam] = useState(
    productVoteId !== '' ||
      props.product.vl_percebido ||
      props.product.vl_preco_percebido ||
      props.product.ds_comentario ||
      props.product.ds_observacao ||
      parseInt(props.product.classificacao) > 0
  );
  const cardSize = { width: 260, heigth: 422, sizeXs: 'Aprovacao' };
  const cardSize2 = { width: 260, heigth: 400, sizeXs: 'Duplicate' };
  const groupsOpts = props.groups;
  const colorsOpts = props.colors || [];
  const coordinatesOpts = props.coordinates || [];
  const subGroupsManipulateOpts = props.subGroupsManipulateOpts || [];
  const linesManipulateOpts = props.linesManipulateOpts || [];
  const racksManipulateOpts = props.racksManipulateOpts || [];
  const farmTypesManipulateOpts = props.farmTypesManipulateOpts || [];
  const segmentsManipulateOpts = props.segmentsManipulateOpts || [];
  const [isDuplication, setIsDuplication] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [manipulateProduct, setManipulateProduct] = useState({
    id_produto_estilo: props.product.id_produto_estilo,
    ds_produto: props.product.ds_produto,
    estampa_cor: props.product.estampa_cor,
    id_estampa_cor: props.product.id_estampa_cor,
    no_arara: props.product.no_arara,
    no_tipo_farm: props.product.no_tipo_farm,
    no_segmento: props.product.no_segmento,
    preco: props.product.preco,
    no_estampa_farm:
      props.grouping === 'Linhas' || props.grouping === 'Personalizado'
        ? props.product.estampa
        : props.product.coordenado,
    no_subgrupo: props.product.no_subgrupo,
    id_grupo_produto: props.product.id_grupo_produto,
    grupo_produto: props.product.grupo_produto,
    id_linha: props.product.id_linha,
    linha: props.product.linha
  });
  const [duplicateProduct, setDuplicateProduct] = useState({
    id_produto_estilo: props.product.id_produto_estilo,
    ds_produto: props.product.ds_produto,
    estampa_cor: props.product.estampa_cor,
    id_estampa_cor: [],
    desc_estampa_cor: [],
    no_estampa_farm: [],
    no_subgrupo: props.product.no_subgrupo,
    id_grupo_produto: props.product.id_grupo_produto,
    grupo_produto: props.product.grupo_produto,
    id_linha: props.product.id_linha,
    linha: props.product.linha,
    no_arara: props.product.no_arara,
    no_segmento: props.product.no_segmento,
    no_tipo_farm: props.product.no_tipo_farm,
    preco: props.product.preco
  });
  const [checkedManipulateProduct, setCheckedManipulateProduct] =
    useState(false);
  const [checkedDuplicateProduct, setCheckedDuplicateProduct] = useState(false);
  const [awaytingRequest, setAwaytingRequest] = useState(false);
  const votesCount = useVotes();

  const filterOptions = createFilterOptions({
    limit: 10
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose2 = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDuplicateOpt = (event) => {
    setDuplicateOpt(event.target.value);
  };

  const handleManipulateOpt = (event) => {
    setManipulateOpt(event.target.value);
  };

  const handleClassificationOpt = (e) => {
    setClassification({ id_classificacao: [e.target.value.id_classificacao] });
  };

  const handleClassificationOptions = (newClassification) => {
    if (classification.id_classificacao[0] === 0) {
      setClassification({ id_classificacao: [newClassification] });
    }

    if (
      Object.values(classification.id_classificacao).includes(newClassification)
    ) {
      const newClassifications = classification.id_classificacao.filter(
        (opc) => opc !== newClassification
      );

      if (newClassifications.length > 0) {
        setClassification({ id_classificacao: newClassifications });
      } else {
        setClassification({ id_classificacao: [0] });
      }
    }

    if (
      classification.id_classificacao.length >= 1 &&
      classification.id_classificacao[0] !== 0 &&
      !Object.values(classification.id_classificacao).includes(
        newClassification
      )
    ) {
      setClassification({
        id_classificacao: [
          ...classification.id_classificacao,
          newClassification
        ]
      });
    }
  };

  useEffect(() => {
    setVoteOpts(props.voteOpts);
    setVote(props.product.no_voto);
    setVoteId(props.product.id_voto);
    // eslint-disable-next-line
  }, []);

  function handleImage(image, firstImage, width) {
    let newImage = [];
    let newWidth;
    if (width) {
      newWidth = width;
    } else {
      newWidth = 450;
    }
    if (image) {
      image = image.split(',');
      image.forEach((element) => {
        let cover = getResizedImage(element, newWidth);
        newImage.push(cover);
      });

      return newImage;
    }
    if (firstImage) {
      let cover = getResizedImage(firstImage, newWidth);
      newImage.push(cover);
      return newImage;
    } else {
      return noImage;
    }
  }

  const saveVote = useCallback(
    async (product, value, value_id, value_cor, tag) => {
      //handle pevious vote param in cause of bad request
      const previousVote = vote;
      const previousVoteId = voteId;

      //set votes and close menu while awaiting request
      setAnchorEl(false);
      setVote(value);
      setVoteId(value_id);

      //remove value for already voted product
      if (vote === value) {
        if (['Aprovado', 'Reprovado'].includes(value)) {
          value = null;
          value_id = null;
        } else {
          return;
        }
      }

      const isDuplicated = product.id_produto_duplicado ? true : false;
      //check product id for duplicated product
      const product_id = isDuplicated
        ? product.id_produto_duplicado
        : product.id_produto_estilo;
      //format req price value
      const valor = preco * 100;
      const valorAlvo = precoAlvo * 100;

      //check if product already has a vote param
      const productVotesIds = !isNewVoteParam
        ? isDuplicated
          ? { id_produto_duplicado: product_id }
          : { id_produto_estilo: product_id }
        : isDuplicated
        ? {
            id_produto_duplicado: product_id,
            id_voto_produto_duplicado: productVoteId
          }
        : { id_produto_estilo: product_id, id_voto_produto: productVoteId };

      const params = {
        ...productVotesIds,
        id_voto: value_id,
        vl_percebido: valor,
        vl_preco_alvo: valorAlvo,
        ds_comentario: comment,
        ds_observacao: obs,
        classificacao: classification.id_classificacao
      };
      votesCount.storeVotes(value_id, voteId);

      try {
        if (!isNewVoteParam) {
          const response = await voteProduct(params);

          if (response?.statusCode === 400) {
            throw Error;
          }
          props.setVoteCount(props.voteCount + 1);
          setIsNewVoteParam(true);
          isDuplicated
            ? setProductVoteId(response.message.id_voto_produto_duplicado)
            : setProductVoteId(response.message.id_voto_produto);
        } else {
          const response = await updateVote(params);
          isDuplicated &&
            setProductVoteId(response.message.id_voto_produto_duplicado);
        }
      } catch (error) {
        setVote(previousVote);
        setVoteId(previousVoteId);

        return props.enqueueSnackbar(
          `Problema ao salvar o voto do produto ${product.produto} - ${product.ds_produto}`,
          { variant: 'error' }
        );
      }
    },
    [
      classification.id_classificacao,
      comment,
      isNewVoteParam,
      obs,
      preco,
      precoAlvo,
      productVoteId,
      props,
      vote,
      voteId,
      votesCount
    ]
  );

  const renderVotingOpts = useCallback(() => {
    if (voteOpts.length < 2) {
      return null;
    } else {
      return (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
        >
          {voteOpts.map(
            (vote) =>
              vote.in_escala_positiva === 1 && (
                <MenuItem
                  key={`voteOption${vote}`}
                  style={{ width: 120, height: 40 }}
                  onClick={() => {
                    saveVote(
                      props.product,
                      vote.no_voto,
                      vote.id_voto,
                      vote.cd_cor_voto
                    );
                  }}
                >
                  <FavoriteIcon style={{ fill: `${vote.cd_cor_voto}` }} />
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: props.zoom * 12,
                      fontStyle: 'normal',
                      lineHeight: props.zoom * 14,
                      letterSpacing: '0em'
                    }}
                  >
                    {vote.no_voto}
                  </Typography>
                </MenuItem>
              )
          )}
          <MenuItem
            key={`voteOptionRemove`}
            style={{ width: 120, height: 40 }}
            onClick={() => {
              saveVote(props.product, null, null);
            }}
          >
            <FavoriteBorderIcon />
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: props.zoom * 12,
                fontStyle: 'normal',
                lineHeight: props.zoom * 14,
                letterSpacing: '0em'
              }}
            >
              Limpar
            </Typography>
          </MenuItem>
        </Menu>
      );
    }
  }, [voteOpts, props.product, props.zoom, saveVote, anchorEl]);

  async function duplicateProductFunction() {
    setOpen(false);
    props.enqueueSnackbar(`Aguarde Enquanto Duplicamos Seu Produto`, {
      variant: 'info'
    });
    try {
      if (isUserFromFarmBR()) {
        const response = await duplicateProductService({
          id_produto_estilo: duplicateProduct.id_produto_estilo,
          ds_produto: duplicateProduct.ds_produto,
          id_estampa_cor:
            duplicateProduct.id_estampa_cor.length > 0
              ? duplicateProduct.id_estampa_cor
              : [props.product.id_estampa_cor],
          id_grupo_produto: duplicateProduct.id_grupo_produto,
          no_subgrupo: duplicateProduct.no_subgrupo,
          id_linha: duplicateProduct.id_linha,
          no_arara: duplicateProduct.no_arara,
          no_segmento: duplicateProduct.no_segmento,
          no_tipo_farm: duplicateProduct.no_tipo_farm,
          preco: duplicateProduct.preco * 100,
          no_estampa_farm:
            duplicateProduct.no_estampa_farm.length > 0
              ? duplicateProduct.no_estampa_farm
              : [props.product.estampa]
        });
        if (response) {
          setCheckedDuplicateProduct(!checkedDuplicateProduct);
          setOpen(false);
          props.enqueueSnackbar('Produto Duplicado Com Sucesso', {
            variant: 'success'
          });
          return props.getMappings();
        } else {
          return props.enqueueSnackbar('Erro ao Duplicar', {
            variant: 'error'
          });
        }
      } else {
        const response = await duplicateProductService({
          id_produto_estilo: duplicateProduct.id_produto_estilo,
          ds_produto: duplicateProduct.ds_produto,
          id_estampa_cor:
            duplicateProduct.id_estampa_cor.length > 0
              ? duplicateProduct.id_estampa_cor
              : [props.product.id_estampa_cor],
          id_grupo_produto: duplicateProduct.id_grupo_produto,
          no_subgrupo: duplicateProduct.no_subgrupo,
          no_arara: duplicateProduct.no_arara,
          no_tipo_farm: duplicateProduct.no_tipo_farm,
          no_segmento: duplicateProduct.no_segmento,
          preco: duplicateProduct.preco * 100,
          id_linha: duplicateProduct.id_linha
        });
        if (response) {
          setCheckedDuplicateProduct(!checkedDuplicateProduct);
          setCheckedDuplicateProduct(!checkedDuplicateProduct);
          props.enqueueSnackbar('Produto Duplicado Com Sucesso', {
            variant: 'success'
          });
          return props.getMappings();
        }
      }
    } catch {
      setCheckedDuplicateProduct(!checkedDuplicateProduct);
      return props.enqueueSnackbar('Erro ao Duplicar', { variant: 'error' });
    }
  }

  async function moveProductFunction() {
    props.enqueueSnackbar(`Aguarde Enquanto Movimentamos Seu Produto`, {
      variant: 'info'
    });
    setOpen(false);
    if (isUserFromFarmBR()) {
      const response = await manipulateProductService({
        id_produto_estilo: manipulateProduct.id_produto_estilo,
        ds_produto: manipulateProduct.ds_produto,
        no_arara: manipulateProduct.no_arara,
        no_tipo_farm: manipulateProduct.no_tipo_farm,
        no_segmento: manipulateProduct.no_segmento,
        preco: manipulateProduct.preco * 100,
        id_estampa_cor: manipulateProduct.id_estampa_cor,
        no_subgrupo: manipulateProduct.no_subgrupo,
        id_grupo_produto: manipulateProduct.id_grupo_produto,
        id_linha: manipulateProduct.id_linha,
        no_estampa_farm: manipulateProduct.no_estampa_farm
      });
      if (response) {
        props.enqueueSnackbar('Produto Movimentado Com Sucesso', {
          variant: 'success'
        });
        return props.getMappings();
      } else {
        return props.enqueueSnackbar('Erro ao Movimentar', {
          variant: 'error'
        });
      }
    } else {
      const response = await manipulateProductService({
        id_produto_estilo: manipulateProduct.id_produto_estilo,
        ds_produto: manipulateProduct.ds_produto,
        id_estampa_cor: manipulateProduct.id_estampa_cor,
        no_subgrupo: manipulateProduct.no_subgrupo,
        id_grupo_produto: manipulateProduct.id_grupo_produto,
        id_linha: manipulateProduct.id_linha,
        no_arara: manipulateProduct.no_arara,
        no_tipo_farm: manipulateProduct.no_tipo_farm,
        no_segmento: manipulateProduct.no_segmento,
        preco: manipulateProduct.preco * 100
      });
      if (response) {
        props.enqueueSnackbar('Produto Movimentado Com Sucesso', {
          variant: 'success'
        });
        return props.getMappings();
      } else {
        return props.enqueueSnackbar('Erro ao Movimentar', {
          variant: 'error'
        });
      }
    }
  }

  const deleteProductFunction = useCallback(
    async (product) => {
      setAwaytingRequest(true);
      const response = await deleteProductService(
        product.id_produto_duplicado,
        product.id_tipo_produto_duplicado
      );
      if (response.code === 'OK') {
        setOpen2(false);
        setAwaytingRequest(false);
        props.enqueueSnackbar('Produto Desfeito Com Sucesso', {
          variant: 'success'
        });
        return props.getMappings();
      } else {
        setAwaytingRequest(false);
        return props.enqueueSnackbar(`${response.code}:${response.message}`, {
          variant: 'error'
        });
      }
    },
    [props]
  );

  function renderManipulateButtons(
    Opts,
    label,
    id,
    typeOfManipulation,
    setTypeOfManipulation,
    type
  ) {
    const includes = (value, paramName) => {
      return typeOfManipulation[paramName]?.find(
        (vl) => `${vl}` === `${value}`
      );
    };

    function saveOption(opt) {
      if (
        !typeOfManipulation[id].find((filter) => `${filter}` === `${opt[id]}`)
      ) {
        setTypeOfManipulation({
          ...typeOfManipulation,
          [id]: [...typeOfManipulation[id], opt[id]],
          [label]: [...typeOfManipulation[label], opt[label]]
        });
      } else {
        setTypeOfManipulation({
          ...typeOfManipulation,
          [id]: typeOfManipulation[id].filter((f) => `${f}` !== `${opt[id]}`),
          [label]: typeOfManipulation[label].filter(
            (f) => `${f}` !== `${opt[label]}`
          )
        });
      }
    }

    return (
      Opts && ( //eslint-disable-next-line
        <RadioGroup>
          {Opts.map((opt) => (
            <div
              key={`${opt[label]}-manipulate-Opt`}
              style={{ cursor: 'pointer', padding: '0px 25px' }}
              onClick={() => {
                if (type === 'checkbox') {
                  saveOption(opt);
                } else {
                  setTypeOfManipulation({
                    ...typeOfManipulation,
                    [label === 'desc_estampa_cor' ? 'no_estampa_farm' : label]:
                      opt[label],
                    [id]: opt[id]
                  });
                }
              }}
            >
              <FormControlLabel
                style={{ pointerEvents: 'none' }}
                label={
                  <Typography
                    className={classes.cardText}
                    style={{
                      fontSize: 14,
                      textAlign: 'Left',
                      fontFamily: 'Flama'
                    }}
                  >
                    {opt[label]}
                  </Typography>
                }
                control={
                  type === 'checkbox' ? (
                    <Checkbox
                      checked={!!includes(opt[id], id)}
                      style={{ pointerEvents: 'none' }}
                      color="primary"
                    />
                  ) : (
                    <Radio
                      checked={typeOfManipulation[id] === opt[id]}
                      style={{ pointerEvents: 'none' }}
                      color="primary"
                    />
                  )
                }
              />
            </div>
          ))}
        </RadioGroup>
      )
    );
  }

  async function registerPreco(priceType) {
    const valorAlvo = precoAlvo * 100;
    const valor = preco * 100; // eslint-disable-next-line
    const product_id =
      (props.product.id_produto_duplicado &&
        props.product.id_produto_duplicado) ||
      props.product.id_produto_estilo;
    if (!props.product.id_produto_duplicado) {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_estilo: product_id,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          ds_comentario: comment,
          classificacao: classification.id_classificacao,
          ds_observacao: obs
        });
        if (!!response.code) {
          setProductVoteId(response.message.id_voto_produto);
          props.enqueueSnackbar(
            `Preço do Produto ${props.product.produto} Criado com Sucesso`,
            { variant: 'success' }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Preço do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      } else {
        const response = await updateVote({
          id_produto_estilo: product_id,
          id_voto_produto: productVoteId,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          ds_comentario: comment,
          classificacao: classification.id_classificacao,
          ds_observacao: obs
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `Preço do Produto ${props.product.produto} Criado com Sucesso`,
            { variant: 'success' }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Preço do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      }
    } else {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_duplicado: product_id,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          ds_comentario: comment,
          ds_observacao: obs,
          classificacao: classification.id_classificacao
        });

        setProductVoteId(response.message.id_voto_produto_duplicado);

        if (!!response.code)
          props.enqueueSnackbar(
            `Preço do Produto ${props.product.produto} Criado com Sucesso`,
            { variant: 'success' }
          );
        else
          props.enqueueSnackbar(
            `Erro Criando Preço do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      } else {
        const response = await updateVote({
          id_produto_duplicado: product_id,
          id_voto_produto_duplicado: productVoteId,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          ds_comentario: comment,
          ds_observacao: obs,
          classificacao: classification.id_classificacao
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `Preço do Produto ${props.product.produto} Criado com Sucesso`,
            { variant: 'success' }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Preço do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      }
    }
    if (priceType === 'Preco') {
      setCreatePreco(!createPreco);
    }
    if (priceType === 'PrecoAlvo') {
      setCreatePrecoAlvo(!createPrecoAlvo);
    }
  }

  async function registerComentary() {
    const valorAlvo = precoAlvo ? precoAlvo * 100 : 0;
    const valor = preco ? preco * 100 : 0;
    // eslint-disable-next-line
    const product_id =
      (props.product.id_produto_duplicado &&
        props.product.id_produto_duplicado) ||
      props.product.id_produto_estilo;
    if (!props.product.id_produto_duplicado) {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_estilo: product_id,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao,
          ds_observacao: obs
        });
        if (!!response.code) {
          setProductVoteId(response.message.id_voto_produto);
          props.enqueueSnackbar(
            `Comentário do Produto ${props.product.produto} Criado com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Comentário do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      } else {
        const response = await updateVote({
          id_produto_estilo: product_id,
          id_voto_produto: productVoteId,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao,
          ds_observacao: obs
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `Comentário do Produto ${props.product.produto} Criado com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Comentário do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      }
    } else {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_duplicado: product_id,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao,
          ds_observacao: obs
        });

        setProductVoteId(response.message.id_voto_produto_duplicado);

        if (!!response.code) {
          props.enqueueSnackbar(
            `Comentário do Produto ${props.product.produto} Criado com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Comentário do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      } else {
        const response = await updateVote({
          id_produto_duplicado: product_id,
          id_voto_produto_duplicado: productVoteId,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao,
          ds_observacao: obs
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `Comentário do Produto ${props.product.produto} Criado com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Comentário do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      }
    }

    setCreateComment(!createComment);
  }

  async function registerObs() {
    setCreateObs(!createObs);
    const valorAlvo = precoAlvo * 100;
    const valor = preco * 100;
    // eslint-disable-next-line
    const product_id =
      (props.product.id_produto_duplicado &&
        props.product.id_produto_duplicado) ||
      props.product.id_produto_estilo;
    if (!props.product.id_produto_duplicado) {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_estilo: product_id,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });

        setProductVoteId(response.message.id_voto_produto_duplicado);

        if (!!response.code) {
          setProductVoteId(response.message.id_voto_produto);
          props.enqueueSnackbar(
            `Observação do Produto ${props.product.produto} Criada com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Observação do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      } else {
        const response = await updateVote({
          id_produto_estilo: product_id,
          id_voto_produto: productVoteId,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `Observação do Produto ${props.product.produto} Criada com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando Observação do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      }
    } else {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_duplicado: product_id,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });
        if (!!response.code)
          props.enqueueSnackbar(
            `Observação do Produto ${props.product.produto} Criada com Sucesso`,
            {
              variant: 'success'
            }
          );
        else
          props.enqueueSnackbar(
            `Erro Criando Observação do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      } else {
        const response = await updateVote({
          id_produto_duplicado: product_id,
          id_voto_produto_duplicado: productVoteId,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });
        if (!!response.code)
          props.enqueueSnackbar(
            `Observação do Produto ${props.product.produto} Criada com Sucesso`,
            {
              variant: 'success'
            }
          );
        else
          props.enqueueSnackbar(
            `Erro Criando Observação do Produto ${props.product.produto} `,
            { variant: 'error' }
          );
      }
    }
  }

  async function registerClassification() {
    const valor = preco * 100;
    const valorAlvo = precoAlvo * 100;

    if (classification.id_classificacao[0] === 0) {
      setClassification({
        id_classificacao: props.handleClassificationVotes(
          props.product.classificacao
        )
      });
    }

    setCreateClassification(!createClassification);
    // eslint-disable-next-line
    const product_id =
      (props.product.id_produto_duplicado &&
        props.product.id_produto_duplicado) ||
      props.product.id_produto_estilo;
    if (!props.product.id_produto_duplicado) {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_estilo: product_id,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });
        if (!!response.code) {
          setProductVoteId(response.message.id_voto_produto);
          props.enqueueSnackbar(
            `${isUserFromFarmBR() ? 'Atributo' : 'Classificação'} do Produto ${
              props.product.produto
            } ${isUserFromFarmBR() ? 'Atualizado' : 'Atualizada'} Com Sucesso`,
            { variant: 'success' }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando ${
              isUserFromFarmBR() ? 'Atributo' : 'Classificação'
            } do Produto ${props.product.produto} `,
            {
              variant: 'error'
            }
          );
      } else {
        const response = await updateVote({
          id_produto_estilo: product_id,
          id_voto_produto: productVoteId,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `${isUserFromFarmBR() ? 'Atributo' : 'Classificação'} do Produto ${
              props.product.produto
            } ${isUserFromFarmBR() ? 'Atualizado' : 'Atualizada'} com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando ${
              isUserFromFarmBR() ? 'Atributo' : 'Classificação'
            } do Produto ${props.product.produto} `,
            {
              variant: 'error'
            }
          );
      }
    } else {
      if (!isNewVoteParam) {
        setIsNewVoteParam(true);
        const response = await voteProduct({
          id_produto_duplicado: product_id,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });

        setProductVoteId(response.message.id_voto_produto_duplicado);

        if (!!response.code)
          props.enqueueSnackbar(
            `${isUserFromFarmBR() ? 'Atributo' : 'Classificação'} do Produto ${
              props.product.produto
            } Criada com Sucesso`,
            {
              variant: 'success'
            }
          );
        else
          props.enqueueSnackbar(
            `Erro Criando ${
              isUserFromFarmBR() ? 'Atributo' : 'Classificação'
            } do Produto ${props.product.produto} `,
            {
              variant: 'error'
            }
          );
      } else {
        const response = await updateVote({
          id_produto_duplicado: product_id,
          id_voto_produto_duplicado: productVoteId,
          ds_observacao: obs,
          ds_comentario: comment,
          vl_percebido: valor,
          vl_preco_alvo: valorAlvo,
          classificacao: classification.id_classificacao
        });
        if (!!response.code) {
          props.enqueueSnackbar(
            `${isUserFromFarmBR() ? 'Atributo' : 'Classificação'} do Produto ${
              props.product.produto
            } Criada com Sucesso`,
            {
              variant: 'success'
            }
          );
        } else
          props.enqueueSnackbar(
            `Erro Criando ${
              isUserFromFarmBR() ? 'Atributo' : 'Classificação'
            } do Produto ${props.product.produto} `,
            {
              variant: 'error'
            }
          );
      }
    }
  }

  const onSelectMove = (event, newValue) => {
    setDuplicateProduct({
      id_produto_estilo: props.product.id_produto_estilo,
      ds_produto: props.product.ds_produto,
      estampa_cor: props.product.estampa_cor,
      id_estampa_cor: [],
      desc_estampa_cor: [],
      no_estampa_farm: [],
      no_subgrupo: props.product.no_subgrupo,
      id_grupo_produto: props.product.id_grupo_produto,
      grupo_produto: props.product.grupo_produto,
      id_linha: props.product.id_linha,
      no_arara: props.product.no_arara,
      no_tipo_farm: props.product.no_tipo_farm,
      no_segmento: props.product.no_segmento,
      preco: props.product.preco,
      linha: props.product.linha
    });

    setManipulateProduct({
      id_produto_estilo: props.product.id_produto_estilo,
      ds_produto: props.product.ds_produto,
      estampa_cor: props.product.estampa_cor,
      id_estampa_cor: props.product.id_estampa_cor,
      no_estampa_farm:
        props.grouping === 'Linhas' || props.grouping === 'Personalizado'
          ? props.product.estampa
          : props.product.coordenado,
      no_subgrupo: props.product.no_subgrupo,
      id_grupo_produto: props.product.id_grupo_produto,
      grupo_produto: props.product.grupo_produto,
      id_linha: props.product.id_linha,
      no_arara: props.product.no_arara,
      no_tipo_farm: props.product.no_tipo_farm,
      no_segmento: props.product.no_segmento,
      preco: props.product.preco,
      linha: props.product.linha
    });

    setIsDuplication(!isDuplication);

    setTabValue(newValue);
  };

  const checkManipulateProduct = () => {
    if (
      manipulateProduct.id_produto_estilo !== props.product.id_produto_estilo ||
      manipulateProduct.ds_produto !== props.product.ds_produto ||
      // manipulateProduct.estampa_cor !== props.product.estampa_cor ||
      manipulateProduct.id_estampa_cor !== props.product.id_estampa_cor ||
      manipulateProduct.no_estampa_farm !==
        (props.grouping === 'Linhas' || props.grouping === 'Personalizado'
          ? props.product.estampa
          : props.product.coordenado) ||
      manipulateProduct.no_subgrupo !== props.product.no_subgrupo ||
      manipulateProduct.no_arara !== props.product.no_arara ||
      manipulateProduct.no_tipo_farm !== props.product.no_tipo_farm ||
      manipulateProduct.no_segmento !== props.product.no_segmento ||
      manipulateProduct.preco !== props.product.preco ||
      manipulateProduct.id_grupo_produto !== props.product.id_grupo_produto ||
      // manipulateProduct.grupo_produto !== props.product.grupo_produto ||
      manipulateProduct.id_linha !== props.product.id_linha ||
      manipulateProduct.linha !== props.product.linha
    ) {
      return setCheckedManipulateProduct(true);
    }
    return setCheckedManipulateProduct(false);
  };

  const checkDuplicateProduct = () => {
    if (
      duplicateProduct.id_produto_estilo !== props.product.id_produto_estilo ||
      duplicateProduct.ds_produto !== props.product.ds_produto ||
      duplicateProduct.estampa_cor !== props.product.estampa_cor ||
      duplicateProduct.id_estampa_cor.length > 0 ||
      duplicateProduct.no_estampa_farm.length > 0 ||
      duplicateProduct.no_subgrupo !== props.product.no_subgrupo ||
      duplicateProduct.id_grupo_produto !== props.product.id_grupo_produto ||
      duplicateProduct.id_linha !== props.product.id_linha ||
      duplicateProduct.no_arara !== props.product.no_arara ||
      duplicateProduct.no_tipo_farm !== props.product.no_tipo_farm ||
      duplicateProduct.no_segmento !== props.product.no_segmento ||
      duplicateProduct.preco !== props.product.preco ||
      duplicateProduct.linha !== props.product.linha
    ) {
      return setCheckedDuplicateProduct(true);
    }
    return setCheckedDuplicateProduct(false);
  };

  useEffect(() => {
    checkManipulateProduct();
  }, [manipulateProduct]); //eslint-disable-line

  useEffect(() => {
    checkDuplicateProduct();
  }, [duplicateProduct]); //eslint-disable-line

  const renderDiffProperties = useCallback(
    (product) => {
      const {
        linha,
        linha_original,
        coordenado,
        estampa,
        desc_estampa_cor_original,
        grupo_atelier,
        grupo_atelier_original,
        no_segmento,
        no_segmento_original,
        no_arara,
        no_arara_original,
        no_tipo_farm,
        no_tipo_farm_original,
        preco,
        preco_original
      } = props.product;

      function handleCloseHistory() {
        setAnchorElHistory(null);
      }

      return (
        <Menu
          anchorEl={anchorElHistory}
          open={!!anchorElHistory}
          MenuListProps={{ onMouseLeave: handleCloseHistory }}
        >
          <Grid
            container
            style={{
              padding: '2px 14px',
              minWidth: 100,
              display: 'inline-block'
            }}
          >
            <Grid
              item
              xs={12}
              className={classes.historyCardText}
              style={{ opacity: 0.7, fontWeight: 'bold' }}
            >
              Original:
            </Grid>
            {grupo_atelier_original !== grupo_atelier && (
              <Grid item xs={12} className={classes.historyCardText}>
                Grupo: {grupo_atelier_original}
              </Grid>
            )}
            {linha_original !== linha && (
              <Grid item xs={12} className={classes.historyCardText}>
                Linha: {linha_original}
              </Grid>
            )}
            {props.filterData.isGroupingCoordenados()
              ? desc_estampa_cor_original !== coordenado && (
                  <Grid item xs={12} className={classes.historyCardText}>
                    Cor: {desc_estampa_cor_original}
                  </Grid>
                )
              : desc_estampa_cor_original !== estampa && (
                  <Grid item xs={12} className={classes.historyCardText}>
                    {isUserFromFarmBR() ? 'Coordenado:' : 'Cor:'}{' '}
                    {desc_estampa_cor_original}
                  </Grid>
                )}
            {!!preco && preco_original !== preco && (
              <Grid item xs={12} className={classes.historyCardText}>
                Preço: R$
                {preco_original
                  ? (preco_original / 100).toFixed(2).replace('.', ',')
                  : '0,00'}
              </Grid>
            )}
            {no_arara && no_arara_original !== no_arara && (
              <Grid item xs={12} className={classes.historyCardText}>
                Arara: {no_arara_original}
              </Grid>
            )}
            {no_tipo_farm && no_tipo_farm_original !== no_tipo_farm && (
              <Grid item xs={12} className={classes.historyCardText}>
                Tipo Farm: {no_tipo_farm_original}
              </Grid>
            )}
            {isUserFromFabula() &&
              no_segmento &&
              no_segmento_original !== no_segmento && (
                <Grid item xs={12} className={classes.historyCardText}>
                  Segmento: {no_segmento_original}
                </Grid>
              )}
            <Grid
              item
              xs={12}
              className={classes.historyCardText}
              style={{ opacity: 0.7, marginTop: 6, fontWeight: 'bold' }}
            >
              {getDuplicationTypeLabel(product)}:{/* aqui */}
            </Grid>
            {grupo_atelier_original !== grupo_atelier && (
              <Grid item xs={12} className={classes.historyCardText}>
                Grupo: {grupo_atelier}
              </Grid>
            )}
            {linha_original !== linha && (
              <Grid item xs={12} className={classes.historyCardText}>
                Linha: {linha}
              </Grid>
            )}
            {props.filterData.isGroupingCoordenados()
              ? desc_estampa_cor_original !== coordenado && (
                  <Grid item xs={12} className={classes.historyCardText}>
                    Cor: {coordenado}
                  </Grid>
                )
              : desc_estampa_cor_original !== estampa && (
                  <Grid item xs={12} className={classes.historyCardText}>
                    {isUserFromFarmBR() ? 'Coordenado:' : 'Cor:'} {estampa}
                  </Grid>
                )}
            {!!preco && preco_original !== preco && (
              <Grid item xs={12} className={classes.historyCardText}>
                Preço: R${preco ? preco.toFixed(2).replace('.', ',') : '0,00'}
              </Grid>
            )}
            {no_arara && no_arara_original !== no_arara && (
              <Grid item xs={12} className={classes.historyCardText}>
                Arara: {no_arara}
              </Grid>
            )}
            {no_tipo_farm && no_tipo_farm_original !== no_tipo_farm && (
              <Grid item xs={12} className={classes.historyCardText}>
                Tipo Farm: {no_tipo_farm}
              </Grid>
            )}
            {isUserFromFabula() &&
              no_segmento &&
              no_segmento_original !== no_segmento && (
                <Grid item xs={12} className={classes.historyCardText}>
                  Segmento: {no_segmento}
                </Grid>
              )}
          </Grid>
        </Menu>
      );
    },
    [
      anchorElHistory,
      classes.historyCardText,
      props.filterData,
      props.product,
      isUserFromFabula,
      isUserFromFarmBR
    ]
  );

  const productTools = useCallback(
    (product) => {
      const handleClickOpen = () => {
        setOpen2(true);
      };

      const handleClose = () => {
        setOpen2(false);
      };

      if (isDuplicatedProduct(product)) {
        return (
          <>
            <img
              alt="delete-icon"
              src={deleteForeverOutlinedIcon}
              style={{
                color: '#333333',
                cursor: 'pointer',
                width: props.zoom * 20,
                height: props.zoom * 20
              }}
              onClick={handleClickOpen}
            />
            <button
              style={{
                minWidth: 30,
                width: props.zoom * 100,
                height: props.zoom * 25,
                borderRadius: 100,
                fontFamily: 'Flama',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: props.zoom * 12,
                border: '1px solid rgba(24, 24, 24, 0.1)',
                color: 'black',
                backgroundColor: 'white'
              }}
              onMouseOver={(e) => {
                setAnchorElHistory(e.currentTarget);
              }}
            >
              Duplicado
            </button>
            {renderDiffProperties(product)}
            <Dialog open={open2} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                Excluir Produto Duplicado?
              </DialogTitle>
              <DialogActions>
                <Button
                  disabled={awaytingRequest}
                  onClick={handleClose}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  disabled={awaytingRequest}
                  onClick={() => deleteProductFunction(product)}
                  color="primary"
                >
                  Sim!
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
      if (isMovedProduct(product)) {
        return (
          <>
            <img
              alt="undo-icon"
              src={undoIcon}
              style={{
                color: '#333333',
                cursor: 'pointer',
                width: props.zoom * 20,
                height: props.zoom * 20,
                opacity: vote === 'Reprovado' && 0.3
              }}
              onClick={handleClickOpen}
            />
            <button
              style={{
                minWidth: 30,
                width: props.zoom * 100,
                height: props.zoom * 25,
                borderRadius: 100,
                fontFamily: 'Flama',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: props.zoom * 12,
                border: '1px solid rgba(24, 24, 24, 0.1)',
                color: 'black',
                backgroundColor: 'white'
              }}
              onMouseOver={(e) => {
                setAnchorElHistory(e.currentTarget);
              }}
            >
              Movimentado
            </button>
            {renderDiffProperties(product)}
            <Dialog open={open2} onClose={handleClose}>
              <DialogTitle>Desfazer Produto Movimentado?</DialogTitle>
              <DialogActions>
                <Button
                  disabled={awaytingRequest}
                  onClick={handleClose}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  disabled={awaytingRequest}
                  onClick={() => deleteProductFunction(product)}
                  color="primary"
                >
                  Sim!
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
      if (isCreatedProduct(product)) {
        return (
          <>
            <img
              alt="delete-icon"
              src={deleteForeverOutlinedIcon}
              style={{
                color: '#333333',
                cursor: 'pointer',
                width: props.zoom * 20,
                height: props.zoom * 20,
                opacity: vote === 'Reprovado' && 0.3
              }}
              onClick={() => handleClickOpen()}
            />
            <Button
              variant="outlined"
              disabled
              style={{
                minWidth: 30,
                width: props.zoom * 100,
                height: props.zoom * 25,
                borderRadius: 100,
                fontFamily: 'Flama',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: props.zoom * 9,
                border: '1px solid rgba(24, 24, 24, 0.1)',
                lineHeight: props.zoom * 15,
                color: 'black'
              }}
            >
              Criado
            </Button>
            <Dialog open={open2} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                Excluir Produto Criado?
              </DialogTitle>
              <DialogActions>
                <Button
                  disabled={awaytingRequest}
                  onClick={handleClose}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  disabled={awaytingRequest}
                  onClick={() => deleteProductFunction(product)}
                  color="primary"
                >
                  Sim!
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
    },
    [
      awaytingRequest,
      deleteProductFunction,
      renderDiffProperties,
      open2,
      props.zoom,
      vote
    ]
  );

  const renderMaterials = () => {
    return (
      <Grid container alignItems="center" style={{ marginTop: props.zoom * 8 }}>
        <Typography
          className={classes.infoLabel}
          style={{ fontSize: props.zoom * 12 }}
        >
          MP:
        </Typography>
        <Tooltip
          title={props.product.material.split('-;-').map((material) => (
            <h3 className={classes.cardText}>{material}</h3>
          ))}
        >
          <InfoOutlinedIcon
            style={{
              fill: 'white',
              marginLeft: 5,
              width: props.zoom * 16,
              height: props.zoom * 16
            }}
          />
        </Tooltip>
      </Grid>
    );
  };

  const searchTool = (option, label, id, type) => {
    const name = label;

    function saveOption(opt) {
      if (
        !duplicateProduct[id].find((filter) => `${filter}` === `${opt[id]}`)
      ) {
        setDuplicateProduct({
          ...duplicateProduct,
          [id]: [...duplicateProduct[id], opt[id]],
          [label]: [...duplicateProduct[label], opt[label]]
        });
      } else {
        setDuplicateProduct({
          ...duplicateProduct,
          [id]: duplicateProduct[id].filter((f) => `${f}` !== `${opt[id]}`),
          [label]: duplicateProduct[label].filter(
            (f) => `${f}` !== `${opt[label]}`
          )
        });
      }
    }

    if (type === 'duplicate') {
      return (
        <Autocomplete
          style={{ marginTop: 0 }}
          filterOptions={filterOptions}
          size="small"
          disableClearable
          options={option}
          getOptionLabel={(options) => options[label]}
          renderInput={(opt) => (
            <TextField
              {...opt}
              style={{ width: 140, marginRight: 160, fontFamily: 'Flama' }}
              label={'Buscar'}
            />
          )}
          onChange={(e, v) => {
            label !== 'desc_estampa_cor' && label !== 'no_estampa_farm'
              ? setDuplicateProduct({
                  ...duplicateProduct,
                  [id]: v[id],
                  [name]: v[name]
                })
              : saveOption(v);
          }}
        />
      );
    }
    if (type === 'manipulate') {
      return (
        <Autocomplete
          style={{ marginTop: 0 }}
          filterOptions={filterOptions}
          size="small"
          disableClearable
          options={option}
          getOptionLabel={(options) => options[label]}
          renderInput={(opt) => (
            <TextField
              {...opt}
              style={{ width: 140, marginRight: 160, fontFamily: 'Flama' }}
              label={'Buscar'}
            />
          )}
          onChange={(e, v) => {
            label === 'desc_estampa_cor'
              ? setManipulateProduct({
                  ...manipulateProduct,
                  [id]: v[id],
                  no_estampa_farm: v[name]
                }) //eslint-disable-line
              : setManipulateProduct({
                  ...manipulateProduct,
                  [id]: v[id],
                  [name]: v[name]
                });
          }}
        />
      );
    }
  };
  return (
    <>
      <Card
        variant="outlined"
        style={{
          width: props.zoom * 258 - 4,
          height: props.zoom * (props.visibility ? 564 : 392) - 6,
          borderRadius: 0
        }}
      >
        <Grid
          container
          justify="center"
          style={{
            width: props.zoom * cardSize.width,
            height: props.zoom * cardSize.heigth,
            position: 'relative'
          }}
        >
          {vote === 'Reprovado' && (
            <Grid
              item
              onClick={() => setOpenPDP(true)}
              style={{
                backgroundColor: 'rgba(255, 0, 85, 0.15)',
                width: props.zoom * cardSize.width,
                height: props.zoom * 390,
                maxHeight: props.zoom * 390,
                overflowY: 'auto',
                overflowX: 'hidden',
                zIndex: 99,
                position: 'absolute'
              }}
            ></Grid>
          )}
          {info && (
            <Grid
              item
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                width: props.zoom * cardSize.width,
                height: props.zoom * 390,
                maxHeight: props.zoom * 390,
                overflowY: 'auto',
                overflowX: 'hidden',
                zIndex: 99,
                position: 'absolute'
              }}
            >
              <CardContent
                style={{ margin: props.zoom * 2, padding: props.zoom * 5 }}
              >
                <Grid item xs={12}>
                  <Typography
                    className={classes.infoLabel}
                    style={{ fontSize: props.zoom * 12 }}
                  >
                    LINHA: {props.product.linha}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: props.zoom * 8 }}>
                  <Typography
                    className={classes.infoLabel}
                    style={{ fontSize: props.zoom * 12 }}
                  >
                    ESTILISTA: {props.product.estilista}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: props.zoom * 8 }}>
                  <Typography
                    className={classes.infoLabel}
                    style={{ fontSize: props.zoom * 12 }}
                  >
                    FORNECEDOR: {props.product.fornecedor}
                  </Typography>
                </Grid>

                {props.product.no_arara && isUserFromAnimaleBR() && (
                  <Grid item xs={12} style={{ marginTop: props.zoom * 8 }}>
                    <Typography
                      className={classes.infoLabel}
                      style={{ fontSize: props.zoom * 12 }}
                    >
                      ARARA: {props.product.no_arara}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {renderMaterials()}
                </Grid>
                {!isUserFromFabula() &&
                  (!createClassification ? (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            {isUserFromFarmBR()
                              ? 'ATRIBUTO:'
                              : 'CLASSIFICAÇÃO:'}
                          </Typography>
                          <Typography
                            className={classes.infoLabel}
                            style={{
                              fontSize: props.zoom * 12,
                              marginTop: props.zoom * 5
                            }}
                          >
                            {props.classificationOpts
                              ? classification.id_classificacao.length === 1 &&
                                classification.id_classificacao[0] === 0
                                ? isUserFromFarmBR()
                                  ? 'Nenhum'
                                  : 'Nenhuma'
                                : getStringClassification(
                                    classification,
                                    props.classificationOpts
                                  )
                              : isUserFromFarmBR()
                              ? 'Nenhum'
                              : 'Nenhuma'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip
                            title={
                              isUserFromFarmBR() ? (
                                <Typography
                                  className={classes.infoLabel}
                                  style={{ fontSize: props.zoom * 12 }}
                                >
                                  Editar Atributo
                                </Typography>
                              ) : (
                                <Typography
                                  className={classes.infoLabel}
                                  style={{ fontSize: props.zoom * 12 }}
                                >
                                  Editar Classificação
                                </Typography>
                              )
                            }
                          >
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() =>
                                setCreateClassification(!createClassification)
                              }
                            >
                              <CreateIcon
                                style={{
                                  color: 'white',
                                  width: props.zoom * 14,
                                  height: props.zoom * 14
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          {props.product.classificacao !== 0 ? (
                            <Typography
                              className={classes.infoLabel}
                              style={{ fontSize: props.zoom * 12 }}
                            >
                              {isUserFromFarmBR()
                                ? 'ATRIBUTO:'
                                : 'CLASSIFICAÇÃO:'}
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color: 'white',
                                fontSize: props.zoom * 14,
                                fontFamily: 'Flama'
                              }}
                              className={classes.cardText}
                            >
                              {isUserFromFarmBR()
                                ? 'Novo Atributo'
                                : 'Nova Classificação'}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip
                            title={
                              isUserFromFarmBR()
                                ? 'Editar Atributo'
                                : 'Editar Classificação'
                            }
                          >
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                backgroundColor: 'white',
                                marginLeft: props.zoom * 10
                              }}
                              onClick={registerClassification}
                            >
                              <CheckIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'black'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      {isUserFromFarmBR() || isUserFromMariaFilo() ? (
                        <div
                          style={{
                            backgroundColor: '#fff',
                            marginTop: props.zoom * 3
                          }}
                        >
                          {props.classificationOpts &&
                            props.classificationOpts.map((opt) => (
                              <ListItem
                                button
                                key={opt.no_classificacao}
                                style={{ height: props.zoom * 30 }}
                                onClick={() =>
                                  handleClassificationOptions(
                                    opt.id_classificacao
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={Object.values(
                                      classification.id_classificacao
                                    ).includes(opt.id_classificacao)}
                                    color="primary"
                                    size="small"
                                  />
                                </ListItemIcon>
                                <ListItemText primary={opt.no_classificacao} />
                              </ListItem>
                            ))}
                        </div>
                      ) : (
                        <Select
                          style={{
                            width: props.zoom * 130,
                            marginLeft: props.zoom * 10,
                            backgroundColor: 'white'
                          }}
                          value={classification.no_classificacao}
                          label={classification.no_classificacao}
                          onChange={handleClassificationOpt}
                        >
                          {props.classificationOpts &&
                            props.classificationOpts.map((opt) => (
                              <MenuItem value={opt}>
                                <Typography className={classes.cardText}>
                                  {opt.no_classificacao}
                                </Typography>
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    </>
                  ))}
                <Grid item xs={12}>
                  {!createPreco ? (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            PREÇO PERCEBIDO (ESTILO):
                          </Typography>
                          <Typography
                            className={classes.infoLabel}
                            style={{
                              fontSize: props.zoom * 12,
                              marginTop: props.zoom * 5
                            }}
                          >
                            {!preco ? 'Nenhum Preço' : `R$${preco}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Preço Percebido">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() => setCreatePreco(!createPreco)}
                            >
                              <CreateIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'white'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container style={{ flexDirection: 'row' }}>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            PREÇO PERCEBIDO (ESTILO):
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Valor Percebido">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                backgroundColor: 'white',
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() => registerPreco('Preco')}
                            >
                              <CheckIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'black'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <input
                          id="comment"
                          value={preco}
                          type="number"
                          style={{
                            height: props.zoom * 20,
                            width: props.zoom * 239,
                            marginTop: props.zoom * 3,
                            marginLeft: props.zoom * -3
                          }}
                          maxLength={144}
                          cols="40"
                          min="0.00"
                          max="100000.00"
                          step="0.01"
                          onChange={(e) => setPreco(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}

                  {!createPrecoAlvo ? (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            PREÇO ALVO (PLANEJAMENTO):
                          </Typography>
                          <Typography
                            className={classes.infoLabel}
                            style={{
                              fontSize: props.zoom * 12,
                              marginTop: props.zoom * 5
                            }}
                          >
                            {!precoAlvo ? 'Nenhum Preço' : `R$${precoAlvo}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Preço Percebido">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() =>
                                setCreatePrecoAlvo(!createPrecoAlvo)
                              }
                            >
                              <CreateIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'white'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container style={{ flexDirection: 'row' }}>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            PREÇO ALVO (PLANEJAMENTO):
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Valor Percebido">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                backgroundColor: 'white',
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() => registerPreco('PrecoAlvo')}
                            >
                              <CheckIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'black'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <input
                          id="comment"
                          value={precoAlvo}
                          type="number"
                          style={{
                            height: props.zoom * 20,
                            width: props.zoom * 239,
                            marginTop: props.zoom * 3,
                            marginLeft: props.zoom * -3
                          }}
                          maxLength={144}
                          cols="40"
                          min="0.00"
                          max="100000.00"
                          step="0.01"
                          onChange={(e) => setPrecoAlvo(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}

                  {!createComment ? (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            COMENTÁRIO:
                          </Typography>
                          <Typography
                            className={classes.infoLabel}
                            style={{
                              fontSize: props.zoom * 12,
                              marginTop: props.zoom * 5
                            }}
                          >
                            {comment ? comment : 'Nenhum Comentário'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Comentário">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() => setCreateComment(!createComment)}
                            >
                              <CreateIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'white'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container style={{ flexDirection: 'row' }}>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            COMENTÁRIO: {comment?.length} / 144{' '}
                            {/*{comment && comment.length ? comment.length : 0}/144 */}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Comentário">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                backgroundColor: 'white',
                                marginLeft: props.zoom * 10
                              }}
                              onClick={registerComentary}
                            >
                              <CheckIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'black'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <textarea
                          id="comment"
                          type="text"
                          style={{
                            height: props.zoom * 150,
                            width: props.zoom * 239,
                            marginTop: props.zoom * 3,
                            marginLeft: props.zoom * -3
                          }}
                          maxLength={144}
                          rows="6"
                          cols="40"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                      </Grid>
                    </>
                  )}
                  {!createObs ? (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container style={{ flexDirection: 'row' }}>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            OBSERVAÇÃO:
                          </Typography>
                          <Typography
                            className={classes.infoLabel}
                            style={{
                              fontSize: props.zoom * 12,
                              marginTop: props.zoom * 5
                            }}
                          >
                            {obs ? obs : 'Nenhuma Observação'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Observação">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                marginLeft: props.zoom * 10
                              }}
                              onClick={() => setCreateObs(!createObs)}
                            >
                              <CreateIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'white'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Divider
                        style={{
                          margin: `${props.zoom * 8}px ${props.zoom * 1}px`,
                          marginLeft: props.zoom * -1,
                          width: props.zoom * 240,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          opacity: 0.2
                        }}
                      />
                      <Grid container style={{ flexDirection: 'row' }}>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.infoLabel}
                            style={{ fontSize: props.zoom * 12 }}
                          >
                            OBSERVAÇÃO: {obs ? obs.length : 0}/144
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Editar Observação">
                            <IconButton
                              style={{
                                width: props.zoom * 22,
                                height: props.zoom * 22,
                                backgroundColor: 'white',
                                marginLeft: props.zoom * 10
                              }}
                              onClick={registerObs}
                            >
                              <CheckIcon
                                style={{
                                  width: props.zoom * 14,
                                  height: props.zoom * 14,
                                  color: 'black'
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <textarea
                          id="obs"
                          type="text"
                          style={{
                            height: props.zoom * 150,
                            width: props.zoom * 239,
                            marginTop: props.zoom * 3,
                            marginLeft: props.zoom * -3
                          }}
                          maxLength={144}
                          rows="6"
                          cols="40"
                          value={obs}
                          onChange={(e) => setObs(e.target.value)}
                        ></textarea>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Grid>
          )}
          <SwipeableCarrousel
            zoom={props.zoom}
            id={props.product.id_produto_estilo}
            className={classes.media}
            cardSize={cardSize}
            redirect={false}
            setOpen={setOpenPDP}
            photos={
              props.product.imagem || props.product.nome_arquivo_principal
                ? [
                    ...handleImage(
                      props.product.imagem,
                      props.product.nome_arquivo_principal,
                      260
                    ).slice(0, 20)
                  ]
                : [noImage]
            }
            stepper
          />
          <ProductPanel
            open={openPDP}
            setOpen={setOpenPDP}
            product={props.product}
            grouping={props.grouping}
            vote={vote}
            voteId={voteId}
            voteOpts={voteOpts}
            saveVote={saveVote}
            obs={obs}
            preco={preco}
            precoAlvo={precoAlvo}
            comment={comment}
            classification={classification}
            classificationOpts={props.classificationOpts}
          />
        </Grid>
        {props.visibility && (
          <CardContent
            style={{
              paddingLeft: props.zoom * 10,
              paddingRight: props.zoom * 10,
              paddingTop: props.zoom * 10
            }}
          >
            <CardActions style={{ padding: 0 }}>
              {voteOpts.length === 1 ? (
                vote === 'Aprovado' ? (
                  <FavoriteIcon
                    key={`${props.product.id_produto_estilo}-aprovado-${voteOpts[0].id_voto}`}
                    style={{
                      fill: '#FF3A3A',
                      cursor: 'pointer',
                      width: props.zoom * 20,
                      height: props.zoom * 20
                    }}
                    onClick={() =>
                      saveVote(
                        props.product,
                        voteOpts[0].no_voto,
                        voteOpts[0].id_voto,
                        voteOpts[0].cd_cor_voto,
                        voteOpts[0]
                      )
                    }
                  />
                ) : (
                  <FavoriteBorderIcon
                    key={`${props.product.id_produto_estilo}-${props.product.id_estampa_cor}-${voteOpts[0].id_voto}`}
                    value={voteOpts[0].id_voto}
                    style={{
                      color: '#333333',
                      cursor: 'pointer',
                      width: props.zoom * 20,
                      height: props.zoom * 20,
                      opacity: vote === 'Reprovado' && 0.3
                    }}
                    onClick={() =>
                      saveVote(
                        props.product,
                        voteOpts[0].no_voto,
                        voteOpts[0].id_voto,
                        voteOpts[0].cd_cor_voto,
                        voteOpts[0]
                      )
                    }
                  />
                )
              ) : vote ? (
                vote === 'Reprovado' ? (
                  <FavoriteBorderIcon
                    style={{
                      color: '#333333',
                      cursor: 'pointer',
                      width: props.zoom * 20,
                      height: props.zoom * 20,
                      opacity: vote === 'Reprovado' && 0.3
                    }}
                    onClick={handleClick}
                  />
                ) : (
                  voteOpts.map(
                    (voteOpt) =>
                      vote === voteOpt.no_voto && (
                        <FavoriteIcon
                          key={`favIcon-${props.product.id_produto_estilo}-${voteOpt.no_voto}`}
                          style={{
                            color: `${voteOpt.cd_cor_voto}`,
                            cursor: 'pointer',
                            width: props.zoom * 20,
                            height: props.zoom * 20
                          }}
                          onClick={handleClick}
                        />
                      )
                  )
                )
              ) : (
                <FavoriteBorderIcon
                  style={{
                    color: '#333333',
                    cursor: 'pointer',
                    width: props.zoom * 20,
                    height: props.zoom * 20,
                    opacity: vote === 'Reprovado' && 0.3
                  }}
                  onClick={handleClick}
                />
              )}
              {vote === 'Reprovado'
                ? voteOpts.map(
                    (voteOpt) =>
                      voteOpt.in_escala_positiva === 0 && (
                        <HighlightOffTwoToneIcon
                          style={{
                            color: '#FF3A3A',
                            cursor: 'pointer',
                            width: props.zoom * 20,
                            height: props.zoom * 20
                          }}
                          onClick={() =>
                            saveVote(
                              props.product,
                              voteOpt.no_voto,
                              voteOpt.id_voto,
                              voteOpt.cd_cor_voto
                            )
                          }
                          key={`${props.product.id_produto_estilo}-${props.product.id_estampa_cor}-HighlightOffTwoToneIcon-${voteOpt.id_voto}`}
                        />
                      )
                  )
                : voteOpts.map(
                    (voteOpt) =>
                      voteOpt.in_escala_positiva === 0 && (
                        <HighlightOffIcon
                          key={`${props.product.id_produto_estilo}-${props.product.id_estampa_cor}-highlightOff-${voteOpt.id_voto}`}
                          style={{
                            color: '#333333',
                            cursor: 'pointer',
                            width: props.zoom * 20,
                            height: props.zoom * 20
                          }}
                          onClick={() =>
                            saveVote(
                              props.product,
                              voteOpt.no_voto,
                              voteOpt.id_voto,
                              voteOpt.cd_cor_voto
                            )
                          }
                        />
                      )
                  )}
              {renderVotingOpts()}
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                style={{
                  minWidth: props.zoom * 20,
                  minHeight: props.zoom * 20
                }}
                color="secondary"
                variant="dot"
                invisible={
                  (preco === null || preco.length === 0) &&
                  (precoAlvo === null || precoAlvo.length === 0) &&
                  (comment === null || comment.length === 0) &&
                  (obs === null || obs.length === 0) &&
                  classification.id_classificacao.length === 1 &&
                  classification.id_classificacao[0] === 0
                }
              >
                <InfoOutlinedIcon
                  onClick={() => setInfo(!info)}
                  style={{
                    color: '#333333',
                    cursor: 'pointer',
                    width: props.zoom * 20,
                    height: props.zoom * 20,
                    opacity: vote === 'Reprovado' && 0.3
                  }}
                />
              </Badge>
              {!props.product.id_produto_duplicado ? (
                <>
                  <img
                    alt="share-icon"
                    src={shareIcon}
                    onClick={handleClickOpen}
                    style={{
                      color: '#333333',
                      cursor: 'pointer',
                      width: props.zoom * 20,
                      height: props.zoom * 20,
                      opacity: vote === 'Reprovado' && 0.3
                    }}
                  />
                </>
              ) : (
                productTools(props.product)
              )}

              <Dialog
                maxWidth="md"
                open={open}
                fullWidth
                onClose={handleClose2}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1.5px solid rgb(0, 0, 0, 0.15)',
                    padding: 12
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                      <Typography
                        className={classes.cardText}
                        style={{
                          fontSize: 15,
                          textAlign: 'center',
                          fontFamily: 'Flama'
                        }}
                      >
                        {props.product.produto} - {props.product.ds_produto}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ paddingLeft: 10 }}>
                      <Button
                        style={{ border: '1px solid #000000', height: 36 }}
                        variant="outlined"
                        onClick={
                          isDuplication
                            ? () => {
                                setCheckedDuplicateProduct(false);
                                setDuplicateProduct({
                                  id_produto_estilo:
                                    props.product.id_produto_estilo,
                                  ds_produto: props.product.ds_produto,
                                  estampa_cor: props.product.estampa_cor,
                                  id_estampa_cor: [],
                                  desc_estampa_cor: [],
                                  no_estampa_farm: [],
                                  no_subgrupo: props.product.no_subgrupo,
                                  id_grupo_produto:
                                    props.product.id_grupo_produto,
                                  grupo_produto: props.product.grupo_produto,
                                  id_linha: props.product.id_linha,
                                  no_arara: props.product.no_arara,
                                  no_tipo_farm: props.product.no_tipo_farm,
                                  no_segmento: props.product.no_segmento,
                                  preco: props.product.preco,
                                  linha: props.product.linha
                                });
                              }
                            : () => {
                                setCheckedManipulateProduct(false);
                                setManipulateProduct({
                                  id_produto_estilo:
                                    props.product.id_produto_estilo,
                                  ds_produto: props.product.ds_produto,
                                  estampa_cor: props.product.estampa_cor,
                                  id_estampa_cor: props.product.id_estampa_cor,
                                  no_estampa_farm:
                                    props.grouping === 'Linhas' ||
                                    props.grouping === 'Personalizado'
                                      ? props.product.estampa
                                      : props.product.coordenado,
                                  no_subgrupo: props.product.no_subgrupo,
                                  id_grupo_produto:
                                    props.product.id_grupo_produto,
                                  grupo_produto: props.product.grupo_produto,
                                  id_linha: props.product.id_linha,
                                  no_arara: props.product.no_arara,
                                  no_tipo_farm: props.product.no_tipo_farm,
                                  no_segmento: props.product.no_segmento,
                                  preco: props.product.preco,
                                  linha: props.product.linha
                                });
                              }
                        }
                      >
                        <Typography
                          className={classes.cardText}
                          style={{
                            fontSize: 14,
                            textAlign: 'center',
                            fontFamily: 'Flama'
                          }}
                        >
                          Limpar
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: 30 }}>
                      <Button
                        disabled={
                          isDuplication
                            ? !checkedDuplicateProduct
                            : !checkedManipulateProduct
                        }
                        variant="contained"
                        style={{
                          backgroundColor:
                            checkedDuplicateProduct || checkedManipulateProduct
                              ? 'black'
                              : 'grey',
                          color: 'white',
                          height: 36
                        }}
                        onClick={
                          isDuplication
                            ? () => duplicateProductFunction()
                            : () => moveProductFunction()
                        }
                      >
                        {isDuplication ? 'Duplicar' : 'Movimentar'}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <Grid container direction="row" justify="center">
                  <Grid item xs={4}>
                    <SwipeableCarrousel
                      cardSize={cardSize2}
                      redirect={false}
                      photos={
                        props.product.imagem ||
                        props.product.nome_arquivo_principal
                          ? [
                              ...handleImage(
                                props.product.imagem,
                                props.product.nome_arquivo_principal,
                                320
                              ).slice(0, 20)
                            ]
                          : [noImage]
                      }
                      stepper
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={onSelectMove}
                      >
                        <Tab
                          style={{
                            backgroundColor: isDuplication
                              ? 'rgba(0, 0, 0, 0.05)'
                              : 'white'
                          }}
                          label={
                            <Typography
                              className={classes.cardText}
                              style={{
                                fontSize: 13,
                                textAlign: 'center',
                                fontFamily: 'Flama medium'
                              }}
                            >
                              DUPLICAR
                            </Typography>
                          }
                        />
                        <Tab
                          style={{
                            backgroundColor: !isDuplication
                              ? 'rgba(0, 0, 0, 0.05)'
                              : 'white'
                          }}
                          label={
                            <Typography
                              className={classes.cardText}
                              style={{
                                fontSize: 13,
                                textAlign: 'center',
                                fontFamily: 'Flama medium'
                              }}
                            >
                              MOVIMENTAR
                            </Typography>
                          }
                        />
                      </Tabs>
                    </Grid>
                    <DialogContentText
                      style={{ minWidth: 300, maxHeight: 300 }}
                    >
                      {isDuplication ? (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={6} style={{ paddingLeft: 10 }}>
                              <Select
                                style={{ minWidth: 140, marginTop: 13 }}
                                value={duplicateOpt}
                                onChange={handleDuplicateOpt}
                              >
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  disabled={
                                    props.grouping === 'Coordenados'
                                      ? true
                                      : false
                                  }
                                  value="Cor"
                                >
                                  {isUserFromFarmBR() ? 'Coordenado' : 'Cor'}
                                </MenuItem>
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'Grupo'}
                                >
                                  Grupo
                                </MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: 10 }}>
                              {duplicateOpt === 'Grupo' &&
                                props.groups.length > 0 &&
                                searchTool(
                                  props.groups,
                                  'grupo_produto',
                                  'id_grupo_produto',
                                  'duplicate'
                                )}
                              {duplicateOpt === 'Cor' &&
                                (isUserFromFarmBR()
                                  ? props.coordinates.length > 0
                                  : props.colors.length > 0) &&
                                searchTool(
                                  isUserFromFarmBR()
                                    ? props.coordinates
                                    : props.colors,
                                  isUserFromFarmBR()
                                    ? 'no_estampa_farm'
                                    : 'desc_estampa_cor',
                                  isUserFromFarmBR()
                                    ? 'no_estampa_farm'
                                    : 'id_estampa_cor',
                                  'duplicate'
                                )}
                            </Grid>
                          </Grid>

                          {duplicateOpt === 'Grupo' &&
                          props.groups.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                groupsOpts,
                                'grupo_produto',
                                'id_grupo_produto',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            duplicateOpt === 'Grupo' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {duplicateOpt === 'Cor' &&
                          (isUserFromFarmBR()
                            ? props.coordinates.length > 0
                            : props.colors.length > 0) ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {isUserFromFarmBR()
                                ? renderManipulateButtons(
                                    props.coordinates,
                                    'no_estampa_farm',
                                    'no_estampa_farm',
                                    isDuplication
                                      ? duplicateProduct
                                      : manipulateProduct,
                                    isDuplication
                                      ? setDuplicateProduct
                                      : setManipulateProduct,
                                    'checkbox'
                                  )
                                : renderManipulateButtons(
                                    props.colors,
                                    'desc_estampa_cor',
                                    'id_estampa_cor',
                                    isDuplication
                                      ? duplicateProduct
                                      : manipulateProduct,
                                    isDuplication
                                      ? setDuplicateProduct
                                      : setManipulateProduct,
                                    'checkbox'
                                  )}
                            </Grid>
                          ) : (
                            duplicateOpt === 'Cor' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={6} style={{ paddingLeft: 10 }}>
                              <Select
                                style={{ minWidth: 140, marginTop: 13 }}
                                value={manipulateOpt}
                                onChange={handleManipulateOpt}
                              >
                                {isUserFromFarmBR() ? (
                                  <MenuItem
                                    style={{ minWidth: 140 }}
                                    value={'Coordenado'}
                                  >
                                    Coordenado
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    style={{ minWidth: 140 }}
                                    value={'Cor'}
                                  >
                                    Cor
                                  </MenuItem>
                                )}
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'Grupo'}
                                >
                                  Grupo
                                </MenuItem>
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'SubGrupo'}
                                >
                                  SubGrupo
                                </MenuItem>
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'Linha'}
                                >
                                  Linha
                                </MenuItem>
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'Arara'}
                                >
                                  Arara
                                </MenuItem>
                                {isUserFromFabula() && (
                                  <MenuItem
                                    style={{ minWidth: 140 }}
                                    value={'Segmento'}
                                  >
                                    Segmento
                                  </MenuItem>
                                )}
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'Tipo Farm'}
                                >
                                  Tipo Farm
                                </MenuItem>
                                <MenuItem
                                  style={{ minWidth: 140 }}
                                  value={'Preço'}
                                >
                                  Preço
                                </MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: 10 }}>
                              {manipulateOpt === 'Grupo' &&
                                groupsOpts.length > 0 &&
                                searchTool(
                                  groupsOpts,
                                  'grupo_produto',
                                  'id_grupo_produto',
                                  'manipulate'
                                )}
                              {(manipulateOpt === 'Cor' ||
                                manipulateOpt === 'Coordenado') &&
                                (isUserFromFarmBR()
                                  ? coordinatesOpts.length > 0
                                  : colorsOpts.length > 0) &&
                                searchTool(
                                  isUserFromFarmBR()
                                    ? coordinatesOpts
                                    : colorsOpts,
                                  isUserFromFarmBR()
                                    ? 'no_estampa_farm'
                                    : 'desc_estampa_cor',
                                  isUserFromFarmBR()
                                    ? 'no_estampa_farm'
                                    : 'id_estampa_cor',
                                  'manipulate'
                                )}
                              {manipulateOpt === 'SubGrupo' &&
                                subGroupsManipulateOpts.length > 0 &&
                                searchTool(
                                  subGroupsManipulateOpts,
                                  'no_subgrupo',
                                  'no_subgrupo',
                                  'manipulate'
                                )}
                              {manipulateOpt === 'Linha' &&
                                linesManipulateOpts.length > 0 &&
                                searchTool(
                                  linesManipulateOpts,
                                  'linha',
                                  'id_linha',
                                  'manipulate'
                                )}
                              {manipulateOpt === 'Arara' &&
                                racksManipulateOpts.length > 0 &&
                                searchTool(
                                  racksManipulateOpts,
                                  'no_arara',
                                  'no_arara',
                                  'manipulate'
                                )}
                              {manipulateOpt === 'Tipo Farm' &&
                                farmTypesManipulateOpts.length > 0 &&
                                searchTool(
                                  farmTypesManipulateOpts,
                                  'no_tipo_farm',
                                  'no_tipo_farm',
                                  'manipulate'
                                )}
                              {isUserFromFabula() &&
                                manipulateOpt === 'Segmento' &&
                                segmentsManipulateOpts.length > 0 &&
                                searchTool(
                                  segmentsManipulateOpts,
                                  'no_segmento',
                                  'no_segmento',
                                  'manipulate'
                                )}
                            </Grid>
                          </Grid>

                          {groupsOpts.length > 0 &&
                          manipulateOpt === 'Grupo' ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                groupsOpts,
                                'grupo_produto',
                                'id_grupo_produto',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Grupo' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'SubGrupo' &&
                          subGroupsManipulateOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                subGroupsManipulateOpts,
                                'no_subgrupo',
                                'no_subgrupo',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'SubGrupo' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'Linha' &&
                          linesManipulateOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                linesManipulateOpts,
                                'linha',
                                'id_linha',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Linha' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'Arara' &&
                          racksManipulateOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                racksManipulateOpts,
                                'no_arara',
                                'no_arara',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Arara' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'Tipo Farm' &&
                          farmTypesManipulateOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                farmTypesManipulateOpts,
                                'no_tipo_farm',
                                'no_tipo_farm',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Tipo Farm' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'Segmento' &&
                          segmentsManipulateOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                segmentsManipulateOpts,
                                'no_segmento',
                                'no_segmento',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Segmento' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'Preço' && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                maxHeight: 330,
                                overflowY: 'hidden',
                                marginTop: 10
                              }}
                            >
                              <CurrencyTextField
                                variant="standard"
                                style={{ marginRight: 5, width: 280 }}
                                currencySymbol="R$"
                                minimumValue="0"
                                decimalPlaces={2}
                                value={manipulateProduct.preco}
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event, value) =>
                                  setManipulateProduct({
                                    ...manipulateProduct,
                                    preco: value
                                  })
                                }
                              />
                            </Grid>
                          )}

                          {manipulateOpt === 'Cor' && colorsOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                colorsOpts,
                                'desc_estampa_cor',
                                'id_estampa_cor',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Cor' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}

                          {manipulateOpt === 'Coordenado' &&
                          coordinatesOpts.length > 0 ? (
                            <Grid
                              item
                              xs={12}
                              style={{ maxHeight: 360, overflowY: 'auto' }}
                            >
                              {renderManipulateButtons(
                                coordinatesOpts,
                                'no_estampa_farm',
                                'no_estampa_farm',
                                isDuplication
                                  ? duplicateProduct
                                  : manipulateProduct,
                                isDuplication
                                  ? setDuplicateProduct
                                  : setManipulateProduct,
                                'radio'
                              )}
                            </Grid>
                          ) : (
                            manipulateOpt === 'Coordenado' && (
                              <Grid
                                container
                                justify="center"
                                alignContent="center"
                                style={{ height: 100, width: 300 }}
                              >
                                <CircularProgress />
                              </Grid>
                            )
                          )}
                        </>
                      )}
                    </DialogContentText>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      flexGrow: 1,
                      backgroundColor: 'white',
                      paddingTop: 16,
                      paddingLeft: 16
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="space-between"
                      alignItems="flex-start"
                      style={{ height: 430 }}
                    >
                      <Grid item>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.modalText}
                            style={{ fontFamily: 'Flama medium', marginTop: 0 }}
                          >
                            ORIGINAL:
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          {props.grouping === 'Coordenados' ? (
                            <Typography className={classes.modalText}>
                              {props.product.estampa_cor} -{' '}
                              {props.product.coordenado}
                            </Typography>
                          ) : (
                            <Typography className={classes.modalText}>
                              {props.product.estampa_cor} -{' '}
                              {props.product.estampa}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography className={classes.modalText}>
                            Grupo: {props.product.grupo_produto}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.modalText}>
                            Linha: {props.product.linha}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.modalText}>
                            Subgrupo: {props.product.no_subgrupo}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.modalText}>
                            Arara: {props.product.no_arara}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.modalText}>
                            Tipo Farm: {props.product.no_tipo_farm}
                          </Typography>
                        </Grid>
                        {isUserFromFabula() && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Segmento: {props.product.no_segmento}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography className={classes.modalText}>
                            Preço: R$
                            {props.product.preco
                              ? props.product.preco.toFixed(2).replace('.', ',')
                              : '0,00'}
                          </Typography>
                        </Grid>

                        {checkedDuplicateProduct && (
                          <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Divider
                              style={{
                                width: 272,
                                backgroundColor: '#000000',
                                opacity: 0.1
                              }}
                            />
                            <Typography
                              className={classes.cardText}
                              style={{
                                fontSize: 13,
                                textAlign: 'Left',
                                fontFamily: 'Flama medium',
                                marginTop: 10
                              }}
                            >
                              DUPLICAÇÕES:
                            </Typography>
                          </Grid>
                        )}

                        {isUserFromFarmBR()
                          ? props.grouping === 'Coordenados'
                            ? duplicateProduct.no_estampa_farm.length > 0 && (
                                <Typography className={classes.modalText}>
                                  {duplicateProduct.estampa_cor} -{' '}
                                  {duplicateProduct.no_estampa_farm}
                                </Typography>
                              )
                            : duplicateProduct.no_estampa_farm.length > 0 && (
                                <Typography className={classes.modalText}>
                                  {duplicateProduct.no_estampa_farm.map(
                                    (opt) => {
                                      return (
                                        <div>
                                          {duplicateProduct.estampa_cor} - {opt}
                                        </div>
                                      );
                                    }
                                  )}
                                </Typography>
                              )
                          : props.grouping === 'Linhas'
                          ? duplicateProduct.desc_estampa_cor.length > 0 && (
                              <Typography className={classes.modalText}>
                                {duplicateProduct.desc_estampa_cor.map(
                                  (opt) => {
                                    return <div>{opt}</div>;
                                  }
                                )}
                              </Typography>
                            )
                          : duplicateProduct.desc_estampa_cor.length > 0 && (
                              <Typography className={classes.modalText}>
                                {duplicateProduct.desc_estampa_cor}
                              </Typography>
                            )}

                        {duplicateProduct.id_grupo_produto !==
                          props.product.id_grupo_produto && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Grupo: {duplicateProduct.grupo_produto}
                            </Typography>
                          </Grid>
                        )}

                        {checkedManipulateProduct && (
                          <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Divider
                              style={{
                                width: 272,
                                backgroundColor: '#000000',
                                opacity: 0.1
                              }}
                            />
                            <Typography
                              className={classes.modalText}
                              style={{
                                fontFamily: 'Flama medium',
                                marginTop: 10
                              }}
                            >
                              MOVIMENTAÇÕES:
                            </Typography>
                          </Grid>
                        )}

                        {isUserFromFarmBR()
                          ? props.grouping === 'Coordenados'
                            ? manipulateProduct.no_estampa_farm !==
                                props.product.coordenado && (
                                <Typography className={classes.modalText}>
                                  {manipulateProduct.estampa_cor} -{' '}
                                  {manipulateProduct.no_estampa_farm}
                                </Typography>
                              )
                            : manipulateProduct.no_estampa_farm !==
                                props.product.estampa &&
                              manipulateProduct.no_estampa_farm && (
                                <Typography className={classes.modalText}>
                                  {manipulateProduct.estampa_cor} -{' '}
                                  {manipulateProduct.no_estampa_farm}
                                </Typography>
                              )
                          : props.grouping === 'Linhas' ||
                            props.grouping === 'Personalizado'
                          ? manipulateProduct.no_estampa_farm !==
                              props.product.estampa && (
                              <Typography className={classes.modalText}>
                                {manipulateProduct.no_estampa_farm}
                              </Typography>
                            )
                          : manipulateProduct.no_estampa_farm !==
                              props.product.coordenado && (
                              <Typography className={classes.modalText}>
                                {manipulateProduct.no_estampa_farm}
                              </Typography>
                            )}

                        {manipulateProduct.id_grupo_produto !==
                          props.product.id_grupo_produto && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Grupo: {manipulateProduct.grupo_produto}
                            </Typography>
                          </Grid>
                        )}

                        {manipulateProduct.no_subgrupo !==
                          props.product.no_subgrupo && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Subgrupo: {manipulateProduct.no_subgrupo}
                            </Typography>
                          </Grid>
                        )}

                        {manipulateProduct.linha !== props.product.linha && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Linha: {manipulateProduct.linha}
                            </Typography>
                          </Grid>
                        )}

                        {manipulateProduct.no_arara !==
                          props.product.no_arara && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Arara: {manipulateProduct.no_arara}
                            </Typography>
                          </Grid>
                        )}
                        {manipulateProduct.no_tipo_farm !==
                          props.product.no_tipo_farm && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Tipo Farm: {manipulateProduct.no_tipo_farm}
                            </Typography>
                          </Grid>
                        )}
                        {isUserFromFabula() &&
                          manipulateProduct.no_segmento !==
                            props.product.no_segmento && (
                            <Grid item xs={12}>
                              <Typography className={classes.modalText}>
                                Segmento: {manipulateProduct.no_segmento}
                              </Typography>
                            </Grid>
                          )}
                        {manipulateProduct.preco !== props.product.preco && (
                          <Grid item xs={12}>
                            <Typography className={classes.modalText}>
                              Preço:{' '}
                              {manipulateProduct.preco
                                .toFixed(2)
                                .replace('.', ',')}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </CardActions>
            <Grid item xs={12} style={{ maxHeight: props.zoom * 95 }}>
              <Typography
                className={classes.cardText}
                style={{
                  textDecorationLine: vote === 'Reprovado' && 'line-through',
                  opacity: vote === 'Reprovado' ? 0.3 : 1,
                  fontSize: props.zoom * 12,
                  marginTop: 4 * props.zoom,
                  lineHeight: `${props.zoom * 14}px`
                }}
              >
                {props.product.produto} - {props.product.ds_produto}
              </Typography>
              {props.grouping === 'Coordenados' ? (
                <Typography
                  className={classes.cardText}
                  style={{
                    textDecorationLine: vote === 'Reprovado' && 'line-through',
                    opacity: vote === 'Reprovado' ? 0.3 : 1,
                    fontSize: props.zoom * 12,
                    marginTop: 4 * props.zoom,
                    lineHeight: `${props.zoom * 14}px`
                  }}
                >
                  {props.product.estampa_cor} - {props.product.coordenado}
                </Typography>
              ) : (
                <Typography
                  className={classes.cardText}
                  style={{
                    textDecorationLine: vote === 'Reprovado' && 'line-through',
                    opacity: vote === 'Reprovado' ? 0.3 : 1,
                    fontSize: props.zoom * 12,
                    marginTop: 4 * props.zoom,
                    lineHeight: `${props.zoom * 14}px`
                  }}
                >
                  {props.product.estampa_cor} - {props.product.estampa}
                </Typography>
              )}
              <Typography
                className={classes.cardText}
                style={{
                  textDecorationLine: vote === 'Reprovado' && 'line-through',
                  opacity: vote === 'Reprovado' ? 0.3 : 1,
                  fontSize: props.zoom * 12,
                  marginTop: 4 * props.zoom,
                  lineHeight: `${props.zoom * 14}px`
                }}
              >
                PV: {formatPrice(props.product.preco)} / CT:{' '}
                {formatPrice(props.product.custo_total)}
                {props.product.custo_total && props.product.preco
                  ? ` / MKP: ${(props.product.preco / props.product.custo_total)
                      .toFixed(2)
                      .replace('.', ',')}`
                  : null}
              </Typography>
              <Typography
                className={classes.cardText}
                style={{
                  textDecorationLine: vote === 'Reprovado' && 'line-through',
                  opacity: vote === 'Reprovado' ? 0.3 : 1,
                  fontSize: props.zoom * 12,
                  marginTop: 4 * props.zoom,
                  lineHeight: `${props.zoom * 14}px`
                }}
              >
                {preco ? ` PP: ${formatPrice(preco)}` : null}
                {props.product.custo_total && preco
                  ? ` / NOVO MKP: ${(preco / props.product.custo_total)
                      .toFixed(2)
                      .replace('.', ',')}`
                  : null}
              </Typography>
              <Typography
                className={classes.cardText}
                style={{
                  textDecorationLine: vote === 'Reprovado' && 'line-through',
                  opacity: vote === 'Reprovado' ? 0.3 : 1,
                  fontSize: props.zoom * 12,
                  marginTop: 4 * props.zoom,
                  lineHeight: `${props.zoom * 14}px`
                }}
              >
                {precoAlvo ? ` PA: ${formatPrice(precoAlvo)}` : null}
              </Typography>
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  );
}
export default withSnackbar(VoteCardProduct);
