import React, { createContext, useContext, useState } from "react";

const VotesContext = createContext();

const useVotes = () => {
  return useContext(VotesContext);
};

const VotesContextProvider = (props) => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [votesTable, setVotesTable] = useState({});
  const [manipulatesTable, setManipulatesTable] = useState({});
  const manipulateOpts = { 1: "Duplicados", 2: "Movimentados", 3: "Criados" };

  const storeVotes = (voteProps, prevVoteProps) => {
    let vote = parseInt(voteProps) ? parseInt(voteProps) : null;
    let prevVote = parseInt(prevVoteProps)  ? parseInt(prevVoteProps) : -1;
    if (vote > 0 && parseInt(prevVote) === -1) {
      votesTable[vote] = votesTable[vote] ? votesTable[vote] + 1 : 1;
      votesTable["Total"] += 1;
      votesTable["Não votados"] -= 1;
      setVotesTable({...votesTable});
    }

    if (vote > 0 && prevVote > 0) {
      votesTable[vote] = votesTable[vote] ? votesTable[vote] + 1 : 1;
      votesTable[prevVote] = votesTable[prevVote] - 1 > 0 ? votesTable[prevVote] - 1 : 0;
      setVotesTable({...votesTable});
    }

    if (parseInt(vote) === -1 && prevVote > 0) {
      votesTable[vote] -= 1;
      votesTable["Não votados"] -= 1;  
      votesTable["Total"] -= 1;
      setVotesTable({...votesTable});
    }
    if (vote === null) {
      votesTable[prevVote] = votesTable[prevVote] - 1 > 0 ? votesTable[prevVote] - 1 : 0;
      votesTable["Não votados"] += 1;
      votesTable["Total"] -= 1;
      setVotesTable({...votesTable});
    }
  };

  return (
    <VotesContext.Provider
      value={{
        totalProducts,
        setTotalProducts,
        votesTable,
        setVotesTable,
        manipulatesTable,
        setManipulatesTable,
        manipulateOpts,
        storeVotes,
      }}
    >
      {props.children}
    </VotesContext.Provider>
  );
};

export { VotesContextProvider };

export default useVotes;
