import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      top: 0
    },
    avatar: {
      padding: theme.spacing(2),
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        width: '90vw',
        height: '90vh'
      },
      [theme.breakpoints.up('md')]: {
        width: '260px',
        height: '90%'
      }
    },
    stepper: {
      backgroundColor: 'white',
      // maxWidth: 260,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 160
      }
    },
    sliderBtn: {
      padding: theme.spacing(0)
    },
    mediaCardMax: {
      height: 360,
      width: 260,
      boxSizing: 'border-box',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        height: 230,
        width: 200,
        left: 0
      },
      borderColor: '#FFE600'
    },
    mediaCardMin: {
      height: 180,
      width: 120,
      boxSizing: 'border-box',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        height: 220,
        width: 120,
        left: 0
      },
      borderColor: '#FFE600'
    },
    aprove: {
      height: 370,
      width: 260,
      boxSizing: 'border-box',
      objectFit: 'cover',
      borderColor: '#FFE600'
    },
    duplicate: {
      height: 430,
      width: 320,
      boxSizing: 'border-box',
      objectFit: 'cover',
      borderColor: '#FFE600'
    },
    dot: {
      opacity: 0.2,
      border: '1px solid #000000',
      boxSizing: 'border-box',
      backgroundColor: 'white'
    },
    dotActive: {
      opacity: 0.2,
      backgroundColor: 'rgba(0, 0, 0, 4)',
      border: '1px solid rgba(0, 0, 0, 4)'
    },
    appBar: {
      position: 'relative'
    },
    toolBar: {
      minHeight: 48,
      backgroundColor: 'white'
    }
  }),
  { index: 1 }
);
