import React from "react";
import { useStyles } from "./style";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";

export default function TotalSwitchCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.cardRoot} >
      <CardContent>
        <Grid container justify="space-between">
          <Typography className={classes.title} gutterBottom>
            {props.title}
          </Typography>
          <Switch
            color="primary"
            onChange={() => props.changeCustomFilter(props.type, 0, 1)}
            checked={!!props.customFilter[props.type].length > 0}
          />
        </Grid>
        <Divider />
      </CardContent>
    </Card>
  )
}