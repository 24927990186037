import { setInterceptor } from './axiosServer';

export class MaisControleServices {
  constructor(authServer) {
    this.authServer = authServer;
    setInterceptor(authServer);
  }

  async getUserPermissionsTokenBySystem(userId) {
    return this.authServer
      .get(`/user/auth/${userId}/sistema/mais-aprovacao`)
      .then((response) => {
        return response.data.content;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getUsersBySomaAuthId(id) {
    try {
      const response = await this.authServer.get(`/user/soma-auth/${id}`);
      return response?.data?.content;
    } catch (error) {
      console.error('Erro ao carregar o usuário.', error);
      throw new Error('Erro ao carregar o usuário.');
    }
  }

  async requestUserAuthorization({ idUsuario, email, name, idSomaAuth }) {
    try {
      const response = await this.authServer.post(
        `/user/auth/${idUsuario}/permissao`,
        {
          name,
          email,
          idSomaAuth,
        }
      );
      return response?.data?.content;
    } catch (error) {
      console.error('Erro ao enviar pedido de permissão.', error);
      throw new Error('Erro ao carregar o usuário.');
    }
  }

  async getLastRequestAuthorization(idUsuario) {
    try {
      const response = await this.authServer.get(
        `/user/auth/${idUsuario}/permissao`
      );
      return response?.data?.content;
    } catch (error) {
      console.error('Erro ao enviar pedido de permissão.', error);
      throw new Error('Erro ao carregar o usuário.');
    }
  }

  async createUser(data) {
    try {
      const response = await this.authServer.post('/user/auth', {
        ...data,
      });
      return response?.data?.content;
    } catch (error) {
      console.error('Error ao cadastrar o usuário.', error);
      throw new Error('Error ao cadastrar o usuário.');
    }
  }

  async createLog(log) {
    const response = await this.authServer.post(`/log`, {
      ...log,
    });
    return response?.data?.content;
  }
}
