/* eslint-disable no-undef */
import axios from 'axios';
import {
  SOMA_AUTH_URL,
  TOKEN_API_BODY,
  SOMA_AUTH_LOGIN_URL,
} from '../consts';
import jwt from 'jwt-decode';
import Cookies from 'universal-cookie';

class AuthGuard {
  constructor() {
    this.initServer();
    this.cookies = new Cookies();
  }

  initServer() {
    this.authServer = axios.create({
      baseURL: SOMA_AUTH_URL,
    });
  }

  logout() {
    this.cookies.remove(this.getCookieTokenNameByAmbient(), {
      path: '/',
      ...this.getDomainByAmbient(),
    });
    localStorage.removeItem('user');
  }

  getCookieTokenNameByAmbient() {
    if (
      process.env.REACT_APP_STAGE === 'prod' ||
      process.env.REACT_APP_STAGE === 'develop'
    ) {
      return 'somaAuthToken';
    }

    return 'somaAuthToken-homolog';
  }

  getDomainByAmbient() {
    if (process.env.REACT_APP_STAGE !== 'develop') {
      return { domain: '.somalabs.com.br' };
    }

    return {};
  }

  setTokenToCookie(tokenBody) {
    this.cookies.set(this.getCookieTokenNameByAmbient(), tokenBody, {
      ...this.getDomainByAmbient(),
      expires: new Date(tokenBody?.exp * 1000),
    });
  }

  setUserToLocalStorage(userBody) {
    localStorage.setItem('user', JSON.stringify(userBody));
  }

  isAuthenticated() {
    const token = this.cookies.get(this.getCookieTokenNameByAmbient());

    if (!token) {
      return false;
    }

    return true;
  }

  isAuthorized() {
    if (this.isAuthenticated()) {
      const userLocal = localStorage.getItem('user');

      if (userLocal !== null) {
        const { roles } = JSON.parse(userLocal);
        if (roles && roles.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return false;
  }

  isRedirectPage() {
    return localStorage.getItem('redirect-page');
  }

  getLocalUser() {
    if (this.isAuthenticated()) {
      const user = localStorage.getItem('user');
      return JSON.parse(user);
    }
  }

  getLocalToken() {
    if (this.isAuthenticated()) {
      return this.cookies.get(this.getCookieTokenNameByAmbient());
    }
  }

  getSomaAuthUser() {
    if (this.isAuthenticated() === true) {
      const token = this.cookies.get(this.getCookieTokenNameByAmbient());

      if (token) {
        const userDecoded = jwt(token.token);
        return userDecoded;
      }
    }
  }

  getRefreshTokenCode() {
    if (this.isAuthenticated()) {
      const token = this.cookies.get(this.getCookieTokenNameByAmbient());
      return token.refresh_token;
    }
  }

  async redirectToLogin() {
    const soma_auth_url = SOMA_AUTH_LOGIN_URL;
    return window.location.replace(soma_auth_url);
  }

  async requestToken(userCode) {
    try {
      const response = await this.authServer.post(`${SOMA_AUTH_URL}/token`, {
        client_id: TOKEN_API_BODY.client_id,
        client_secret: TOKEN_API_BODY.client_secret,
        redirect_uri: TOKEN_API_BODY.redirect_uri,
        grant_type: TOKEN_API_BODY.grant_type,
        code: userCode,
      });

      return response?.data;
    } catch (error) {
      console.error('Erro ao realizar o login', error);
    }
  }

  async requestRefreshToken(refreshTokenCode) {
    try {
      const response = await this.authServer.post(`${SOMA_AUTH_URL}/token`, {
        client_id: TOKEN_API_BODY.client_id,
        client_secret: TOKEN_API_BODY.client_secret,
        grant_type: TOKEN_API_BODY.grant_type_to_refresh_toke,
        refresh_token: refreshTokenCode,
      });

      return response?.data;
    } catch (error) {
      console.error('Erro ao realizar o login', error);
    }
  }
}
export const authGuard = new AuthGuard();