import api from "./api";

export const findPcpPeriod = async (params = {}) => {
  return api()
    .get(`/v0/pcpperiod`, {
      params,
    })
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      throw err;
    });
};
