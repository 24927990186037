import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    productInfoLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '100%',
      fontSize: 12,
      opacity: 0.5
    },
    productInfoHistory: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '150%',
      fontSize: 14,
      margin: '5px 0px'
    },
    productInfo: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '150%',
      fontSize: 12
    },
    list: {
      width: 320,
      padding: '0px 16px',
      color: 'black'
    },
    divider: {
      width: 302,
      opacity: '0.1',
      margin: '12px 0px',
      backgroundColor: 'rgba(0, 0, 0, 1)'
    },
    voteIcon: {
      width: 24,
      height: 24
    },
    MPIcon: {
      marginLeft: 5,
      width: 24,
      height: 24,
      opacity: 0.9
    },
    infoGridContainer: {
      margin: '8px 0px'
    }
  }),
  { index: 1 }
);
