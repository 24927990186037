import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_PATH } from '../routes/routes';

export function useRoutes() {
  const navigate = useNavigate();

  const routes = useMemo(
    () => ({
      home: () => {
        navigate(ROUTES_PATH.home.use);
      },
      login: () => {
        navigate(ROUTES_PATH.login.use);
      },
      goBack: () => {
        navigate(-1);
      },
      logout: () => {
        navigate(ROUTES_PATH.logout.use);
      },
      aprovacao: () => {
        navigate(ROUTES_PATH.aprovacao.use);
      },
      summary: () => {
        navigate(ROUTES_PATH.summary.use);
      },
      matrix: () => {
        navigate(ROUTES_PATH.matrix.use);
      },
      // search: () => {
      //   navigate(ROUTES_PATH.search.use);
      // },
      noAuthorized: () => {
        navigate(ROUTES_PATH.noAuthorized.use);
      },
      emailInvalid: () => {
        navigate(ROUTES_PATH.emailInvalid.use);
      },
      goToEspecifcRoute: (routePath) => {
        navigate(routePath);
      }
    }),
    [navigate]
  );

  return {
    routes
  };
}
