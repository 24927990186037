import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    text: {
      fontSize: 11
    },
    numberText: {
      marginLeft: 5,
      color: '#000',
      fontSize: 10
    },
    painel: {
      background: '#f2f2f2',
      height: 45,
      position: 'sticky',
      top: 0,
      zIndex: 999,
      padding: 5,
      overflow: 'hidden'
    },
    chipVote: {
      border: '1.8px solid',
      marginLeft: 10,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: 50,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 5,
      color: 'black',
      opacity: '0.5'
    },
    selectedChip: {
      background: 'rgba(0, 0, 0, 0.1)',
      fontWeight: 'bold',
      color: '#000',
      borderColor: 'transparent',
      '&:focus': {
        background: 'rgba(0, 0, 0, 0.1) !important',
        color: '#000 !important'
      }
    },
    scrollMenu: {
      marginLeft: '25px',
      position: 'absolute',
      flexWrap: 'nowrap',
      transition: 'all ease 0.5s'
    },
    arrowButtonLeft: {
      position: 'absolute',
      zIndex: 10,
      background: '#f2f2f2',
      color: '#000',
      minWidth: 10,
      left: '0px',
      opacity: 0.4,
      '&:hover': {
        background: '#f2f2f2 !important',
        color: '#000',
        opacity: 0.8
      }
    },
    arrowButtonRight: {
      position: 'absolute',
      zIndex: 10,
      background: '#f2f2f2',
      color: '#000',
      minWidth: 10,
      right: '0px',
      opacity: 0.4,
      '&:hover': {
        background: '#f2f2f2 !important',
        color: '#000',
        opacity: 0.8
      }
    },
    iconButton: {
      fontSize: '25px'
    }
  }),
  { index: 1 }
);
