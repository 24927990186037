import './header.css';
import {
  HeaderBox,
  HeaderLeftContent,
  HeaderCenterContent,
  HeaderName,
  HeaderRightContent,
} from '../../styled/main';
import { ArrowLeftS } from '@styled-icons/remix-line';
import { IconButton } from '@material-ui/core';
import { useRoutes } from '../../../hooks/useRoutes';
import React, { useCallback } from 'react';

export default function NoAuthorizedHeader({ title, goBackTo }) {
  const { routes } = useRoutes();

  const handleRedirect = useCallback(() => {
    if (goBackTo) {
      return routes[goBackTo]();
    } else {
      return routes.goBack();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <HeaderBox>
      <HeaderLeftContent>
        <IconButton onClick={() => handleRedirect()}>
          <ArrowLeftS size={35} color="#000" />
        </IconButton>
      </HeaderLeftContent>
      <HeaderCenterContent>
        <HeaderName>{title}</HeaderName>
      </HeaderCenterContent>
      <HeaderRightContent></HeaderRightContent>
    </HeaderBox>
  );
}
