import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@font-face {font-family: "Flama";
    src: url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.eot"); /* IE9*/
    src: url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.woff2") format("woff2"), /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.woff") format("woff"), /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.svg#Flama") format("svg"); /* iOS 4.1- */
}
* {
    margin:0;
    padding: 0;
    outline: 0;
    font-family: 'Flama';
}

::-webkit-scrollbar {
    height:8px;
    width: 8px;
    border-radius:2px;
    :hover {
      height: 20px;
    }
  }

  .react-modal-action {
    width: 430px;
    height: 240px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  .ReactModal__Overlay .react-modal-action {
    transform: translateY(250%);
    transition: all 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open .react-modal-action {
    transform: translateY(0px);
  }
  
  .ReactModal__Overlay--before-close .react-modal-action {
    transform: translateY(250%);
  }
  
  .react-modal-action-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
  
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  /* Track */
  ::-webkit-scrollbar-track {
    background: #E5E5E5;
    width:5px;
  }
 
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    width:8px;
    transition: all 2s;
    :hover{
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

`;
