import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import useContextFilters from '../../../hooks/useContextFilters';
import { useUser } from '../../../hooks/useUser';

import TotalCard from '../../components/totalCard/index';
import TotalSwitchCard from '../../components/totalSwitchCard/index.js';

import { findColors } from '../../../services/colorService';
import { findGroups } from '../../../services/groupService';
import { findCoordinateds } from '../../../services/coordinatedService';
import { findLines } from '../../../services/lineService';
import { findSegments } from '../../../services/segmentService';
import { findSubgroups } from '../../../services/subGroupService';
import { findStylists } from '../../../services/stylistService';
import { findProductType } from '../../../services/productTypeService';
import { findTipoFarm } from '../../../services/tipoFarmService';
import { findRack } from '../../../services/rackService';
import { findMixedLine } from '../../../services/mixedLineService';
import { findPcpPeriod } from '../../../services/pcpPeriodService';
import { findBusiness } from '../../../services/businessService';
function Personalizado(props) {
  const { isUserFromFarmBR, isUserFromFabula, isUserFromHering } = useUser();
  const useFilters = useContextFilters();
  const [customFilter, setCustomFilter] = useState({
    lines: {},
    segments: {},
    groups: {},
    subGroups: {},
    coordinates: {},
    colors: {},
    stylists: {},
    productTypes: {},
    tiposFarm: {},
    racks: {},
    mixedLines: {},
    business: {},
    pcpPeriods: {},
    vitrine: []
  });
  const [lines, setLines] = useState([]);
  const [segments, setSegments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [pcpPeriods, setPcpPeriods] = useState([]);
  const [colors, setColors] = useState([]);
  const [stylists, setStylists] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [tiposFarm, setTiposFarm] = useState([]);
  const [racks, setRacks] = useState([]);
  const [mixedLines, setMixedLines] = useState([]);
  const [business, setBusiness] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const filterData = props.filterData;
  const { filters, getFilterBrand, getFilterCollection } = filterData;

  useEffect(() => {
    filterData.clearFilters({
      grouping: filters.grouping,
      collection: filters.collection,
      XAxis: isUserFromFarmBR()
        ? { label: 'Coordenado', value: 'C' }
        : { label: 'Cor', value: 'C' },
      YAxis: { label: 'Grupo', value: 'G' }
    });
    loadFilters();
  }, []); //eslint-disable-line

  useEffect(() => {
    addDetachedFilters(filterData.filtersLabels);
  }, [filterData.filtersLabels]); //eslint-disable-line

  const addDetachedFilters = (filters) => {
    let arrayOptions = [];
    filters.forEach((val, i) => {
      val.label &&
        arrayOptions.push({
          key: val.id,
          filter: val.label,
          filtro: val.filtro
        });
    });
    setFilterOptions(arrayOptions);
  };

  const loadRack = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findRack(params);
  };

  const loadTipoFarm = async () => {
    if (isUserFromFabula()) {
      let params = {
        ...getFilterBrand(),
        ...getFilterCollection()
      };
      return await findTipoFarm(params);
    }
    return [];
  };

  const loadLines = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findLines(params);
  };

  const loadSegment = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findSegments(params);
  };

  const loadGroups = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findGroups(params);
  };

  const loadSubGroups = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findSubgroups(params);
  };

  const loadPcpPeriods = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findPcpPeriod(params);
  };

  const loadCoordinates = async () => {
    if (isUserFromFarmBR()) {
      let params = {
        ...getFilterBrand(),
        ...getFilterCollection()
      };
      return await findCoordinateds(params);
    }
    return [];
  };

  const loadColor = async () => {
    if (!isUserFromFarmBR()) {
      let params = {
        ...getFilterBrand(),
        ...getFilterCollection()
      };
      return await findColors({
        'order[estampa_cor]': 'asc',
        ...params
      });
    }
    return [];
  };

  const loadStylists = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findStylists(params);
  };

  const loadProductTypes = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findProductType(params);
  };

  const loadMixedLines = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findMixedLine(params);
  };

  const loadBusiness = async () => {
    let params = {
      ...getFilterBrand(),
      ...getFilterCollection()
    };
    return await findBusiness(params);
  };

  const loadFilters = async () => {
    Promise.all([
      loadLines(),
      isUserFromFabula() ? loadSegment() : [],
      loadGroups(),
      loadSubGroups(),
      loadPcpPeriods(),
      loadStylists(),
      loadProductTypes(),
      loadTipoFarm(),
      loadRack(),
      isUserFromFarmBR() ? loadCoordinates() : loadColor(),
      isUserFromHering() ? loadBusiness() : [],
      isUserFromFarmBR() && loadMixedLines()
    ]).then(
      ([
        linesResponse,
        segmentsResponse,
        groupsResponse,
        subgroupsResponse,
        pcpPeriodsResponse,
        stylistsResponse,
        productTypesResponse,
        tiposFarmResponse,
        racksResponse,
        coordinatesResponse,
        businessResponse,
        mixedLinesResponse
      ]) => {
        setLines(linesResponse);
        setSegments(segmentsResponse);
        setGroups(groupsResponse);
        setSubGroups(subgroupsResponse);
        setPcpPeriods(pcpPeriodsResponse);
        if (isUserFromFarmBR()) {
          setCoordinates(coordinatesResponse);
        } else {
          setColors(coordinatesResponse);
        }
        setStylists(stylistsResponse);
        setProductTypes(productTypesResponse);
        setTiposFarm(tiposFarmResponse);
        setRacks(racksResponse);
        setBusiness(businessResponse);
        setMixedLines(mixedLinesResponse);
      }
    );
  };

  const changeCustomFilter = (key, id, label) => {
    const newObj = customFilter[key];
    if (!!!id) return;
    if (id === 'Limpar') {
      setFilterOptions([]);
      setCustomFilter({ ...customFilter, [key]: [] });
      filterData.setFilters({ ...filterData.filters, [key]: [] });
      filterData.cleanFilterLabels();
      useFilters.cleanFilterLabels();
    } else if (id === 0) {
      if (customFilter[key].length > 0) {
        setCustomFilter({ ...customFilter, [key]: [] });
        filterData.setFilters({ ...filterData.filters, [key]: [] });
      } else {
        setCustomFilter({ ...customFilter, [key]: [1] });
        filterData.setFilters({ ...filterData.filters, [key]: [1] });
      }
    } else if (newObj) {
      if (Object.prototype.hasOwnProperty.call(newObj, id)) {
        delete newObj[id];
      } else {
        newObj[id] = label;
      }
      setCustomFilter({ ...customFilter, [key]: newObj });
      filterData.setFilters({
        ...filterData.filters,
        [key]: Object.keys(customFilter[key])
      });
      filterData.addFilterLabel({
        id: id,
        label: label,
        filtro: key,
        value: id
      });
      useFilters.addFilterLabel({
        id: id,
        label: label,
        filtro: key,
        value: id
      });
    }
    return true;
  };

  return (
    <Grid container justify="center" style={{ flexGrow: 1, width: '100 %' }}>
      <TotalCard
        filterOptions={filterOptions}
        title="Linhas"
        type="lines"
        label="linha"
        value="id_linha"
        option={lines}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      {isUserFromFabula() && (
        <TotalCard
          filterOptions={filterOptions}
          title="Segmentos"
          type="segments"
          label="no_segmento"
          value="no_segmento"
          option={segments}
          filterData={props.filterData}
          customFilter={customFilter}
          setCustomFilter={setCustomFilter}
          changeCustomFilter={changeCustomFilter}
        />
      )}
      <TotalCard
        filterOptions={filterOptions}
        title="Grupos"
        type="groups"
        label="grupo_produto"
        value="id_grupo_produto"
        option={groups}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      <TotalCard
        filterOptions={filterOptions}
        title="Subgrupos"
        type="subGroups"
        label="no_subgrupo"
        value="no_subgrupo"
        option={subGroups}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      <TotalCard
        filterOptions={filterOptions}
        title="Período Pcp"
        type="pcpPeriods"
        label="periodo_pcp"
        value="periodo_pcp"
        option={pcpPeriods}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      {isUserFromFarmBR() ? (
        <TotalCard
          filterOptions={filterOptions}
          title="Coordenados"
          type="coordinates"
          label="no_estampa_farm"
          value="no_estampa_farm"
          option={coordinates}
          filterData={props.filterData}
          customFilter={customFilter}
          setCustomFilter={setCustomFilter}
          changeCustomFilter={changeCustomFilter}
        />
      ) : (
        <TotalCard
          filterOptions={filterOptions}
          title="Cores"
          type="colors"
          label="desc_estampa_cor"
          value="id_estampa_cor"
          option={colors}
          filterData={props.filterData}
          customFilter={customFilter}
          setCustomFilter={setCustomFilter}
          changeCustomFilter={changeCustomFilter}
        />
      )}
      <TotalCard
        filterOptions={filterOptions}
        title="Estilistas"
        type="stylists"
        label="estilista"
        value="id_estilista"
        option={stylists}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      <TotalCard
        filterOptions={filterOptions}
        title="Tipos de Produto"
        type="productTypes"
        label="no_tipo_produto"
        value="no_tipo_produto"
        option={productTypes}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      {isUserFromFabula() && (
        <TotalCard
          filterOptions={filterOptions}
          title="Tipo Farm"
          type="tiposFarm"
          label="no_tipo_farm"
          value="no_tipo_farm"
          option={tiposFarm}
          filterData={props.filterData}
          customFilter={customFilter}
          setCustomFilter={setCustomFilter}
          changeCustomFilter={changeCustomFilter}
        />
      )}
      <TotalCard
        filterOptions={filterOptions}
        title="Arara"
        type="racks"
        label="no_arara"
        value="no_arara"
        option={racks}
        filterData={props.filterData}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
        changeCustomFilter={changeCustomFilter}
      />
      {isUserFromFarmBR() && (
        <TotalCard
          filterOptions={filterOptions}
          title="Linha Mix"
          type="mixedLines"
          label="linha_mix"
          value="linha_mix"
          option={mixedLines}
          filterData={props.filterData}
          customFilter={customFilter}
          setCustomFilter={setCustomFilter}
          changeCustomFilter={changeCustomFilter}
        />
      )}
      {isUserFromHering() && (
        <TotalCard
          filterOptions={filterOptions}
          title="Negócio"
          type="business"
          label="no_sexo_publico_alvo"
          value="id_sexo_publico_alvo"
          option={business}
          filterData={props.filterData}
          customFilter={customFilter}
          setCustomFilter={setCustomFilter}
          changeCustomFilter={changeCustomFilter}
        />
      )}
      <TotalSwitchCard
        title="Vitrine"
        type="vitrine"
        changeCustomFilter={changeCustomFilter}
        customFilter={customFilter}
        setCustomFilter={setCustomFilter}
      />
    </Grid>
  );
}
export default Personalizado;
