import React, { useEffect, useState } from 'react';
import useVotes from '../../../hooks/useVotesCount';

import { useStyles } from './style';

import { Chip, Grid, Typography, Button } from '@material-ui/core';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import useContextFilters from '../../../hooks/useContextFilters';

export default function ToolHeader(props) {
  const classes = useStyles();
  const useFilters = useContextFilters();
  const votesCount = useVotes();
  const [filterOptions, setFilterOptions] = useState([]);
  const [translateX, setTranslateX] = useState(0);
  const [toolsWidth, setToolsWidth] = useState(0);
  const minPass = 150;

  useEffect(() => {
    setToolsWidth(
      120 * (2 + 3 + props.voteOpts.length) + 225 * filterOptions.length
    );
  }, [props.voteOpts.length, filterOptions.length]);

  useEffect(() => {
    addDetachedFilters(useFilters.filtersLabels);
    //eslint-disable-next-line
  }, [useFilters.filtersLabels]);

  useEffect(() => {
    return () => {
      useFilters.cleanFilterLabels();
    };
    //eslint-disable-next-line
  }, []);

  const addDetachedFilters = (filters) => {
    let arrayOptions = [];
    filters.forEach((val, i) => {
      arrayOptions.push({ key: val.id, filter: val.label, filtro: val.filtro });
    });
    setFilterOptions(arrayOptions);
  };

  function removeFilter(myFilter) {
    let filters = props.filterData.filters;
    if (myFilter.key === 'precoInicial') {
      if (filters[myFilter.filtro].length > 1) {
        let precoLimite = filters[myFilter.filtro][1];
        filters[myFilter.filtro] = [];
        filters[myFilter.filtro][1] = precoLimite;
      } else {
        filters[myFilter.filtro] = [];
      }
      props.filterData.setFilters({ ...filters });
      props.filterData.removeFilterLabel({ id: myFilter.key });
      return;
    }

    if (myFilter.key === 'precoLimite') {
      if (filters[myFilter.filtro].length > 0) {
        let precoInicial = filters[myFilter.filtro][0];
        filters[myFilter.filtro] = [];
        filters[myFilter.filtro][0] = precoInicial;
      } else {
        filters[myFilter.filtro] = [];
      }
      props.filterData.setFilters({ ...filters });
      props.filterData.removeFilterLabel({ id: myFilter.key });
      return props.getMappings();
    }

    filters[myFilter.filtro] = filters[myFilter.filtro].filter(
      (v, i) => `${v}` !== `${myFilter.key}`
    );
    props.filterData.setFilters({ ...filters });
    props.filterData.removeFilterLabel({ id: myFilter.key });
    useFilters.removeFilterLabel({ id: myFilter.key });
    return props.getMappings();
  }

  function renderFilterOptions() {
    if (filterOptions.length > 0) {
      //eslint-disable-next-line
      return filterOptions.map((opt, i) => {
        if (opt.filtro !== 'selecteds') {
          return (
            <Chip
              key={`${i}-filterOptions`}
              onClick={() => removeFilter(opt)}
              variant="outlined"
              label={getLabelFilter(opt.filter, opt)}
              className={
                props.vote === 'null'
                  ? `${classes.chipVote} ${classes.selectedChip}`
                  : classes.chipVote
              }
            />
          );
        }
      });
    }
  }

  function renderVotes() {
    if (props.voteOpts && Object.keys(props.mappings).length > 0) {
      return props.voteOpts.map((vote) => (
        <Chip
          key={vote.id_voto}
          disabled={props.loading}
          onClick={() => props.saveVote(vote.no_voto)}
          variant="outlined"
          label={
            <Typography variant="subtitle2" className={classes.text}>
              {vote.no_voto}{' '}
              {votesCount.votesTable?.[vote.id_voto]
                ? votesCount.votesTable[vote.id_voto]
                : 0}
            </Typography>
          }
          className={
            props.vote.includes(vote.no_voto)
              ? `${classes.chipVote} ${classes.selectedChip}`
              : classes.chipVote
          }
        />
      ));
    }
  }

  function renderManipulates() {
    if (Object.keys(props.mappings).length > 0 && votesCount.manipulatesTable) {
      return Object.keys(votesCount.manipulateOpts).map((opt, i) => (
        <Chip
          key={`${i}-key`}
          onClick={() => props.saveManipulate(opt)}
          variant="outlined"
          label={getLabel(
            votesCount.manipulateOpts[opt],
            votesCount.manipulatesTable[opt]
          )}
          className={
            props.manipulates.includes(opt)
              ? `${classes.chipVote} ${classes.selectedChip}`
              : classes.chipVote
          }
        />
      ));
    }
  }

  function handleBtnActionLeft() {
    let offset = translateX + minPass;
    if (offset > 0) {
      offset = 0;
    }
    setTranslateX(offset);
  }

  function handleBtnActionRight() {
    let offset = translateX - minPass;
    if (toolsWidth < window.innerWidth - 80) return false;
    else {
      if (toolsWidth - (window.innerWidth - 80) > Math.abs(offset)) {
        setTranslateX(offset);
      } else {
        setTranslateX(0);
      }
    }
  }

  function getLabel(text, votes) {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Typography variant="subtitle2" className={classes.text}>
          {text}
        </Typography>
        <Typography variant="subtitle2" className={classes.numberText}>
          {votes ? votes : 0}
        </Typography>
      </Grid>
    );
  }

  function getLabelFilter(text) {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Typography variant="subtitle2" className={classes.text}>
          {text}
        </Typography>
        <Typography variant="subtitle2" className={classes.numberText}>
          X
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.painel}
    >
      {props.voteOpts.length > 0 && Object.keys(props.mappings).length > 0 && (
        <>
          <Button
            className={classes.arrowButtonLeft}
            onClick={handleBtnActionLeft}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <div
            className={classes.scrollMenu}
            style={{
              transform: `translate(${translateX}px, 0px)`,
              width: toolsWidth,
            }}
          >
            <Chip
              disabled={props.loading}
              onClick={() => {
                props.saveManipulate(false);
                props.saveVote(false);
              }}
              variant="outlined"
              label={getLabel('Todos', votesCount.totalProducts)}
              className={
                props.vote.length === 0 && props.manipulates.length === 0
                  ? `${classes.chipVote} ${classes.selectedChip}`
                  : classes.chipVote
              }
            />
            <Chip
              disabled={props.loading}
              onClick={() => props.saveVote('null')}
              variant="outlined"
              label={getLabel(
                'Não Votados',
                votesCount.votesTable?.['Não votados']
              )}
              className={
                props.vote.includes('null')
                  ? `${classes.chipVote} ${classes.selectedChip}`
                  : classes.chipVote
              }
            />
            {props.mappings['Total produtos votados'] && renderVotes()}
            {props.mappings['Total produtos manipulados'] &&
              renderManipulates()}
            {renderFilterOptions()}
          </div>
          <Button
            className={classes.arrowButtonRight}
            onClick={handleBtnActionRight}
          >
            <KeyboardArrowRightIcon className={classes.iconButton} />
          </Button>
        </>
      )}
    </Grid>
  );
}
