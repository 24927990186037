import React, { useState, useEffect } from 'react';
import useFilters from '../../hooks/useFilters';
import { withSnackbar } from 'notistack';
import { useStyles } from './style';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import zoomInIcon from '../../assets/icons/zoomIn-icon.svg';
import zoomOutIcon from '../../assets/icons/zoomOut-icon.svg';

import CreateProduct from '../../components/group/createProduct/index';
import GetPDF from '../../components/pages/getPDF/index';
import FilterAV from '../../components/group/filterAV';
import VoteCardProduct from '../../components/components/voteCardProduct/index';
import ToolHeader from '../../components/group/toolHeader/index';

import { findMatrix } from '../../services/matrixService';
import { findVotes } from '../../services/voteService';
import { findClassification } from '../../services/classificationService';

import { findLines } from '../../services/lineService';
import { findGroups } from '../../services/groupService';
import { findSubgroups } from '../../services/subGroupService';
import { findCoordinateds } from '../../services/coordinatedService';
import { findColors } from '../../services/colorService';
import { findStylists } from '../../services/stylistService';
import { findProductType } from '../../services/productTypeService';
import { findTipoFarm } from '../../services/tipoFarmService';
import { findSupplier } from '../../services/supplierService';
import { findBusiness } from '../../services/businessService';
import { findRack } from '../../services/rackService';
import { findSegments } from '../../services/segmentService';
import useVotes from '../../hooks/useVotesCount';

import closeEyeIcon from '../../assets/icons/closeEye-icon.svg';
import openEyeIcon from '../../assets/icons/openEye-icon.svg';
import arrowLeft from '../../assets/icons/arrowLeft-icon.svg';
import { useRoutes } from '../../hooks/useRoutes';
import { useUser } from '../../hooks/useUser';

function Matrix(props) {
  const { isUserFromFarmBR, isUserFromFabula } = useUser();
  const { getMainBrandFromUser } = useUser();
  const routes = useRoutes();
  const classes = useStyles();
  const filterData = useFilters();
  const votesCount = useVotes();
  const [mappings, setMappings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groups2, setGroups2] = useState([]);
  const [lines, setLines] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [coordinate, setCoordinate] = useState([]);
  const [coordinate2, setCoordinate2] = useState([]);
  const [colors, setColors] = useState([]);
  const [stylists, setStylists] = useState([]);
  const [productType, setProductType] = useState([]);
  const [tipoFarm, setTipoFarm] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [business, setBusiness] = useState([]);
  const [rack, setRack] = useState([]);
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [voteOpts, setVoteOpts] = useState([]);
  const [vote, setVote] = useState([]);
  const [manipulates, setManipulates] = useState([]);
  const [classificationOpts, setClassificationOpts] = useState([]);
  const [voteCount, setVoteCount] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [visibility, setVisibility] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const matrixAxes = [
    { label: 'Arara', value: 'A' },
    { label: 'Grupo', value: 'G' },
    { label: 'Preço Venda', value: 'PV' },
    { label: 'Tipo Farm', value: 'TF' },
    { label: 'Linha', value: 'L' },
    { label: 'Periodo Pcp', value: 'PP' },
    isUserFromFarmBR()
      ? { label: 'Coordenado', value: 'C' }
      : { label: 'Cor', value: 'C' }
  ];
  const [matrixXAxis, setMatrixXAxis] = useState(filterData.filters.XAxis);
  const [matrixYAxis, setMatrixYAxis] = useState(filterData.filters.YAxis);
  const [oldMatrixXAxis, setOldMatrixXAxis] = useState(
    filterData.filters.XAxis
  );
  const [oldMatrixYAxis, setOldMatrixYAxis] = useState(
    filterData.filters.YAxis
  );
  const collection = filterData.getParamFromUrl('filter[colecao]');
  const label = filterData.getParamFromUrl('label');

  useEffect(() => {
    votesCount.setVotesTable(mappings['Total produtos votados']);
    votesCount.setManipulatesTable(mappings['Total produtos manipulados']);
    votesCount.setTotalProducts(mappings?.['Total produtos']);
  }, [mappings, votesCount]);

  useEffect(() => {
    if (Object.keys(oldMatrixXAxis).length === 0) {
      setOldMatrixXAxis(filterData.filters.XAxis);
    }
    if (Object.keys(oldMatrixYAxis).length === 0) {
      setOldMatrixYAxis(filterData.filters.YAxis);
    }
    if (
      Object.keys(matrixXAxis).length === 0 ||
      Object.keys(matrixYAxis).length === 0
    ) {
      setMatrixXAxis(filterData.filters.XAxis);
      setMatrixYAxis(filterData.filters.YAxis);
    }
  }, [filterData.filters.XAxis, filterData.filters.YAxis]); //eslint-disable-line

  const handleAddZoom = () => {
    if (zoom === 1) {
      props.enqueueSnackbar('Zoom máximo atingido', {
        variant: 'error'
      });
    } else {
      setZoom(zoom + 0.1);
    }
  };

  const handleRemoveZoom = () => {
    if (zoom === 0.5000000000000001) {
      props.enqueueSnackbar('Zoom mínimo atingido', {
        variant: 'error'
      });
    } else {
      setZoom(zoom - 0.1);
    }
  };

  useEffect(() => {
    getVotingOpts();
    getManipulateOpts();
  }, []); // eslint-disable-line

  useEffect(() => {
    getMappings();
  }, [filterData.filters.grouping]); //eslint-disable-line

  // useEffect(() => {
  //   if (filterData.loaded || filterData.isGroupingPersonalizado()) {
  //     getMappings();
  //   }
  // }, [filterData.loaded]);// eslint-disable-line

  useEffect(() => {
    getMappings();
  }, [vote, manipulates]); // eslint-disable-line

  async function getVotingOpts() {
    const { id_marca: brandFromUser } = getMainBrandFromUser();
    try {
      let response = await findVotes({
        id_marca: brandFromUser
      });
      setVoteOpts(response);
    } catch (error) {
      console.log('Error', error);
    }

    const classificationsResponse = await findClassification({
      ...filterData.getFilterBrand()
    });

    setClassificationOpts(classificationsResponse);
  }

  async function getMappings() {
    setLoading(true);

    let filterVotes = vote.length > 0 && { 'filter[no_voto]': vote.join() };

    let filterManipulates = manipulates.length > 0 && {
      'filter[id_tipo_produto_duplicado]': manipulates.join()
    };

    let filterXAxis = matrixXAxis['label']?.length
      ? { 'filter[XAxis]': matrixXAxis['label'] }
      : filterData.getFilterXAxis();

    let filterYAxis = matrixYAxis['label']?.length
      ? { 'filter[YAxis]': matrixYAxis['label'] }
      : filterData.getFilterYAxis();

    const params = filterData.isGroupingPersonalizado()
      ? {
          ...filterVotes,
          ...filterManipulates,
          ...filterXAxis,
          ...filterYAxis,
          ...filterData.getFilterBrand(),
          ...filterData.getFilterLines(),
          ...filterData.getFilterGroups(),
          ...filterData.getFilterCollection(),
          ...filterData.getFilterStylist(),
          ...filterData.getFilterProductType(),
          ...filterData.getFilterGroups(),
          ...filterData.getFilterSubGroups(),
          ...filterData.getFilterSegments(),
          ...filterData.getFilterPrice(),
          ...filterData.getFilterGrouping(),
          ...filterData.getFilterTipoFarm(),
          ...filterData.getFilterRack(),
          ...filterData.getFilterWardrobe(),
          ...filterData.getFilterPcpPeriod(),
          ...filterData.getFilterSupplier(),
          ...filterData.getFilterBusiness(),
          ...filterData.getFilterMaterial(),
          ...filterData.getFilterClassification(),
          ...filterData.getFilterMixedLine(),
          ...filterData.getFilterVitrine()
        }
      : {
          ...filterVotes,
          ...filterManipulates,
          ...filterXAxis,
          ...filterYAxis,
          ...filterData.getFilterBrand(),
          ...filterData.getFilterCollection(),
          ...filterData.getFilterStylist(),
          ...filterData.getFilterProductType(),
          ...filterData.getFilterGroups(),
          ...filterData.getFilterSubGroups(),
          ...filterData.getFilterSegments(),
          ...filterData.getFilterPrice(),
          ...filterData.getFilterGrouping(),
          ...filterData.getFilterTipoFarm(),
          ...filterData.getFilterSupplier(),
          ...filterData.getFilterBusiness(),
          ...filterData.getFilterRack(),
          ...filterData.getFilterWardrobe(),
          ...filterData.getFilterPcpPeriod(),
          ...filterData.getFilterMaterial(),
          ...filterData.getFilterClassification(),
          ...filterData.getFilterMixedLine(),
          ...filterData.getFilterVitrine()
        };

    if (filterData.isGroupingLinhas()) {
      const response = await findMatrix({
        ...filterData.getFilterCoordinates(),
        ...filterData.getFilterColors(),
        ...filterData.formatFilter(
          filterData.buildFilterList(['selecteds', 'lines']),
          'id_linha'
        ),
        ...params
      });

      if (response.Produtos.length === 0) {
        setLoading(false);
        props.enqueueSnackbar('Nenhuma Opção Para Essa Coleção', {
          variant: 'warning'
        });
        setMappings([]);
      } else {
        setMappings(response);
        setVoteCount(response['Total produtos votados']);

        let res = Object.keys(response['Produtos']);
        setGroups(res);
        setLoading(false);
        getHeaderSizes(response);
      }
    }

    if (filterData.isGroupingCoordenados()) {
      let params = {
        ...filterVotes,
        ...filterManipulates,
        ...filterXAxis,
        ...filterYAxis,
        ...filterData.getFilterMixedLine(),
        ...filterData.getFilterBrand(),
        ...filterData.getFilterCollection(),
        ...filterData.getFilterGrouping(),
        ...filterData.getFilterLines(),
        ...filterData.getFilterStylist(),
        ...filterData.getFilterProductType(),
        ...filterData.getFilterGroups(),
        ...filterData.getFilterSubGroups(),
        ...filterData.getFilterSegments(),
        ...filterData.getFilterPrice(),
        ...filterData.getFilterRack(),
        ...filterData.getFilterWardrobe(),
        ...filterData.getFilterPcpPeriod(),
        ...filterData.getFilterMaterial(),
        ...filterData.getFilterSupplier(),
        ...filterData.getFilterBusiness(),
        ...filterData.getFilterClassification(),
        ...filterData.getFilterMixedLine(),
        ...filterData.getFilterVitrine()
      };

      if (isUserFromFarmBR) {
        params = {
          ...params,
          ...filterData.formatFilter(
            filterData.buildFilter('selecteds'),
            'no_estampa_farm'
          )
        };
      } else {
        params = {
          ...params,
          ...filterData.formatFilter(
            filterData.buildFilterList(['selecteds', 'colors']),
            'id_estampa_cor'
          )
        };
      }

      const response = await findMatrix(params);

      if (response.Produtos.length === 0) {
        setLoading(false);
        props.enqueueSnackbar('Nenhuma Opção Para Essa Coleção', {
          variant: 'warning'
        });
        setMappings([]);
      } else {
        setMappings(response);
        setVoteCount(response['Total produtos votados']);
        let res = Object.keys(response['Produtos']);
        setGroups(res);
        setLoading(false);
        getHeaderSizes(response);
      }
    }

    if (filterData.isGroupingSegmentos()) {
      const colors = isUserFromFarmBR()
        ? filterData.getFilterCoordinates()
        : filterData.getFilterColors();
      let params = {
        ...colors,
        ...filterVotes,
        ...filterManipulates,
        ...filterXAxis,
        ...filterYAxis,
        ...filterData.getFilterBrand(),
        ...filterData.getFilterCollection(),
        ...filterData.getFilterGrouping(),
        ...filterData.getFilterLines(),
        ...filterData.getFilterStylist(),
        ...filterData.getFilterProductType(),
        ...filterData.getFilterGroups(),
        ...filterData.getFilterSubGroups(),
        ...filterData.getFilterSegments(),
        ...filterData.getFilterPrice(),
        ...filterData.getFilterTipoFarm(),
        ...filterData.getFilterRack(),
        ...filterData.getFilterWardrobe(),
        ...filterData.getFilterPcpPeriod(),
        ...filterData.getFilterMaterial(),
        ...filterData.getFilterSupplier(),
        ...filterData.getFilterBusiness(),
        ...filterData.getFilterClassification(),
        ...filterData.getFilterMixedLine(),
        ...filterData.getFilterVitrine()
      };

      params = {
        ...params,
        ...filterData.formatFilter(
          filterData.buildFilter('selecteds'),
          'no_segmento'
        )
      };

      const response = await findMatrix(params);

      if (response.Produtos.length === 0) {
        setLoading(false);
        props.enqueueSnackbar('Nenhuma Opção Para Essa Coleção', {
          variant: 'warning'
        });
        setMappings([]);
      } else {
        setMappings(response);
        setVoteCount(response['Total produtos votados']);
        let res = Object.keys(response['Produtos']);
        setGroups(res);
        setLoading(false);
        getHeaderSizes(response);
      }
    }
    if (filterData.isGroupingPersonalizado()) {
      const colors = isUserFromFarmBR()
        ? filterData.getFilterCoordinates()
        : filterData.getFilterColors();
      let params = {
        ...colors,
        ...filterVotes,
        ...filterManipulates,
        ...filterData.getFilterXAxis(),
        ...filterData.getFilterYAxis(),
        ...filterData.getFilterBrand(),
        ...filterData.getFilterLines(),
        ...filterData.getFilterGroups(),
        ...filterData.getFilterCollection(),
        ...filterData.getFilterStylist(),
        ...filterData.getFilterProductType(),
        ...filterData.getFilterGroups(),
        ...filterData.getFilterSubGroups(),
        ...filterData.getFilterSegments(),
        ...filterData.getFilterPrice(),
        ...filterData.getFilterGrouping(),
        ...filterData.getFilterTipoFarm(),
        ...filterData.getFilterRack(),
        ...filterData.getFilterWardrobe(),
        ...filterData.getFilterPcpPeriod(),
        ...filterData.getFilterMaterial(),
        ...filterData.getFilterSupplier(),
        ...filterData.getFilterBusiness(),
        ...filterData.getFilterClassification(),
        ...filterData.getFilterMixedLine(),
        ...filterData.getFilterVitrine()
      };

      const response = await findMatrix(params);

      if (response.Produtos.length === 0) {
        setLoading(false);
        props.enqueueSnackbar('Nenhuma Opção Para Essa Coleção', {
          variant: 'warning'
        });
        setMappings([]);
      } else {
        setMappings(response);
        setVoteCount(response['Total produtos votados']);
        let res = Object.keys(response['Produtos']);
        setGroups(res);
        setLoading(false);
        getHeaderSizes(response);
      }
    }
  }

  const getHeaderSizes = (data) => {
    const coordinateList = Object.values(data.Produtos).reduce(
      (acc = {}, item) => {
        let obj = acc;
        const keys = Object.keys(item);
        keys.forEach((key) => {
          obj = {
            ...obj,
            [key]:
              item[key].length >= (acc[key] || 0) ? item[key].length : acc[key]
          };
        });
        return obj;
      },
      {}
    );

    setCoordinate(coordinateList);

    return coordinateList;
  };

  const maxWidth =
    Object.values(coordinate).reduce((acc, item) => item + acc, 0) * 260;

  async function saveVote(value) {
    if (value === false) {
      setVote([]);
    } else {
      let lastVotes = vote;
      if (lastVotes.includes(value)) {
        lastVotes = lastVotes.filter((vote) => {
          return vote !== value;
        });
      } else {
        lastVotes.push(value);
      }
      setVote([...lastVotes]);
    }
  }

  async function saveManipulate(value) {
    if (value === false) {
      setManipulates([]);
    } else {
      let lastManipulates = manipulates;
      if (lastManipulates.includes(value)) {
        lastManipulates = lastManipulates.filter((manipulate) => {
          return manipulate !== value;
        });
      } else {
        lastManipulates.push(value);
      }
      setManipulates([...lastManipulates]);
    }
  }

  async function getManipulateOpts() {
    const orderEstampaCor = { 'order[estampa_cor]': 'asc' };

    const groups = await findGroups({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setGroups2(groups);

    const coordinates = await findCoordinateds({
      ...orderEstampaCor,
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });

    let newCoordinates;
    if (
      coordinates.find((coordinate) => {
        return coordinate.no_estampa_farm === 'INDEFINIDO';
      })
    ) {
      newCoordinates = coordinates;
    } else {
      newCoordinates = [{ no_estampa_farm: 'INDEFINIDO' }, ...coordinates];
    }
    setCoordinate2(newCoordinates);

    let color = await findColors({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });

    let newColor;
    if (
      color.find((color) => {
        return color.desc_estampa_cor === 'XXXX - INDEFINIDO';
      })
    ) {
      newColor = color;
    } else {
      newColor = [
        { id_estampa_cor: -1, desc_estampa_cor: 'XXXX - INDEFINIDO' },
        ...color
      ];
    }
    setColors(newColor);

    const subGroups = await findSubgroups({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setSubGroups(subGroups);
    const line = await findLines({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setLines(line);
    const stylist = await findStylists({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setStylists(stylist);
    const productType = await findProductType({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setProductType(productType);
    const tipoFarm = await findTipoFarm({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setTipoFarm(tipoFarm);
    const supplier = await findSupplier({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setSupplier(supplier);

    const business = await findBusiness({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setBusiness(business);

    const rack = await findRack({
      ...filterData.getFilterBrand(),
      ...filterData.getFilterCollection()
    });
    setRack(rack);
    if (isUserFromFabula) {
      const segments = await findSegments({
        ...filterData.getFilterBrand(),
        ...filterData.getFilterCollection()
      });
      setSegments(segments);
    }
  }

  const handleClassificationVotes = (classificacoes) => {
    return classificacoes
      .split(';')
      .filter((votes) => !isNaN(parseInt(votes)))
      .map((votes) => {
        return parseInt(votes);
      });
  };

  function matrixCounters(direction, option) {
    let lineCounter = 0;
    let collumnCounter = 0;
    if (Object.values(mappings['Produtos']).length) {
      if (direction === 'line') {
        Object.values(mappings['Produtos'][option]).forEach((opt) => {
          lineCounter = lineCounter + opt.length;
        });
        return lineCounter;
      }
      if (direction === 'collumn') {
        Object.values(mappings['Produtos']).forEach((opt) => {
          if (opt?.[option]) {
            collumnCounter = collumnCounter + opt[option].length;
          } else {
            return false;
          }
        });
        return collumnCounter;
      }
    }
  }

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
    setShowMenu(true);
  };

  const handleClose = () => {
    if (
      oldMatrixXAxis.label === matrixXAxis.label &&
      oldMatrixYAxis.label === matrixYAxis.label
    ) {
      setAnchor(null);
      return setShowMenu(false);
    } else {
      let route = props.history?.location.search;

      let cleanOldXRouteParam = filterData.getParamFromUrl('filter[XAxis]');
      let cleanOldYRouteParam = filterData.getParamFromUrl('filter[YAxis]');

      let oldXRouteParam = `filter%5BXAxis%5D=${cleanOldXRouteParam}`;
      let oldYRouteParam = `filter%5BYAxis%5D=${cleanOldYRouteParam}`;

      let newXRouteParam = `filter%5BXAxis%5D=${matrixXAxis.label}`;
      let newYRouteParam = `filter%5BYAxis%5D=${matrixYAxis.label}`;

      if (cleanOldXRouteParam.join() === 'Preço Venda') {
        oldXRouteParam = 'filter%5BXAxis%5D=Pre%C3%A7o+Venda';
      }
      if (cleanOldYRouteParam.join() === 'Preço Venda') {
        oldYRouteParam = 'filter%5BYAxis%5D=Pre%C3%A7o+Venda';
      }
      if (matrixYAxis.label === 'Preço Venda') {
        newYRouteParam = 'filter%5BYAxis%5D=Pre%C3%A7o+Venda';
      }
      if (matrixXAxis.label === 'Preço Venda') {
        newXRouteParam = 'filter%5BXAxis%5D=Pre%C3%A7o+Venda';
      }
      if (cleanOldXRouteParam.join() === 'Tipo Farm') {
        oldXRouteParam = 'filter%5BXAxis%5D=Tipo+Farm';
      }
      if (cleanOldYRouteParam.join() === 'Tipo Farm') {
        oldYRouteParam = 'filter%5BYAxis%5D=Tipo+Farm';
      }
      if (matrixYAxis.label === 'Tipo Farm') {
        newYRouteParam = 'filter%5BYAxis%5D=Tipo+Farm';
      }
      if (matrixXAxis.label === 'Tipo Farm') {
        newXRouteParam = 'filter%5BXAxis%5D=Tipo+Farm';
      }

      route = route
        ?.replace(oldXRouteParam, newXRouteParam)
        ?.replace(oldYRouteParam, newYRouteParam);

      setAnchor(null);
      setShowMenu(false);
      setOldMatrixXAxis(matrixXAxis);
      setOldMatrixYAxis(matrixYAxis);

      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        route;

      window.history.pushState({ path: newurl }, '', newurl);
      return getMappings();
    }
  };

  function renderMatrixAxesMenu() {
    return (
      <>
        <Grid
          onClick={handleClick}
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            height: zoom * 40 - 4,
            width: zoom * 40 - 4
          }}
        >
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography
              variant="subtitle2"
              component="h6"
              style={{
                fontSize: zoom * 12,
                fontWeight: 'bold',
                marginTop: zoom * 3
              }}
              className={classes.text2}
            >
              {matrixYAxis.value}/{matrixXAxis.value}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'center', marginTop: -8 / zoom }}
          >
            <ArrowDropDownIcon
              style={{ height: zoom * 10, width: zoom * 10 }}
            />
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchor}
          open={showMenu}
          onClose={handleClose}
          style={{ padding: 0 }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ width: 390, padding: 10, paddingTop: 0, paddingBottom: 0 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{ fontSize: 12, fontWeight: 'bold', opacity: 1 }}
                className={classes.text2}
              >
                VISUALIZAR MATRIZ POR:
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ marginTop: 10 }}>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{ fontSize: 12 }}
                className={classes.text2}
              >
                X:Linha
              </Typography>
              <Autocomplete
                size="small"
                variant="outlined"
                disableClearable
                options={matrixAxes}
                value={matrixYAxis}
                getOptionLabel={(options) => options.label}
                renderInput={(opt) => (
                  <TextField
                    {...opt}
                    style={{ width: 150 }}
                    variant="outlined"
                  />
                )}
                onChange={(e, v) => {
                  const prev = matrixYAxis;
                  if (v.label === matrixXAxis.label) {
                    setMatrixXAxis(prev);
                    setMatrixYAxis(v);
                    filterData.setFilters({
                      ...filterData.filters,
                      YAxis: v,
                      XAxis: prev
                    });
                  } else {
                    filterData.setFilters({ ...filterData.filters, YAxis: v });
                    setMatrixYAxis(v);
                  }
                }}
                style={{ width: 300 }}
              />
            </Grid>
            <Grid item xs={5} style={{ marginTop: 10 }}>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{ fontSize: 12 }}
                className={classes.text2}
              >
                Y:Coluna
              </Typography>
              <Autocomplete
                size="small"
                variant="outlined"
                disableClearable
                options={matrixAxes}
                value={matrixXAxis}
                getOptionLabel={(options) => options.label}
                renderInput={(opt) => (
                  <TextField
                    {...opt}
                    style={{ width: 150 }}
                    variant="outlined"
                  />
                )}
                onChange={(e, v) => {
                  const prev = matrixXAxis;
                  if (v.label === matrixYAxis.label) {
                    setMatrixYAxis(matrixXAxis);
                    setMatrixXAxis(v);
                    filterData.setFilters({
                      ...filterData.filters,
                      XAxis: v,
                      YAxis: prev
                    });
                  } else {
                    filterData.setFilters({ ...filterData.filters, XAxis: v });
                    setMatrixXAxis(v);
                  }
                }}
                style={{ width: 300 }}
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Reordenar">
                <IconButton
                  style={{ padding: 0, marginLeft: 20, marginTop: 27 }}
                  onClick={() => handleClose()}
                >
                  <PlayCircleOutlineIcon style={{ height: 25 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Menu>
      </>
    );
  }

  function renderLabel() {
    const joinLabels = label.join();
    if (
      filterData.isGroupingLinhas() ||
      filterData.isGroupingCoordenados() ||
      filterData.isGroupingSegmentos()
    ) {
      if (label.length === 1) {
        return (
          <Typography
            className={classes.title}
            style={{ textTransform: 'uppercase' }}
          >
            Aprovação de Visual - {collection} - {filterData.filters.grouping} -{' '}
            {label.join().toLowerCase().charAt(0).toUpperCase() +
              label.join().toLowerCase().slice(1)}
          </Typography>
        );
      }
      if (label.length > 1) {
        return (
          <Tooltip
            title={joinLabels.split(',').map((label) => (
              <h3>{label}</h3>
            ))}
          >
            <Typography
              className={classes.title}
              style={{ textTransform: 'uppercase' }}
            >
              Aprovação de Visual - {collection} - {filterData.filters.grouping}{' '}
              - Multi Seleção
            </Typography>
          </Tooltip>
        );
      }
    } else {
      return (
        <Typography
          className={classes.title}
          style={{ textTransform: 'uppercase' }}
        >
          Aprovação de Visual - {collection} - {filterData.filters.grouping}
        </Typography>
      );
    }
  }

  const getOptLabel = (opt, label) => {
    if (label.includes('Preço')) {
      return parseInt(opt).toFixed(2);
    }

    return opt;
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        justify="space-around"
        alignItems="center"
        className={classes.painel}
      >
        <Grid item xs={3}>
          <IconButton
            aria-label="Voltar"
            className={classes.blackButton}
            onClick={() =>
              routes.routes.goToEspecifcRoute(
                `/aprovacao?filter[colecao]=${collection}&agrupamento=${filterData.filters.grouping}`
              )
            }
          >
            <img src={arrowLeft} alt="arrow-left" />
          </IconButton>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          {loading ? (
            <Typography
              className={classes.title}
              style={{ textTransform: 'uppercase' }}
            >
              Aprovação de Visual
            </Typography>
          ) : (
            renderLabel()
          )}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {!loading && (
            <>
              <CreateProduct
                key={'Create Product'}
                getMappings={getMappings}
                lines={lines}
                groups={groups2}
                subGroups={subGroups}
                coordinates={coordinate2}
                colors={colors}
                stylists={stylists}
                productTypes={productType}
                tiposFarm={tipoFarm}
                suppliers={supplier}
                business={business}
                racks={rack}
                segments={segments}
              />
              {visibility ? (
                <Tooltip key={'Hide info'} title="Esconder Informações">
                  <IconButton onClick={() => setVisibility(false)}>
                    <img
                      src={openEyeIcon}
                      alt="zoom out"
                      style={{ height: '15px', marginTop: -4, opacity: 0.7 }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip key={'Show info'} title="Mostrar Informações">
                  <IconButton onClick={() => setVisibility(true)}>
                    <img
                      src={closeEyeIcon}
                      alt="zoom out"
                      style={{ height: '11px', opacity: 0.7 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip key={'Zoom out'} title="Diminuir Zoom">
                <IconButton onClick={handleRemoveZoom}>
                  <img
                    src={zoomOutIcon}
                    alt="zoom out"
                    style={{ height: '17px', marginTop: -4 }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip key={'Zoom in'} title="Aumentar Zoom">
                <IconButton onClick={handleAddZoom}>
                  <img
                    src={zoomInIcon}
                    alt="zoom in"
                    style={{ height: '17px', marginTop: -4 }}
                  />
                </IconButton>
              </Tooltip>
              <GetPDF
                products={mappings}
                filterData={filterData}
                matrixXAxis={matrixXAxis}
              />
            </>
          )}
          {!loading && (
            <FilterAV
              key={'Side Filter'}
              color="black"
              blockSelecteds
              filterData={filterData}
              getMappings={() => getMappings()}
            />
          )}
        </Grid>
      </Grid>
      <ToolHeader
        vote={vote}
        loading={loading}
        mappings={mappings}
        voteOpts={voteOpts}
        saveVote={saveVote}
        filterData={filterData}
        manipulates={manipulates}
        getMappings={getMappings}
        saveManipulate={saveManipulate}
      />
      <section className={classes.matrix}>
        {!loading ? (
          mappings['Total produtos'] > 0 ? (
            <Grid
              container
              direction="row"
              style={{ display: 'flex', overflowX: 'auto' }}
            >
              {!loading ? (
                <Grid
                  item
                  style={{ position: 'sticky', left: 0, zIndex: 1000 }}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                      backgroundColor: 'rgba(217, 217, 217, 1)',
                      height: zoom * 40 - 4,
                      width: zoom * 40 - 4,
                      zIndex: 999,
                      position: 'sticky',
                      left: 2,
                      top: 2,
                      cursor: 'pointer'
                    }}
                  >
                    {renderMatrixAxesMenu()}
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    style={{
                      marginTop: 4,
                      height: zoom * groups.length * (visibility ? 564 : 394)
                    }}
                  >
                    {groups.map(
                      (
                        collumn, //Grouping col
                        i
                      ) => (
                        <Grid
                          key={`${collumn}-${i}`}
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          style={{
                            background: '#E5E5E5',
                            width: zoom * 40,
                            height: zoom * (visibility ? 564 : 394),
                            border: '2px solid white'
                          }}
                        >
                          <Grid item xs={12} className={classes.columnLabel}>
                            <Typography
                              style={{
                                fontSize: zoom * 11,
                                textTransform: 'uppercase'
                              }}
                              className={classes.text}
                            >
                              {getOptLabel(collumn, oldMatrixYAxis?.label)} (
                              {matrixCounters('line', collumn)})
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              ) : null}
              <Grid item style={{ marginLeft: 0 }} xs={10}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{
                    width: zoom * maxWidth,
                    height: zoom * 40,
                    position: 'sticky',
                    top: 0,
                    zIndex: 999
                  }}
                >
                  {Object.keys(coordinate).map((opt, i) => (
                    <Grid
                      key={`${opt}-${i}`}
                      item
                      style={{
                        minWidth: zoom * 260 * coordinate[opt],
                        height: zoom * 40
                      }}
                    >
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{
                          background: '#E5E5E5',
                          height: zoom * 40,
                          border: '2px solid white'
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            className={classes.text2}
                            style={{
                              fontSize: zoom * 12,
                              alignItems: 'center',
                              maxWidth: zoom * 250 * coordinate[opt],
                              textAlign: 'center',
                              maxHeight: zoom * 40
                            }}
                          >
                            {getOptLabel(opt, oldMatrixXAxis?.label)} (
                            {matrixCounters('collumn', [opt])})
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container style={{ width: zoom * maxWidth + 10 }}>
                  {!loading && groups && voteOpts ? (
                    groups.map((line, i) => (
                      <Grid
                        key={`${line}-${i}`}
                        container
                        justify="flex-start"
                        style={{ width: maxWidth }}
                      >
                        {Object.keys(coordinate).map((col, i2) => (
                          <Box
                            key={`products-${col}-${i2}`}
                            borderColor="white"
                            border={2}
                            style={{
                              justifyContent: 'flex-start',
                              width: zoom * 260 * coordinate[col] - 4,
                              height: zoom * (visibility ? 564 : 394) - 4,
                              display: 'flex',
                              backgroundColor: 'rgb(246 245 245)'
                            }}
                          >
                            {mappings.Produtos[line][col] &&
                            voteOpts[0]?.id_voto ? (
                              mappings.Produtos[line][col].map((product) => (
                                <VoteCardProduct
                                  key={`${product.id_produto_estilo}-${product.id_produto_duplicado}-${i}`}
                                  zoom={zoom}
                                  voteCount={voteCount}
                                  setVoteCount={setVoteCount}
                                  product={product}
                                  handleClassificationVotes={
                                    handleClassificationVotes
                                  }
                                  getMappings={getMappings}
                                  grouping={filterData.filters.grouping}
                                  voteOpts={voteOpts}
                                  groups={groups2}
                                  colors={colors}
                                  coordinates={coordinate2}
                                  linesManipulateOpts={lines}
                                  racksManipulateOpts={rack}
                                  farmTypesManipulateOpts={tipoFarm}
                                  segmentsManipulateOpts={segments}
                                  subGroupsManipulateOpts={subGroups}
                                  classificationOpts={classificationOpts}
                                  visibility={visibility}
                                  filterData={filterData}
                                />
                              ))
                            ) : (
                              <Box
                                style={{
                                  height: visibility ? 564 : 454,
                                  width: 260
                                }}
                                borderColor="white"
                                border={0}
                              ></Box>
                            )}
                          </Box>
                        ))}
                      </Grid>
                    ))
                  ) : (
                    <CircularProgress style={{ margin: 300 }} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ height: '55vh' }}
            >
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: 200 }}
              >
                <Typography
                  style={{ fontFamily: 'Flama', color: '#000' }}
                  variant="h4"
                  component="p"
                >
                  Sem Opções Para Essa Coleção
                </Typography>
                <span role="img" className={classes.emoji} aria-label="Shrug">
                  🤷
                </span>
              </Grid>
            </Grid>
          )
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: '55vh' }}
          >
            <CircularProgress style={{ color: '#4C4C4C' }} />
          </Grid>
        )}
      </section>
    </Box>
  );
}
export default withSnackbar(Matrix);
