/* eslint-disable no-undef */
export const ROUTES = {
  LOGIN: '/login',
  SEARCH: '/search',
  INSTA: '/insta',
  HOME: '/home',
  APROVACAOVISUAL: '/aprovacao',
  MATRIX: '/aprovacao/vote',
  SUMMARY: '/aprovacao/summary'
};

export const OMNI_URL =
  'https://gestao-omni-dot-apt-bonbon-179602.appspot.com/v0';

let PLM_URL =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://plm-dot-apt-bonbon-179602.ue.r.appspot.com/api'
    : 'https://plm-homolog-dot-apt-bonbon-179602.ue.r.appspot.com/api';

export const PLM_API_URL = PLM_URL;

let baseApi =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://aprovacao-visual-api-production-wlavxis5jq-uc.a.run.app'
    : 'https://aprovacao-visual-api-homolog-wlavxis5jq-uc.a.run.app';
if (process.env.REACT_APP_STAGE === 'develop') {
  baseApi = 'http://localhost:8000';
}

export const getResizedImage = (img, width = 600) => {
  if (img?.includes('data:image/')) {
    return img;
  } else if (process.env.REACT_APP_STAGE === 'prod') {
    return `https://estilo-imagem-api-production-wlavxis5jq-uc.a.run.app/api/v1/imagem/width/${width}/${img}`;
  } else {
    return `https://estilo-imagem-api-homolog-wlavxis5jq-uc.a.run.app/api/v1/imagem/width/${width}/${img}`;
  }
};

export const getFullImage = (img) => {
  if (img.includes('data:image/')) {
    return img;
  } else if (process.env.REACT_APP_STAGE === 'prod') {
    return `https://estilo-imagem-api-production-wlavxis5jq-uc.a.run.app/api/v1/imagem/${img}`;
  } else {
    return `https://estilo-imagem-api-homolog-wlavxis5jq-uc.a.run.app/api/v1/imagem/${img}`;
  }
};

export const BASE_URL_APROVACAO = baseApi;

export const BASE_URL = baseApi;

let apiAccessControlUrl =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://mais-controle-api-production-wlavxis5jq-uc.a.run.app/api/v1'
    : 'https://mais-controle-api-homolog-wlavxis5jq-uc.a.run.app/api/v1';
if (process.env.REACT_APP_STAGE === 'develop') {
  apiAccessControlUrl = 'http://localhost:8888/api/v1';
}

let somaAuthUrl =
  process.env.REACT_APP_STAGE === 'prod'
    ? 'https://soma-auth.somalabs.com.br'
    : 'https://soma-auth-homolog.somalabs.com.br';

//const somaAuthUrl = 'https://soma-auth.somalabs.com.br';
export const API_ACCESS_CONTROL_URL = apiAccessControlUrl;
export const SOMA_AUTH_URL = somaAuthUrl;
const objTokenBody = {
  client_id:
    process.env.REACT_APP_STAGE === 'prod'
      ? 'mais-aprovacao'
      : 'mais-aprovacao-hml',
  grant_type: 'authorization_code',
  grant_type_to_refresh_toke: 'refresh_token',
  redirect_uri:
    process.env.REACT_APP_STAGE === 'prod'
      ? 'https://maisaprovacao.somalabs.com.br/login'
      : 'https://maisaprovacao-homolog.somalabs.com.br/login',
  client_secret: 'ImmmcWfYxEemuLBFZvbMCAiscEthCI-ZzP-hilBRnPA'
};
const objAuthBody = {
  scope: 'openid permissions offline_access',
  response_type: 'code',
  client_id:
    process.env.REACT_APP_STAGE === 'prod'
      ? 'mais-aprovacao'
      : 'mais-aprovacao-hml',
  redirect_uri:
    process.env.REACT_APP_STAGE === 'prod'
      ? 'https://maisaprovacao.somalabs.com.br/login'
      : 'https://maisaprovacao-homolog.somalabs.com.br/login',
  prompt: 'select_account'
};
if (process.env.REACT_APP_STAGE === 'develop') {
  objTokenBody.client_id = 'mais-aprovacao-dev';
  objTokenBody.redirect_uri = 'http://localhost:3000/login';
  objAuthBody.client_id = 'mais-aprovacao-dev';
  objAuthBody.redirect_uri = 'http://localhost:3000/login';
}

export const SOMA_AUTH_AUTENTICATION = objAuthBody;

export const TOKEN_API_BODY = objTokenBody;

export const SOMA_AUTH_LOGIN_URL = `${somaAuthUrl}/auth?scope=${SOMA_AUTH_AUTENTICATION.scope}&response_type=${SOMA_AUTH_AUTENTICATION.response_type}&client_id=${SOMA_AUTH_AUTENTICATION.client_id}&redirect_uri=${SOMA_AUTH_AUTENTICATION.redirect_uri}&prompt=${SOMA_AUTH_AUTENTICATION.prompt}`;
