import React, { useState, useEffect } from 'react';

import SummaryChart from '../../components/group/summaryChart/index';

import { useStyles } from './style';
import useFilters from '../../hooks/useFilters';
import { findLines } from '../../services/lineService';

import { withSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  TextField,
  Typography
} from '@material-ui/core';

import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import arrowLeft from '../../assets/icons/arrowLeft-icon.svg';

import { findMappingSummary } from '../../services/mappingService';
import { useRoutes } from '../../hooks/useRoutes';
import { useUser } from '../../hooks/useUser';

function Summary() {
  const { getMainBrandFromUser } = useUser();
  const routes = useRoutes();
  const classes = useStyles();
  const filterData = useFilters();
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const [anchor, setAnchor] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [lines, setLines] = useState({});
  const grouping = filterData.getParamFromUrl('agrupamento')[0];
  const collection = filterData.getParamFromUrl('filter[colecao]')[0];
  const { id_marca: brandFromUser } = getMainBrandFromUser();
  const [translateX, setTranslateX] = useState(0);
  const [toolsWidth, setToolsWidth] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);

  const customFilterInitialState = {
    lines: {},
    segments: {},
    groups: {},
    subGroups: {},
    coordinates: {},
    colors: {},
    stylists: {},
    productTypes: {},
    tiposFarm: {},
    racks: {},
    mixedLines: {},
    vitrine: []
  };

  const [customFilter, setCustomFilter] = useState({
    ...customFilterInitialState
  });

  const minPass = 110;

  function handleBtnActionLeft() {
    let offset = translateX + minPass;
    if (offset > 0) {
      offset = 0;
    }
    setTranslateX(offset);
  }

  function handleBtnActionRight() {
    let offset = translateX - minPass;
    if (toolsWidth < 0.2 * window.innerWidth + 300) return false;
    else {
      if (toolsWidth - (0.2 * window.innerWidth + 300) > Math.abs(offset)) {
        setTranslateX(offset);
      } else {
        setTranslateX(0);
      }
    }
  }

  useEffect(() => {
    addDetachedFilters(filterData.filtersLabels);
  }, [filterData.filtersLabels]); //eslint-disable-line

  useEffect(() => {
    setToolsWidth(225 * filterOptions.length);
  }, [filterOptions.length]);

  const addDetachedFilters = (filters) => {
    let arrayOptions = [];
    filters.forEach((val, i) => {
      arrayOptions.push({ key: val.id, filter: val.label, filtro: val.filtro });
    });
    setFilterOptions(arrayOptions);
  };

  const includes = (key, id) => {
    const newObj = customFilter[key];
    if (newObj && Object.prototype.hasOwnProperty.call(newObj, id)) {
      return true;
    } else {
      return false;
    }
  };

  const filterSearchOptions = createFilterOptions({
    limit: 200
  });

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
    setShowMenu((s) => !s);
  };

  const handleClose = () => {
    setAnchor(null);
    setShowMenu(false);
  };

  useEffect(() => {
    getFilters();
  }, []); //eslint-disable-line

  useEffect(() => {
    let filtros = filterData.buildFilters();
    if (filtros['filter[colecao]'] && grouping) {
      getSummary();
    }
  }, [filterData.filters.collection, customFilter]); //eslint-disable-line

  async function getSummary() {
    setLoading(true);
    let filters = filterData.buildFilters();
    filters.agrupamento = grouping;
    const response = await findMappingSummary({ ...filters });
    setSummary(response);
    return setLoading(false);
  }

  const loadLines = async () => {
    let filtros = filterData.buildFilters();
    filtros.agrupamento = grouping;
    const response = await findLines({ ...filtros });
    setLines(response);
  };

  function getFilters() {
    loadLines();
  }

  function formatPrice(price) {
    if (price) {
      return (price / 100).toFixed(2).replace('.', ',');
    } else {
      return '0,00';
    }
  }

  const changeCustomFilter = (key, id, label) => {
    const newObj = customFilter[key];
    if (id === 'Limpar') {
      filterData.cleanFilterLabels();
      setCustomFilter({ ...customFilter, [key]: [] });
      filterData.setFilters({ ...filterData.filters, [key]: [] });
    } else if (id === 0) {
      if (customFilter[key].length > 0) {
        setCustomFilter({ ...customFilter, [key]: [] });
        filterData.setFilters({ ...filterData.filters, [key]: [] });
      } else {
        setCustomFilter({ ...customFilter, [key]: [1] });
        filterData.setFilters({ ...filterData.filters, [key]: [1] });
      }
    } else if (newObj) {
      if (Object.prototype.hasOwnProperty.call(newObj, id)) {
        delete newObj[id];
      } else {
        newObj[id] = label;
      }
      setCustomFilter({ ...customFilter, [key]: newObj });
      filterData.setFilters({
        ...filterData.filters,
        [key]: Object.keys(customFilter[key])
      });
      filterData.addFilterLabel({
        id: id,
        label: label,
        filtro: key,
        value: id
      });
    }
    return true;
  };

  function renderFilterOptions() {
    if (filterOptions.length > 0) {
      //eslint-disable-next-line
      return filterOptions.map((opt, i) => {
        if (opt.filtro !== 'selecteds') {
          return (
            <Chip
              key={`${i}-filterOptions`}
              onClick={() =>
                changeCustomFilter(opt.filtro, opt.key, opt.filter)
              }
              variant="outlined"
              label={getLabelFilter(opt.filter, opt)}
            />
          );
        }
      });
    }
  }

  function getLabelFilter(text) {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Typography variant="subtitle2" className={classes.text}>
          {text}
        </Typography>
        <Typography variant="subtitle2" className={classes.numberText}>
          X
        </Typography>
      </Grid>
    );
  }

  function Header() {
    return (
      <>
        <Grid
          item
          container
          justify="space-around"
          alignItems="center"
          className={classes.painel}
          xs={12}
        >
          <Grid item container justify="flex-start" xs={4}>
            <IconButton
              aria-label="Voltar"
              className={classes.whiteButton}
              onClick={() => routes.routes.goBack()}
            >
              <img src={arrowLeft} alt="arrow-left" className={classes.icon} />
            </IconButton>
          </Grid>
          <Grid item container justify="center" xs={4}>
            <Typography className={classes.title}>RESUMO</Typography>
          </Grid>
          <Grid item container justify="flex-end" xs={4}></Grid>
        </Grid>
      </>
    );
  }

  function toolHeader() {
    return (
      <>
        <div
          className={classes.headerBtn}
          onClick={() => changeCustomFilter('lines', 'Limpar', 'Limpar')}
        >
          <Typography className={classes.headerBtnLabel}>
            Limpar Filtros
          </Typography>
        </div>
        <Grid container alignContent="center" className={classes.chipPainel}>
          <Button
            className={classes.arrowButtonLeft}
            onClick={handleBtnActionLeft}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <div
            className={classes.scrollMenu}
            style={{
              transform: `translate(${translateX}px, 0px)`,
              width: toolsWidth
            }}
          >
            {renderFilterOptions()}
          </div>
          <Button
            className={classes.arrowButtonRight}
            onClick={handleBtnActionRight}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </Grid>
      </>
    );
  }

  function renderGroupsSummary(summary) {
    const summarys = summary;
    const skeletonArr = ['1', '2', '3', '4'];
    if (!!summarys.groups && !loading) {
      return (
        <>
          {Object.keys(summarys.groups).map((group) => (
            <Grid
              key={`${group}-${summarys.groups[group].total}`}
              item
              xs={12}
              style={{ display: 'flex' }}
            >
              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Typography className={classes.groupSummaryTotal}>
                  {summarys.groups[group].total}
                </Typography>
                <Typography className={classes.groupSummaryTitle}>
                  {group}
                </Typography>
              </Grid>

              <Grid item xs={3} className={classes.groupSummaryContainer}>
                {Object.keys(summarys.votes).map((voteKey) => {
                  const voteId = summarys.votes[voteKey].id_voto;
                  const voteLabel = summarys.votes[voteKey].no_voto;
                  const voteCount = summarys.groups[group].votes[voteId]
                    ? summarys.groups[group].votes[voteId].total
                    : 0;
                  return (
                    <Typography
                      key={`${voteLabel}-${voteId}`}
                      className={classes.groupSummaryVotes}
                    >
                      {voteLabel}: {voteCount}
                    </Typography>
                  );
                })}
                <Typography className={classes.groupSummaryVotes}>
                  Não Votados:{' '}
                  {summarys.groups[group].votes[-1]
                    ? summarys.groups[group].votes[-1].total
                    : 0}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Typography className={classes.groupSummaryPrices}>
                  Max: R${' '}
                  {summarys.groups[group].preco.max
                    .toFixed(2)
                    .replace('.', ',')}
                </Typography>
                <Typography className={classes.groupSummaryPrices}>
                  Min: R${' '}
                  {summarys.groups[group].preco.min
                    .toFixed(2)
                    .replace('.', ',')}
                </Typography>
              </Grid>

              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Typography className={classes.groupSummaryPrices}>
                  Max: R$ {formatPrice(summarys.groups[group].vl_percebido.max)}
                </Typography>
                <Typography className={classes.groupSummaryPrices}>
                  Min: R$ {formatPrice(summarys.groups[group].vl_percebido.min)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </>
      );
    } else {
      return (
        <>
          {skeletonArr.map((group) => (
            <Grid key={`${group}`} item xs={12} style={{ display: 'flex' }}>
              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Skeleton
                  className={classes.skeleton}
                  variant="rect"
                  width={150}
                  height={160}
                  animation="wave"
                />
              </Grid>

              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Skeleton
                  className={classes.skeleton}
                  variant="rect"
                  width={150}
                  height={160}
                  animation="wave"
                />
              </Grid>
              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Skeleton
                  className={classes.skeleton}
                  variant="rect"
                  width={150}
                  height={160}
                  animation="wave"
                />
              </Grid>

              <Grid item xs={3} className={classes.groupSummaryContainer}>
                <Skeleton
                  className={classes.skeleton}
                  variant="rect"
                  width={150}
                  height={160}
                  animation="wave"
                />
              </Grid>
            </Grid>
          ))}
        </>
      );
    }
  }

  function filterMenu() {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        open={showMenu}
        onClose={handleClose}
        classes={{ list: classes.list }}
        style={{
          margin: '35px 70px',
          minWidth: 220,
          display: 'flex',
          justifyContent: 'center',
          padding: 8
        }}
      >
        <Autocomplete
          filterOptions={filterSearchOptions}
          size="small"
          disableClearable
          options={lines}
          getOptionLabel={(options) => options['linha'] && options['linha']}
          renderInput={(opt) => (
            <TextField
              {...opt}
              style={{ width: 180 }}
              label={'Pesquisar Linha'}
              variant="outlined"
            />
          )}
          onChange={(e, v) =>
            changeCustomFilter('lines', v['id_linha'], v['linha'])
          }
        />
        {lines.length > 0 ? (
          lines.map((line) => (
            <div
              key={`${line['linha']}-checkbox`}
              onClick={() =>
                changeCustomFilter('lines', line['id_linha'], line['linha'])
              }
              className={classes.menuItem}
            >
              <Checkbox
                key={`${line['id_linha']}-checkbox`}
                checked={includes('lines', line['id_linha'])}
                style={{ color: 'black' }}
              />
              {line['linha']}
            </div>
          ))
        ) : (
          <div className={classes.menuLoadingContainer}>
            <CircularProgress />
          </div>
        )}
      </Menu>
    );
  }

  return (
    <>
      {Header()}
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="flex-start"
        className={classes.root}
      >
        <Grid item xs={6} className={classes.gridContainer}>
          <div className={classes.titleLabelContainer}>
            <Typography className={classes.titleLabel}>MARCA</Typography>
            <Typography className={classes.subTitleLabel}>
              {brandFromUser}
            </Typography>
          </div>
          <div className={classes.titleLabelContainer}>
            <Typography className={classes.titleLabel}>COLEÇÃO</Typography>
            <Typography className={classes.subTitleLabel}>
              {collection}
            </Typography>
          </div>
          <div className={classes.titleLabelContainer}>
            <Typography className={classes.titleLabel}>FILTRAR POR</Typography>
            <Typography
              className={classes.subTitleLabel}
              style={{ display: 'flex', cursor: 'pointer' }}
              onClick={handleClick}
            >
              LINHA
              <ArrowDropDownIcon />
            </Typography>
            {filterMenu()}
          </div>

          {filterOptions.length > 0 && toolHeader()}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <Grid item xs={3} className={classes.columnHeaderContainer}>
            <Typography className={classes.columnHeaderTitle}>GRUPO</Typography>
          </Grid>

          <Grid item xs={3} className={classes.columnHeaderContainer}>
            <Typography className={classes.columnHeaderTitle}>
              CLASSIFICAÇÃO
            </Typography>
          </Grid>

          <Grid item xs={3} className={classes.columnHeaderContainer}>
            <Typography className={classes.columnHeaderTitle}>
              PREÇO VENDA
            </Typography>
          </Grid>

          <Grid item xs={3} className={classes.columnHeaderContainer}>
            <Typography className={classes.columnHeaderTitle}>
              PREÇO PERCEBIDO (ESTILO)
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          {!loading ? (
            <SummaryChart summary={summary} />
          ) : (
            <div className={classes.chartSkeletonContainer}>
              <Skeleton
                variant="circle"
                width={480}
                height={480}
                animation="wave"
              />
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{ maxHeight: 'calc(100vh - 125px)', overflowY: 'auto' }}
        >
          {renderGroupsSummary(summary)}
        </Grid>
      </Grid>
    </>
  );
}
export default withSnackbar(Summary);
