import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    homeContainer: {
      padding: 36
    },
    title: {
      fontFamily: 'Flama',
      fontSize: 48,
      letterSpacing: '0em'
    },
    subTitle: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.3em',
      marginTop: 54,
      marginLeft: 72
    },
    menuCardRoot: {
      flexGrow: 1
    },
    card: {
      paddingTop: theme.spacing(1),
      // marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      width: '100%',
      borderTop: '1px solid rgba(0,0,0,0.1)'
    },
    fontWhite: {
      textTransform: 'uppercase',
      fontFamily: 'Flama',
      fontSize: 32,
      letterSpacing: '0.05em'
    },
    hover: {
      padding: 10,
      borderRadius: 10,
      '&:hover': {
        background: 'rgba(211, 211, 211, 0.4)',
        cursor: 'pointer',
        transition: '0.2s'
      }
    },
    arrowDropDown: {
      transform: 'rotate(90deg)',
      fontSize: '1rem',
      marginLeft: 10
    },
    arrowDropUp: {
      transform: 'rotate(270deg)',
      fontSize: '1rem',
      marginLeft: 10
    },
    gridItem: {
      margin: '36px 72px'
    }
  }),
  { index: 1 }
);
