import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    productImage: {
      background: `url("http://portal.ufvjm.edu.br/a-universidade/cursos/grade_curricular_ckan/loading.gif") no-repeat;`
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    slideContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    slide: {
      margin: '0 auto'
    }
  }),
  { index: 1 }
);
