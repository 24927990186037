import NoAuthorizedHeader from '../../components/components/NoAuthorizedHeader/index';
import React from 'react';
import {
  WrapperPage,
  MessageSection,
  MessageText,
  MessageTitle,
} from './style';
import NoAuthorizedButton from '../../components/components/NoAuthorizedButton/index';
import RequestPermission from '../../components/group/requestPermission/index';
import { authGuard } from '../../guard/AuthGuard';

export default function NoAuthorized() {
  const renderUnauthorizedMessage = () => {
    if (window.location.href.includes('email-invalid')) {
      return (
        <>
          <MessageSection>
            <MessageTitle>
              {'Ops, não reconhecemos esse e-mail...'}
            </MessageTitle>
            <MessageText>
              Você só pode solicitar permissão para ter acesso aos conteúdos
              utilizando seu e-mail corporativo.
            </MessageText>
            <NoAuthorizedButton
              title="Tentar outro e-mail"
              handleClick={() => authGuard.redirectToLogin()}
              disabled={false}
            />
          </MessageSection>
        </>
      );
    } else {
      return <RequestPermission />;
    }
  };

  return (
    <>
      <NoAuthorizedHeader title="Permissão" goBackTo="logout" />
      <WrapperPage>{renderUnauthorizedMessage()}</WrapperPage>
    </>
  );
}
