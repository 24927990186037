import React from "react";
import { Grid, Button, DialogTitle, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";

export const DialogHeader = ({ handleClose, createProductFunction, disable }) => (
  <DialogTitle onClose={handleClose}>
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={6}>
        Criar Produto
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          disabled={disable}
          onClick={() => createProductFunction()}
          style={{
            backgroundColor: disable ? "grey" : "black",
            color: "white",
          }}
          variant="contained"
        >
          Criar
        </Button>
      </Grid>
    </Grid>
  </DialogTitle>
);

export const Description = ({ createdProduct, setCreatedProduct }) => (
  <Grid item style={{ marginTop: 10 }}>
    <TextField
      label="Descrição*"
      size="small"
      style={{ width: 300 }}
      variant="outlined"
      onChange={(e) =>
        setCreatedProduct({
          ...createdProduct,
          ds_produto: e.target.value,
        })
      }
    />
  </Grid>
);

export const AutocompleteGroup = ({
  filterOptions,
  size,
  width,
  options,
  getOptionLabel,
  label,
  variant,
  onChange,
}) => (
  <Autocomplete
    filterOptions={filterOptions}
    size={size}
    style={{ width }}
    disableClearable
    options={options}
    getOptionLabel={getOptionLabel}
    renderInput={(params) => (
      <TextField
        {...params}
        style={{ width }}
        label={label}
        variant={variant}
      />
    )}
    onChange={onChange}
  />
);

export const PriceTextField = ({ createdProduct, setCreatedProduct }) => (
  <Grid item style={{ marginTop: 10 }}>
    <TextField
      label="Preço*"
      type="number"
      size="small"
      style={{ width: 300 }}
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
      }}
      onChange={(e) =>
        setCreatedProduct({
          ...createdProduct,
          vl_preco: e.target.value,
        })
      }
    />
  </Grid>
);