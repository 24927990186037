import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    cardRoot: {
      width: '20vw',
      minHeight: '20vh',
      margin: '10px'
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center'
    },
    chip: {
      margin: 2,
      backgroundColor: 'black',
      color: 'white'
    },
    button: {
      marginRight: 10,
      marginLeft: 10
    },
    paper: {
      minWidth: '70vw'
    },
    optCard: {
      width: '15vw',
      height: '8vh',
      backgroundColor: 'white',
      borderRadius: 5,
      margin: 10,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer'
    },
    optCardSelected: {
      width: '15vw',
      height: '8vh',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: 5,
      margin: 10,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer'
    },
    selectAll: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: 16,
      opacity: 0.5,
      display: 'flex',
      alignItems: 'center'
    },
    arrowButtonLeft: {
      position: 'absolute',
      zIndex: 10,
      background: '#f2f2f2',
      color: '#000',
      minWidth: 8,
      left: '0px',
      opacity: 0.4,
      '&:hover': {
        background: '#fff !important',
        color: '#000',
        opacity: 0.8
      }
    },
    arrowButtonRight: {
      position: 'absolute',
      zIndex: 10,
      background: '#f2f2f2',
      color: '#000',
      minWidth: 8,
      right: '0px',
      opacity: 0.4,
      '&:hover': {
        background: '#fff !important',
        color: '#000',
        opacity: 0.8
      }
    },
    scrollMenu: {
      marginLeft: '25px',
      position: 'absolute',
      flexWrap: 'nowrap',
      transition: 'all ease 0.5s'
    },
    text: {
      fontFamily: 'Flama',
      color: 'white',
      fontSize: 12,
      letterSpacing: '0em',
      textAlign: 'center'
    },
    chipPainel: {
      top: 0,
      height: 35,
      zIndex: 999,
      display: 'flex',
      overflow: 'hidden',
      position: 'sticky',
      alignItems: 'center'
    }
  }),
  { index: 1 }
);
