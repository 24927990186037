import React from 'react';
import { useUser } from '../hooks/useUser';
import { authGuard } from './AuthGuard';
import { DeniedRedirect } from './DeniedRedirect';

export default function Logout() {
  const { setUser } = useUser();

  if (authGuard.isAuthenticated()) {
    setUser(null);
    authGuard.logout();
  }

  return <DeniedRedirect />;
}
