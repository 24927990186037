/* eslint-disable react/prop-types */
import Modal from 'react-modal';
import React from 'react';
import {
  ModalWrapper,
  ModalBody,
  ModalHeader,
  Title,
  ModalButton,
  MessageText,
} from './style';
import { Close } from '@styled-icons/remix-line';
import { IconButton } from '@material-ui/core';

export default function Action({ isOpen, title, message, setCloseModal }) {
  const handleCloseModal = () => {
    setCloseModal(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      ariaHideApp={false}
      overlayClassName="react-modal-action-overlay"
      className="react-modal-action"
      onRequestClose={handleCloseModal}
    >
      <ModalWrapper>
        <ModalHeader>
          <Title>{title}</Title>
          <IconButton
            style={{ position: 'absolute', right: 10 }}
            onClick={() => handleCloseModal()}
          >
            <Close size={25} color={'#000'} />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <MessageText>{message}</MessageText>
          <ModalButton onClick={() => handleCloseModal()}>Entendi</ModalButton>
        </ModalBody>
      </ModalWrapper>
    </Modal>
  );
}
