import { authGuard } from './AuthGuard';

export function logoutUser() {
  if (
    !window.location.href.includes('/login') &&
    !window.location.href.includes('/logout')
  ) {
    window.location.href = `${window.location.origin}/logout`;
  } else {
    authGuard.logout();
  }
}
