import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    list: {
      minWidth: 300,
      minHeight: '100%'
    },
    header: {
      flexDirection: 'row',
      height: 50,
      position: 'sticky',
      top: 0,
      zIndex: 999,
      background: '#fff',
      maxWidth: 440
    },
    fullList: {
      minWidth: 300
    },
    text: {
      fontFamily: 'Flama medium',
      fontSize: 16
    },
    chip: {
      margin: theme.spacing(0.5),
      fontFamily: 'Flama',
      fontSize: 12
    },
    panel: {
      boxShadow: 'none',
      minWidth: 400
    },
    buttonLabel: {
      textTransform: 'capitalize'
    }
  }),
  { index: 1 }
);
