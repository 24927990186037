import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      flexGrow: 1,
      heigth: 150,
      maxWidth: 400,
      // margin: 5,
      backgroundColor: '#FFF',
      '&:hover': {
        opacity: 0.7
      }
    },
    progressLabel: {
      borderColor: '#FFF',
      borderRadius: 20,
      // position: 'absolute',
      width: 235,
      minHeight: '10px', // borderlinearprogress root.height
      textAlign: 'center',
      alignItems: 'center',
      '& span': {
        width: '10%'
      }
    },
    bar: {
      backgroundColor: '#0ADC2D'
    },
    emoji: {
      marginLeft: 40,
      fontSize: '250px'
    },
    fill: {
      fill: '#FF0000'
    },
    notFill: {
      fill: '#FFF'
    },
    opacity: {
      flexGrow: 1,
      heigth: 150,
      maxWidth: 400,
      backgroundColor: 'rgb(50,50,50,0.1)',
      opacity: 1.3,
      borderRadius: 10
    },
    cardText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      width: '100%',
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0em',
      color: '#000'
    },
    cardLabel: {
      fontFamily: 'flama medium',
      fontSize: 15,
      color: '#000',
      height: 45,
      borderRadius: 5
    },
    cardLabel2: {
      fontFamily: 'flama medium',
      fontSize: 15,
      color: '#FFF',
      height: 45,
      borderRadius: 5
    }
  }),
  { index: 1 }
);
