import React, { useState } from 'react';

import { useStyles } from "./style";

import {
  AppBar,
  Dialog,
  IconButton,
  Grid,
  List,
  Menu,
  MenuItem,
  Slide,
  Typography,
  Toolbar,
} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import PDPSwiper from '../pdpSwiper/index';
import SwipeableTemporaryDrawer from '../swipeableTemporaryDrawer/index.js';

import closeIcon from '../../../assets/icons/close-icon.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductPanel({
  open,
  setOpen,
  product,
  grouping,
  vote,
  voteId,
  voteOpts,
  saveVote,
  obs,
  preco,
  precoAlvo,
  comment,
  classification,
  classificationOpts,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  let imageHeight = parseInt(
    ((window.innerHeight - 65) / window.innerHeight) * 100
  );

  const handleClose = () => {
    setOpen(false);
  };

  function renderVote(voteOpts, vote, product, showLabel) {
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    function renderVoteMenu() {
      if (voteOpts.length === 1) {
        return null;
      } else {
        return (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {voteOpts.map(
              (vote) =>
                vote.in_escala_positiva === 1 && (
                  <MenuItem
                    key={`voteOption${vote}`}
                    className={classes.menuItem}
                    onClick={() => {
                      saveVote(
                        product,
                        vote.no_voto,
                        vote.id_voto,
                        vote.cd_cor_voto
                      );
                      handleCloseMenu();
                    }}
                  >
                    <FavoriteIcon style={{ fill: `${vote.cd_cor_voto}` }} />
                    <Typography className={classes.menuVoteOpt}>
                      {vote.no_voto}
                    </Typography>
                  </MenuItem>
                )
            )}
            {voteOpts.map(
              (vote) =>
                vote.in_escala_positiva === 0 && (
                  <MenuItem
                    key={`voteOption${vote}`}
                    className={classes.menuItem}
                    onClick={() => {
                      saveVote(
                        product,
                        vote.no_voto,
                        vote.id_voto,
                        vote.cd_cor_voto
                      );
                      handleCloseMenu();
                    }}
                  >
                    <HighlightOffIcon style={{ fill: `${vote.cd_cor_voto}` }} />
                    <Typography className={classes.menuVoteOpt}>
                      {vote.no_voto}
                    </Typography>
                  </MenuItem>
                )
            )}
            <MenuItem
              key={`voteOptionRemove`}
              style={{ width: 120, height: 40 }}
              onClick={() => {
                saveVote(product, null, null);
                handleCloseMenu();
              }}
            >
              <FavoriteBorderIcon />
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 12,
                  fontStyle: 'normal',
                  lineHeight: 14,
                  letterSpacing: '0em',
                }}
              >
                Limpar
              </Typography>
            </MenuItem>
          </Menu>
        );
      }
    }

    if (vote === null) {
      return (
        <>
          {renderVoteMenu()}
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <FavoriteBorderIcon
              key={`favIcon-${product.id_produto_estilo}`}
              className={classes.voteIcon}
            />
            {showLabel ? (
              <Typography
                className={classes.productInfo}
                style={{ width: 100 }}
              >
                NÃO VOTADO
              </Typography>
            ) : null}
          </IconButton>
        </>
      );
    }
    if (vote === 'Reprovado') {
      return (
        <>
          {renderVoteMenu()}
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <HighlightOffIcon
              key={`${product.id_produto_estilo}-highlightOff`}
              className={classes.voteIcon}
              style={{ color: 'rgb(255, 58, 58)' }}
            />
            {showLabel ? (
              <Typography className={classes.productInfo}>REPROVADO</Typography>
            ) : null}
          </IconButton>
        </>
      );
    } else {
      return voteOpts.map(
        (voteOpt) =>
          vote === voteOpt.no_voto && (
            <div
              key={`${product.id_produto_estilo}-${voteOpt.no_voto}-vote-list`}
            >
              {renderVoteMenu()}
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <FavoriteIcon
                  key={`favIcon-${product.id_produto_estilo}-${voteOpt.no_voto}`}
                  className={classes.voteIcon}
                  style={{ color: `${voteOpt.cd_cor_voto}` }}
                />
                {showLabel ? (
                  <Typography
                    className={classes.productInfo}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {vote}
                  </Typography>
                ) : null}
              </IconButton>
            </div>
          )
      );
    }
  }

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar className={classes.toolBar}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Grid container justify="center" alignItems="center">
                  <Typography className={classes.productTitle}>
                    {product.produto} - {product.ds_produto}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item>{renderVote(voteOpts, vote, product, false)}</Grid>
                  <SwipeableTemporaryDrawer
                    product={product}
                    obs={obs}
                    vote={vote}
                    preco={preco}
                    precoAlvo={precoAlvo}
                    comment={comment}
                    grouping={grouping}
                    voteOpts={voteOpts}
                    classification={classification}
                    classificationOpts={classificationOpts}
                  />
                  <IconButton onClick={handleClose} aria-label="close">
                    <img
                      src={closeIcon}
                      alt="close icon"
                      style={{ height: '16px' }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <List style={{ padding: 0, maxHeight: `${imageHeight}%` }}>
          <PDPSwiper
            product={product}
            images={
              !product.imagem && product.nome_arquivo_principal
                ? product.nome_arquivo_principal
                : product.imagem
            }
            grouping={grouping}
            preco={preco}
            precoAlvo={precoAlvo}
            comment={comment}
            obs={obs}
            classification={classification}
            classificationOpts={classificationOpts}
            vote={vote}
            voteOpts={voteOpts}
            renderVote={renderVote(voteOpts, vote, product, false)}
          />
        </List>
      </Dialog>
    </div>
  );
}
