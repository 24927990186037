import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      minWidth: '100vw',
      minHeight: 'calc(100vh - 48px)',
      maxHeight: 'calc(100vh - 48px)',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden'
    },
    painel: {
      background: '#FFFFFF',
      minHeight: '48px',
      maxHeight: '48px',
      position: 'sticky',
      zIndex: 1,
      paddingLeft: 18,
      paddingRight: 18
    },
    icon: {
      color: '#333333',
      cursor: 'pointer',
      fontSize: 16
    },
    gridContainer: {
      display: 'flex',
      padding: '0px 10px'
    },
    title: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 700,
      letterApacing: '0em',
      textAlign: 'center'
    },
    titleLabel: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0em',
      opacity: 0.3
    },
    titleLabelContainer: {
      margin: '15px'
    },
    subTitleLabel: {
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: '0em',
      textTransform: 'uppercase'
    },
    groupSummaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FAFAFA',
      height: 180,
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.1)'
    },
    groupSummaryTotal: {
      fontFamily: 'Roboto',
      fontSize: 40,
      fontWeight: 300,
      letterSpacing: '0em'
    },
    groupSummaryTitle: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: '0em',
      textAlign: 'center'
    },
    groupSummaryVotes: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '0em',
      textAlign: 'center'
    },
    groupSummaryPrices: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0em',
      textAlign: 'center'
    },
    columnHeaderTitle: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: '0em'
    },
    columnHeaderContainer: {
      marginTop: 40
    },
    skeleton: {
      borderRadius: '25px'
    },
    list: {
      padding: 0,
      margin: '8px'
    },
    menuItem: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '0em',
      cursor: 'pointer'
    },
    menuLoadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '15px 0px'
    },
    chartSkeletonContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    arrowButtonLeft: {
      position: 'absolute',
      zIndex: 10,
      background: '#fff',
      color: '#000',
      minWidth: 8,
      left: '0px',
      opacity: 0.4,
      '&:hover': {
        background: '#f2f2f2 !important',
        color: '#000',
        opacity: 0.8
      }
    },
    arrowButtonRight: {
      position: 'absolute',
      zIndex: 10,
      background: '#fff',
      color: '#000',
      minWidth: 8,
      right: '0px',
      opacity: 0.4,
      '&:hover': {
        background: '#f2f2f2 !important',
        color: '#000',
        opacity: 0.8
      }
    },
    chipPainel: {
      background: '#FFF',
      height: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 999,
      padding: 5,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center'
    },
    scrollMenu: {
      marginLeft: '25px',
      position: 'absolute',
      flexWrap: 'nowrap',
      transition: 'all ease 0.5s'
    },
    text: {
      fontFamily: 'Flama',
      fontSize: 12,
      letterSpacing: '0em',
      textAlign: 'center'
    },
    numberText: {
      marginLeft: 5,
      color: '#000',
      fontSize: 10
    },
    headerBtn: {
      alignItems: 'center',
      display: 'flex',
      width: 'auto',
      margin: 15,
      cursor: 'pointer'
    },
    headerBtnLabel: {
      letterSpacing: '0em',
      fontFamily: 'Roboto',
      textAlign: 'left',
      fontWeight: 400,
      fontSize: 11,
      opacity: 0.5
    }
  }),
  { index: 1 }
);
