import React, { useState } from 'react';

import { formatPrice } from '../../../actions/utils';
import { useUser } from '../../../hooks/useUser';
import {
  isDuplicatedProduct,
  isMovedProduct,
  isCreatedProduct,
  getStringClassification
} from '../../../utils/productProperties.js';

import { useStyles } from './style';

import {
  Divider,
  IconButton,
  Grid,
  SwipeableDrawer,
  Typography,
  Tooltip
} from '@material-ui/core';

import infoIcon from '../../../assets/icons/info-icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function SwipeableTemporaryDrawer({
  product,
  obs,
  comment,
  grouping,
  voteOpts,
  vote,
  preco,
  precoAlvo,
  classification,
  classificationOpts
}) {
  const { isUserFromFarmBR, isUserFromAnimaleBR } = useUser();
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenInfo(open);
  };

  function renderVote(voteOpts, vote, product) {
    if (vote === null) {
      return (
        <Grid container alignItems="center">
          <FavoriteBorderIcon
            key={`favIcon-${product.id_produto_estilo}`}
            className={classes.voteIcon}
          />
          <Typography
            className={classes.productInfo}
            style={{ width: 100, marginLeft: 10 }}
          >
            NÃO VOTADO
          </Typography>
        </Grid>
      );
    }
    if (vote === 'Reprovado') {
      return (
        <Grid container alignItems="center">
          <HighlightOffIcon
            key={`${product.id_produto_estilo}-highlightOff`}
            className={classes.voteIcon}
            style={{ color: 'rgb(255, 58, 58)' }}
          />
          <Typography
            className={classes.productInfo}
            style={{ marginLeft: 10 }}
          >
            REPROVADO
          </Typography>
        </Grid>
      );
    } else {
      return voteOpts.map(
        (voteOpt) =>
          vote === voteOpt.no_voto && (
            <Grid container alignItems="center">
              <FavoriteIcon
                key={`favIcon-${product.id_produto_estilo}-${voteOpt.no_voto}`}
                className={classes.voteIcon}
                style={{ color: `${voteOpt.cd_cor_voto}` }}
              />
              <Typography
                className={classes.productInfo}
                style={{ textTransform: 'uppercase', marginLeft: 10 }}
              >
                {vote}
              </Typography>
            </Grid>
          )
      );
    }
  }

  function productHistory(product, grouping) {
    function originalProductParams() {
      return (
        <div style={{ marginTop: 12 }}>
          <Typography
            className={classes.productInfoHistory}
            style={{ fontWeight: 'bold' }}
          >
            ORIGINAL:
          </Typography>
          {product.grupo_atelier_original !== product.grupo_atelier && (
            <Typography className={classes.productInfoHistory}>
              Grupo: {product.grupo_atelier_original}
            </Typography>
          )}
          {product.linha_original !== product.linha && (
            <Typography className={classes.productInfoHistory}>
              Linha: {product.linha_original}
            </Typography>
          )}
          {grouping === 'Coordenados'
            ? product.desc_estampa_cor_original !== product.coordenado && (
                <Typography className={classes.productInfoHistory}>
                  Cor: {product.desc_estampa_cor_original}
                </Typography>
              )
            : product.desc_estampa_cor_original !== product.estampa && (
                <Typography className={classes.productInfoHistory}>
                  {isUserFromFarmBR() ? 'Coordenado:' : 'Cor:'}{' '}
                  {product.desc_estampa_cor_original}
                </Typography>
              )}
          {!!product.preco && product.preco_original !== product.preco && (
            <Typography className={classes.productInfoHistory}>
              Preço: R$
              {product.preco_original
                ? (product.preco_original / 100).toFixed(2).replace('.', ',')
                : '0,00'}
            </Typography>
          )}
          {product.no_arara &&
            product.no_arara_original !== product.no_arara && (
              <Typography className={classes.productInfoHistory}>
                Arara: {product.no_arara_original}
              </Typography>
            )}
          {product.no_tipo_farm &&
            product.no_tipo_farm_original !== product.no_tipo_farm && (
              <Typography className={classes.productInfoHistory}>
                Tipo Farm: {product.no_tipo_farm_original}
              </Typography>
            )}
        </div>
      );
    }

    if (isDuplicatedProduct(product)) {
      return (
        <>
          <Divider className={classes.divider} />
          <Grid>
            <Grid item xs={12}>
              <button
                style={{
                  minWidth: 30,
                  width: 100,
                  height: 30,
                  borderRadius: 100,
                  fontFamily: 'Flama',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 12,
                  border: '1px solid rgba(24, 24, 24, 0.1)',
                  color: 'black',
                  backgroundColor: 'white'
                }}
              >
                Duplicado
              </button>
            </Grid>
            {originalProductParams()}
          </Grid>
        </>
      );
    }

    if (isMovedProduct(product)) {
      return (
        <>
          <Divider className={classes.divider} />
          <Grid>
            <Grid item xs={12}>
              <button
                style={{
                  minWidth: 30,
                  width: 100,
                  height: 30,
                  borderRadius: 100,
                  fontFamily: 'Flama',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 12,
                  border: '1px solid rgba(24, 24, 24, 0.1)',
                  color: 'black',
                  backgroundColor: 'white'
                }}
              >
                Movimentado
              </button>
            </Grid>
            {originalProductParams()}
          </Grid>
        </>
      );
    }
    if (isCreatedProduct(product)) {
      return (
        <>
          <Divider className={classes.divider} />
          <Grid>
            <Grid item xs={12}>
              <button
                style={{
                  minWidth: 30,
                  width: 100,
                  height: 30,
                  borderRadius: 100,
                  fontFamily: 'Flama',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 12,
                  border: '1px solid rgba(24, 24, 24, 0.1)',
                  color: 'black',
                  backgroundColor: 'white'
                }}
              >
                Criado
              </button>
            </Grid>
          </Grid>
        </>
      );
    }
  }

  function productEditableParams(product) {
    return (
      <>
        <Divider className={classes.divider} />

        <Grid item xs={12} className={classes.infoGridContainer}>
          <Typography className={classes.productInfoLabel}>
            {isUserFromFarmBR() ? 'ATRIBUTO:' : 'CLASSIFICAÇÃO:'}
          </Typography>
          <Typography className={classes.productInfo}>
            {classificationOpts
              ? classification.id_classificacao.length === 1 &&
                classification.id_classificacao[0] === 0
                ? isUserFromFarmBR()
                  ? 'Nenhum'
                  : 'Nenhuma'
                : getStringClassification(classification, classificationOpts)
              : isUserFromFarmBR()
              ? 'Nenhum'
              : 'Nenhuma'}
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid item xs={12} className={classes.infoGridContainer}>
          <Typography className={classes.productInfoLabel}>
            COMENTÁRIO:
          </Typography>
          <Typography className={classes.productInfo}>
            {comment ? comment : 'Nenhum Comentário'}
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid
          item
          xs={12}
          className={classes.infoGridContainer}
          style={{ marginBottom: 30 }}
        >
          <Typography className={classes.productInfoLabel}>
            OBSERVAÇÃO:
          </Typography>
          <Typography className={classes.productInfo}>
            {obs ? obs : 'Nenhuma Observação'}
          </Typography>
        </Grid>
      </>
    );
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Grid container justify="flex-end">
        <IconButton
          style={{ margin: '8px 0px' }}
          onClick={toggleDrawer(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container style={{ padding: '0px 12px' }}>
        <Grid item xs={12}>
          <Typography className={classes.productInfo} style={{ fontSize: 22 }}>
            {product.produto} - {product.ds_produto}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.infoGridContainer}>
          {grouping === 'Coordenados' ? (
            <Typography className={classes.productInfo}>
              {product.estampa_cor} - {product.coordenado}
            </Typography>
          ) : (
            <Typography className={classes.productInfo}>
              {product.estampa_cor} - {product.estampa}
            </Typography>
          )}
          <Typography className={classes.productInfo}>
            PV: {formatPrice(product.preco)} / CT:{' '}
            {formatPrice(product.custo_total)}
            {product.custo_total && product.preco
              ? ` / MKP: ${(product.preco / product.custo_total)
                  .toFixed(2)
                  .replace('.', ',')}`
              : null}
          </Typography>
          <Typography className={classes.productInfo}>
            {preco ? ` PP: ${formatPrice(preco)}` : null}
            {product.custo_total && preco
              ? ` / NOVO MKP: ${(preco / product.custo_total)
                  .toFixed(2)
                  .replace('.', ',')}`
              : null}
          </Typography>
          <Typography className={classes.productInfo}>
            {precoAlvo ? `PA: ${formatPrice(precoAlvo)}` : null}
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid item xs={12} className={classes.infoGridContainer}>
          {renderVote(voteOpts, vote, product, true)}
        </Grid>

        <Divider className={classes.divider} />

        <Grid item xs={12} className={classes.infoGridContainer}>
          <Typography className={classes.productInfoLabel}>
            ESTILISTA:
          </Typography>
          <Typography className={classes.productInfo}>
            {product.estilista}
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid item xs={12} className={classes.infoGridContainer}>
          <Typography className={classes.productInfo}>
            LINHA: {product.linha}
          </Typography>

          <Typography
            className={classes.productInfo}
            style={{ margin: '5px 0px' }}
          >
            FORNECEDOR: {product.fornecedor}
          </Typography>

          {product.no_arara && isUserFromAnimaleBR() && (
            <Typography
              className={classes.productInfo}
              style={{ margin: '5px 0px' }}
            >
              ARARA: {product.no_arara}
            </Typography>
          )}

          <Grid container>
            <Typography className={classes.infoLabel}>MP:</Typography>
            <Tooltip
              title={product.material.split('-;-').map((material) => (
                <h3 className={classes.infoLabel}>{material}</h3>
              ))}
            >
              <InfoOutlinedIcon className={classes.MPIcon} />
            </Tooltip>
          </Grid>
        </Grid>

        {productHistory(product, grouping)}

        {productEditableParams()}
      </Grid>
    </div>
  );

  return (
    <div>
      <>
        <IconButton onClick={toggleDrawer(true)}>
          <img src={infoIcon} alt="info icon" style={{ height: 19 }} />
        </IconButton>
        <SwipeableDrawer
          anchor={'right'}
          open={openInfo}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </>
    </div>
  );
}
