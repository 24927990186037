import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { useStyles } from './style';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';

import { IconButton } from '@material-ui/core';

function SwipeableCarrousel({
  id,
  photos = [],
  cardSize,
  redirect,
  history,
  stepper,
  zoom,
  setOpen
}) {
  const ref = useRef();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const renderImg = (img, index) => {
    return (
      <img
        key={`img-${id}-${index}-${img}`}
        alt={`${img}`}
        ref={ref}
        loading="lazy"
        className={
          cardSize.sizeXs === 'Aprovacao'
            ? classes.aprove
            : cardSize.sizeXs === 'Duplicate'
            ? classes.duplicate
            : cardSize.sizeXs
            ? classes.mediaCardMin
            : classes.mediaCardMax
        }
        style={{ height: zoom * 390, width: zoom * 260 }}
        src={img?.replace(/ /g, '%20')}
        crossOrigin="anonymous"
        title="Produto"
      />
    );
  };

  const handleNextImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveStep(activeStep + 1);
  };

  const handlePreviousImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveStep(activeStep - 1);
  };

  const renderStepper = () => (
    <MobileStepper
      key={`stepper-${id}`}
      variant="dots"
      steps={photos.length}
      classes={{ dot: classes.dot, dotActive: classes.dotActive }}
      className={classes.stepper}
      style={{ height: zoom * 32 }}
      position="static"
      activeStep={activeStep}
      nextButton={
        <IconButton
          onClick={handleNextImage}
          disabled={activeStep + 1 >= photos.length}
          className={classes.sliderBtn}
        >
          <KeyboardArrowRight />
        </IconButton>
      }
      backButton={
        <IconButton
          onClick={handlePreviousImage}
          disabled={activeStep === 0}
          className={classes.sliderBtn}
        >
          <KeyboardArrowLeft />
        </IconButton>
      }
    />
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <SwipeableViews
        className={classes.root}
        style={{ margin: 0, padding: 0, border: 0 }}
        index={activeStep}
        enableMouseEvents
        onChangeIndex={(index) => setActiveStep(index)}
        onClick={
          cardSize.sizeXs === 'Aprovacao' ? () => handleClickOpen() : null
        }
      >
        {photos.map((img, index) => {
          return (
            <Fragment key={`frag-${id}-${img}-${index}`}>
              {renderImg(img, index)}
            </Fragment>
          );
        })}
      </SwipeableViews>
      {stepper && photos.length > 0 && renderStepper()}
    </>
  );
}
const wrapperComponent = SwipeableCarrousel;
SwipeableCarrousel.propTypes = {
  photos: PropTypes.array.isRequired,
  Component: PropTypes.func,
  stepper: PropTypes.bool
};

export default wrapperComponent;
