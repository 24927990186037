import { getResizedImage } from "./consts";
import noImage2 from "./assets/img/noImage2.png";

const UTILS = {
  formatToMaxCaractersAllowed(text, max = 13) {
    if (!text) return "texto não inserido";
    if (text.length > max) {
      return `${text.substring(0, max)}...`;
    } else {
      return text;
    }
  },

  getImage(image, width = 400) {
    if (image) {
      return getResizedImage(image, width);
    }

    return noImage2;
  },
};
export default UTILS;
