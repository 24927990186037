import ANIMALE from '../../../assets/img/ANIMALE.jpg';
import ANIMALEJEANS from '../../../assets/img/ANIMALEJEANS.jpg';
import CRISBARROS from '../../../assets/img/CRISBARROS.jpg';
import HERING from '../../../assets/img/HERING.jpg';
import FABULA from '../../../assets/img/FABULA.jpg';
import FARM from '../../../assets/img/FARM.jpg';
import FARMUSA from '../../../assets/img/FARMUSA.jpg';
import FOXTON from '../../../assets/img/FOXTON.jpg';
import MARIAFILO from '../../../assets/img/MARIAFILO.jpg';
import NV from '../../../assets/img/NV.jpg';

import { useUser } from '../../../hooks/useUser';

export default function HomeBackgroundImage() {
  const {
    isUserFromAnimaleBR,
    isUserFromAnimaleJeans,
    isUserFromCrisBarros,
    isUserFromHering,
    isUserFromFabula,
    isUserFromFarmBR,
    isUserFromFarmUsa,
    isUserFromFoxton,
    isUserFromMariaFilo,
    isUserFromNV
  } = useUser();

  if (isUserFromAnimaleBR()) {
    return ANIMALE;
  }
  if (isUserFromAnimaleJeans()) {
    return ANIMALEJEANS;
  }
  if (isUserFromCrisBarros()) {
    return CRISBARROS;
  }
  if (isUserFromFarmBR()) {
    return FARM;
  }
  if (isUserFromFarmUsa()) {
    return FARMUSA;
  }
  if (isUserFromFoxton()) {
    return FOXTON;
  }
  if (isUserFromMariaFilo()) {
    return MARIAFILO;
  }
  if (isUserFromFabula()) {
    return FABULA;
  }
  if (isUserFromNV()) {
    return NV;
  }
  if (isUserFromHering()) {
    return HERING;
  } else {
    return FARM;
  }
}
