import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      minWidth: '100vw',
      minHeight: 'calc(100vh - 48px)',
      maxHeight: 'calc(100vh - 48px)',
      backgroundColor: 'rgba(242, 242, 242, 1)'
    },
    title: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.1em',
      textAlign: 'center'
    },
    whiteButton: {
      sizeSmall: '100px'
    },
    painel: {
      background: '#FFFFFF',
      minHeight: '48px',
      maxHeight: '48px',
      position: 'sticky',
      zIndex: 1,
      paddingLeft: 18,
      paddingRight: 18
    },
    icon: {
      color: '#333333',
      cursor: 'pointer',
      fontSize: 16
    },
    chip: {
      margin: 8,
      cursor: 'pointer',
      minHeight: 36,
      borderRadius: '20px',
      textTransform: 'none',
      backgroundColor: 'rgba(242, 242, 242, 1)',
      border: '1px solid rgba(24, 24, 24, 0.1)'
    },
    selectedChip: {
      margin: 8,
      cursor: 'pointer',
      minHeight: 36,
      borderRadius: '20px',
      textTransform: 'none',
      backgroundColor: 'rgba(0, 0, 0)',
      border: '1px solid rgba(24, 24, 24, 0.1)',
      color: 'white !important',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0)',
        opacity: 0.9
      }
    },
    chipText: {
      fontFamily: 'Flama',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center'
    },
    gridContainer: {
      marginTop: 12,
      display: 'flex',
      justifyContent: 'center'
    },
    voteBtn: {
      width: 80,
      height: 36,
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      borderRadius: 5,
      letterSpacing: '0em'
    },
    infoLabel: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: 16,
      opacity: 0.5,
      display: 'flex',
      alignItems: 'center'
    }
  }),
  { index: 1 }
);
