import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    title: {
      fontFamily: 'Flama',
      fontSize: 48,
      letterSpacing: '0em'
    },
    menuCardRoot: {
      flexGrow: 1
    },
    card: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '100%',
      borderTop: '1px solid rgba(0,0,0,0.1)'
    },
    fontWhite: {
      textTransform: 'uppercase',
      fontFamily: 'Flama',
      fontSize: 32,
      letterSpacing: '0.05em'
    },
    hover: {
      padding: 10,
      borderRadius: 10,
      '&:hover': {
        background: 'rgba(211, 211, 211, 0.4)',
        cursor: 'pointer',
        transition: '0.2s'
      }
    }
  }),
  { index: 1 }
);
