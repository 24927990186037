import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';

import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

import pdfIcon from '../../../assets/icons/pdf-icon.svg';

import { getMatrixPDF } from '../../../services/matrixService';

function GetPDF(props) {
  const [filterOptions, setFilterOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [openPDFTooltip, setOpenPDFTooltip] = useState(false);

  const handleClick = (event) => {
    setOpenPDFTooltip(false);
    setAnchor(event.currentTarget);
    setShowMenu((s) => !s);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  async function getPDF(template) {
    setLoading(true);
    try {
      props.enqueueSnackbar('Aguarde enquanto geramos o documento', {
        variant: 'info',
      });
      let response = await getMatrixPDF({
        query: {
          agrupamento: props.products.query.agrupamento,
          filter: {
            XAxis: props.matrixXAxis.label,
            YAxis: props.filterData.filters.YAxis.label,
            ...props.products.query.filter,
          },
        },
        labels: filterOptions,
        template: template,
      });
      const url = window.URL.createObjectURL(response.data);
      window.open(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return props.enqueueSnackbar(
        'O PDF esta demorando demais, tente novamente ou nos informe',
        { variant: 'error' }
      );
    }
  }

  useEffect(() => {
    addDetachedFilters(props.filterData.filtersLabels);
    //eslint-disable-next-line
  }, [props.filterData.filtersLabels]);

  const addDetachedFilters = (filters) => {
    let arrayOptions = [];
    filters.forEach((val, i) => {
      arrayOptions.push({ key: val.id, filter: val.label, filtro: val.filtro });
    });
    setFilterOptions(arrayOptions);
  };

  return (
    <div>
      {loading ? (
        <IconButton variant="outlined" color="primary" disabled>
          <CircularProgress
            size={20}
            style={{ color: 'black', marginTop: -1 }}
          />
        </IconButton>
      ) : (
        <Tooltip
          open={openPDFTooltip}
          key={'Get PDF'}
          title={`O PDF É Gerado Conforme As Colunas Da Matriz - ${props.matrixXAxis.label}`}
        >
          <IconButton
            variant="outlined"
            color="primary"
            onClick={handleClick}
            onMouseEnter={() => setOpenPDFTooltip(true)}
            onMouseLeave={() => setOpenPDFTooltip(false)}
            style={{ marginTop: -1 }}
          >
            <img src={pdfIcon} alt="generate pdf" style={{ width: '17px' }} />
            <Menu
              id="simple-menu"
              anchorEl={anchor}
              open={showMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={() => getPDF('complete')}>Completo</MenuItem>
              <MenuItem onClick={() => getPDF('simple')}>Simplificado</MenuItem>
            </Menu>
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
export default withSnackbar(GetPDF);
