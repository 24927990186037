import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
`;

export const Title = styled.h1`
  font-size: 45px;
  text-align: left;
  text-transform: uppercase;
  margin: 10px 0px;
`;

export const LineBottom = styled.div`
  height: 1px;
  width: 250px;
  background-color: #000;
`;
