import React, { createContext, useContext, useState } from "react";

const FiltersContext = createContext();

const useContextFilters = () => {
  return useContext(FiltersContext);
};

const FiltersContextProvider = (props) => {
  const [filtersLabels, setFiltersLabels] = useState([]);

  const addFilterLabel = (filterWithLabel) => {
    if (!filterWithLabel.value || !filterWithLabel.label) {
      removeFilterLabel(filterWithLabel);
      return;
    }
    let newFilter = filtersLabels;
    if (filterWithLabel.filtroAux === "precoInicial") {
      newFilter = filtersLabels.filter((el) => el.filtroAux !== "precoInicial");
    }
    if (filterWithLabel.filtroAux === "precoLimite") {
      newFilter = filtersLabels.filter((el) => el.filtroAux !== "precoLimite");
    }

    let r = newFilter.find((el) => el.id === filterWithLabel.id);
    if (!r) {
      newFilter.push(filterWithLabel);
      setFiltersLabels(newFilter.map((e) => e));
    } else {
      if (filterWithLabel.filtro !== "selecteds") {
        removeFilterLabel(filterWithLabel);
      }
    }
  };

  const removeFilterLabel = (filterWithLabel) => {
    let filter = filtersLabels.filter((el) => el.id !== filterWithLabel.id);
    setFiltersLabels(filter.map((e) => e));
  };

  const cleanFilterLabels = () => {
    let filter = filtersLabels.filter((el) => el.filtro === "selecteds");
    setFiltersLabels(filter);
  };

  return (
    <FiltersContext.Provider
      value={{
        filtersLabels,
        setFiltersLabels,
        addFilterLabel,
        cleanFilterLabels,
        removeFilterLabel,
      }}
    >
      {props.children}
    </FiltersContext.Provider>
  );
};

export { FiltersContextProvider };

export default useContextFilters;
