import axios from 'axios';
import { BASE_URL, API_ACCESS_CONTROL_URL } from '../consts';
import { authGuard } from '../guard/AuthGuard';
import { MaisControleServices } from './maisControle';
import { logoutUser } from '../guard/refreshToken';
import Cookies from 'universal-cookie';

export class AxiosServer {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
    this.cookies = new Cookies();
    this.server = this.initServer();
  }

  getAuthorizationHeader = () => {
    const tokenJson = this.cookies.get(authGuard.getCookieTokenNameByAmbient());
    if (tokenJson) {
      const serializedToken = tokenJson.token;
      return { Authorization: `Bearer ${serializedToken}` };
    }
  };

  initServer() {
    return axios.create({
      baseURL: this.apiUrl,
      headers: {
        client_access: 'mais-aprovacao',
        'Content-Type': 'application/json',
        ...this.getAuthorizationHeader()
      }
    });
  }

  reloadServer() {
    this.server = this.initServer();
  }
}

export const setInterceptor = (authServer) => {
  authServer.interceptors.request.use(async (request) => {
    const tokenBody = authGuard.getLocalToken();
    if (tokenBody) {
      request.headers.Authorization = `Bearer ${tokenBody.token}`;
    }

    return request;
  });

  authServer.interceptors.response.use(
    (response) => {
      if (response) {
        return response;
      }
    },
    async function (error) {
      const prevRequest = error.config;

      if (error?.response?.status === 401 && !prevRequest._retry) {
        prevRequest._retry = true;
        return logoutUser();
      }
      if (error?.response?.status === 403 && !prevRequest._retry) {
        prevRequest._retry = true;
        if (error.response?.data.message === 'permission-required') {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }

        if (error.response?.data.message === 'no-permission') {
          if (!window.location.href.includes('no-authorized')) {
            window.location.href = `${window.location.origin}/no-authorized`;
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export const pagination = (page = 1, size = 20) => ({
  pageStart: page,
  pageSize: size
});

export let CancelToken = axios.CancelToken;

export let isCancel = (err) => axios.isCancel(err);

export let maisAprovacaoApi = new AxiosServer(BASE_URL);

export let maisAprovacaoService = maisAprovacaoApi.server;
setInterceptor(maisAprovacaoService);

export let maisControleApi = new AxiosServer(API_ACCESS_CONTROL_URL);

export let maisControleService = new MaisControleServices(
  maisControleApi.server
);

export const reloadServices = () => {
  maisAprovacaoApi = new AxiosServer(BASE_URL);
  maisAprovacaoService = maisAprovacaoApi.server;
  setInterceptor(maisAprovacaoService);

  maisControleApi = new AxiosServer(API_ACCESS_CONTROL_URL);
  CancelToken = axios.CancelToken;
  maisControleService = new MaisControleServices(maisControleApi.server);
};
