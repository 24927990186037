import React from 'react';
import { useStyles } from './style';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListIcon from '../../components/listIcon/index';
import { useRoutes } from '../../../hooks/useRoutes';
import { useUser } from '../../../hooks/useUser';
import { Container } from '@material-ui/core';

function ResponsiveNavbar(props) {
  const { user } = useUser();
  const { routes } = useRoutes();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  // eslint-disable-next-line no-undef
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
    props.openMenu();
  };

  const menuIcons = [
    // {
    //   name: 'shopping_cart',
    //   text: 'Meus Produtos',
    //   action: () => redirect(ROUTES.MYREPORTS),
    //   show: user.canAcessThisRoute(ROUTES.MYREPORTS),
    //   route: ROUTES.MYREPORTS
    // },
    // {
    //   name: 'date_range',
    //   text: 'Recebimento',
    //   action: () => routes.search(),
    //   show: true,
    //   route: '/search'
    // },

    // {
    //   name: 'local_mall',
    //   text: 'Coleções',
    //   action: () => routes.colecoes(),
    //   show: true,
    //   route: '/search'
    // },
    {
      name: 'exit_to_app',
      text: 'Logout',
      action: () => {
        routes.logout();
      },
      show: true
    }
  ];

  const handleClick = (icon) => {
    icon.action();
  };

  const renderUserInfo = () => {
    return (
      <Container>
        <Grid
          container
          flex="column"
          className={classes.userInfoContainer}
          style={{ justifyContent: 'center', width: '100%' }}
        >
          <AccountCircle
            fontSize="large"
            color="primary"
            style={{
              justifyContent: 'center',
              width: '100%',
              fontSize: '4rem'
            }}
          />
          <Typography
            align="justify"
            gutterBottom
            variant="h6"
            component="h2"
            className={classes.login}
          >
            {user?.no_login}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.email}
          >
            {user?.email}
          </Typography>
        </Grid>
      </Container>
    );
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, true)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <br />
      {renderUserInfo()}
      <Divider />
      <br />
      <ListIcon
        icons={menuIcons.filter((icon) => icon.show)}
        onClick={handleClick}
        showText={props.open}
        showSelected
        initialIndexSelect={-1}
      />
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={props.open}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
    </div>
  );
}
export default ResponsiveNavbar;
