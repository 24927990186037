/* eslint-disable react/prop-types */
import React, { useState, createContext, useEffect, useCallback } from 'react';
import jwt from 'jwt-decode';
import { maisControleService, reloadServices } from '../services/axiosServer';
import Cookies from 'universal-cookie';
import { authGuard } from '../guard/AuthGuard';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [isUserInvalid, setIsUserInvalid] = useState(false);
  const [rolesFetched, setRolesFetched] = useState(false);
  
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      localStorage.removeItem('roles');
      setUser(JSON.parse(userData));
      reloadServices();
    }
  }, []);
 

  const handleUserRoles = useCallback(
    async (fromLogin) => {
      if (
        !window.location.href.includes('logout') &&
        (!window.location.href.includes('login') || fromLogin)
      ) {
        const tokenCoded = cookies.get(authGuard.getCookieTokenNameByAmbient());

        if (tokenCoded && !rolesFetched) {
          const { id_usuario_soma_auth: idSomaAuth } = tokenCoded;

          if (!fromLogin) {
            reloadServices();
          }

          const userRolesToken = await maisControleService.getUserPermissionsTokenBySystem(
            idSomaAuth
          );
          const userDecoded = jwt(userRolesToken);
          localStorage.setItem('user', JSON.stringify(userDecoded));

          setUser(userDecoded);
          setRolesFetched(true);
          if (
            (!userDecoded || userDecoded.roles.length < 1) &&
            !window.location.href.includes('no-authorized')
          ) {
            window.location.href = `${window.location.origin}/no-authorized`;
          }
        }
      }
    },
    // eslint-disable-next-line
    [rolesFetched]
  );

  useEffect(() => {
    handleUserRoles();
  }, [handleUserRoles]);
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loading,
        setLoading,
        isUserInvalid,
        setIsUserInvalid,
        handleUserRoles,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};