import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  {
    list: {
      width: 250
    },
    fullList: {
      width: 'auto'
    },
    login: {
      fontFamily: 'Flama medium'
    }
  },
  { index: 1 }
);
