import React, { useEffect, useCallback } from 'react';
import LoginButton from '../../components/components/LoginButton';
import { useUser } from '../../hooks/useUser';
import { useLocation, useParams } from 'react-router-dom';
import { useRoutes } from '../../hooks/useRoutes';
import { authGuard } from '../../guard/AuthGuard';
import { LoginWrapper, Title, WrapperTitle, LineBottom } from './style';
import { CircularProgress } from '@material-ui/core';
export default function Login() {
  const { code } = useParams();
  const { handleLogin, loading } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userCode = searchParams.get('code');
  const { routes } = useRoutes();

  useEffect(() => {
    if (userCode) {
      handleLogin(userCode);
    }
    // eslint-disable-next-line
  }, [searchParams, handleLogin, userCode]);

  // if (localStorage.getItem('loggingLoading')) {
  //   setLoading(false);
  //   localStorage.removeItem('loggingLoading');
  // }

  const redirectToHome = useCallback(() => {
    if (
      authGuard.isAuthenticated() &&
      authGuard.isAuthorized() &&
      !authGuard.isRedirectPage()
    ) {
      return routes.home();
    }
  }, [routes]);

  useEffect(() => {
    redirectToHome();
  }, [redirectToHome]);

  return (
    <LoginWrapper>
      <WrapperTitle>
        <Title>Login</Title>
        <LineBottom />
      </WrapperTitle>
      {loading ? (
        <CircularProgress style={{ color: '#000' }} />
      ) : (
        <>
          <LoginButton handleClick={() => handleLogin(code)} />
        </>
      )}
    </LoginWrapper>
  );
}
