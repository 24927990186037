import styled from 'styled-components';
export const Title = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  gap: 15px;
  width: 350px;
  height: 76px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  color: #1f1f1f;
  transition: border 0.2s;
  :hover {
    border: 1px solid #afafaf;
  }
`;