import api from "./api";

export const duplicateProduct = async (data = {}) => {
  return api()
    .post(`/v0/duplicated-product/duplicate`, data)
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      throw err;
    });
};

export const moveProduct = async (data = {}) => {
  return api()
    .post(`/v0/duplicated-product/move`, data)
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      throw err;
    });
};

export const createProduct = async (data = {}) => {
  return api()
    .post(`/v0/duplicated-product/create`, data)
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteProduct = async (id_produto_duplicado, id_tipo_produto_duplicado) => {
  return api()
    .delete(`/v0/duplicated-product/${id_produto_duplicado}/type/${id_tipo_produto_duplicado}`)
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      throw err;
    });
};