import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    icon: {
      padding: theme.spacing(1)
    },
    listItem: {
      minWidth: 0
    }
  }),
  { index: 1 }
);
