import React, { useState, useEffect } from "react";
import { useStyles } from './style';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Pagination from "@material-ui/lab/Pagination";

import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TotalCard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState({});
  const [rowsPerPage] = useState(25);
  const [translateX, setTranslateX] = useState(0);
  const [toolsWidth, setToolsWidth] = useState(0);

  const minPass = 300;

  useEffect(() => {
    setToolsWidth(225 * props.filterOptions.length)
  }, [props.filterOptions.length])

  const filterSearchOptions = createFilterOptions({
    limit: 200,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCurrentPage = (filter) => {
    return currentPage[filter] || 1;
  };

  const indexOfLastOptions = getCurrentPage(props.type) * rowsPerPage;
  const indexOfFirstOptions = indexOfLastOptions - rowsPerPage;
  const currentOptions = props.option.slice(indexOfFirstOptions, indexOfLastOptions);

  const OptionsNumbers = [];
  let totalOptions = props.option.length;
  for (let i = 1; i <= Math.ceil(totalOptions / rowsPerPage); i++) {
    OptionsNumbers.push(i);
  }

  const includes = (key, id) => {
    const newObj = props.customFilter[key];
    if (newObj && Object.prototype.hasOwnProperty.call(newObj, id)) {
      return true
    } else {
      return false
    }
  };

  const handlePage = (filter, newPage) => {
    setCurrentPage({
      ...currentPage,
      [filter]: newPage,
    });
  };

  function SelectAll() {
    if (props.option && props.option.length === Object.keys(props.customFilter[props.type]).length) {
      return props.changeCustomFilter(props.type, "Limpar", "Limpar")
    }
    props.option.forEach(opt => {
      if (!includes(props.type, opt[props.value])) {
        props.changeCustomFilter(props.type, opt[props.value], opt[props.label])
      }
    })
  }

  function checkedSelectAll() {
    if (props.option && props.option.length === Object.keys(props.customFilter[props.type]).length) {
      return true
    } else {
      return false
    }
  }

  function handleBtnActionLeft() {
    let offset = translateX + minPass;
    if (offset > 0) {
      offset = 0;
    }
    setTranslateX(offset);
  }

  function handleBtnActionRight() {
    let offset = translateX - minPass;
    if (toolsWidth < (0.2 * window.innerWidth + 300)) return false
    else {
      if (Math.abs(offset) < window.innerWidth) {
        setTranslateX(offset);
      } else {
        setTranslateX(0);
      }
    }
  }

  function getLabelFilter(text) {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Typography variant="subtitle2" className={classes.text}>
          {text}  X
        </Typography>
      </Grid>
    );
  }

  function renderFilterOptions() {
    if (props.filterOptions.length > 0) {
      return props.filterOptions.map((opt, i) => { //eslint-disable-line
        if (opt.filtro !== "selecteds" && opt.filtro === props.type) {
          return (
            <Chip
              key={`${i}-filterOptions`}
              className={classes.chip}
              onClick={() => props.changeCustomFilter(opt.filtro, opt.key, opt.filter)}
              label={getLabelFilter(opt.filter, opt)}
              style={{ marginLeft: 10 }}
            />
          )
        }
      });
    }
  }

  function filterModal() {
    return (
      <>
        <Dialog fullScreen TransitionComponent={Transition} open={open} onClose={handleClose} classes={{ paper: classes.paper }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <Grid
              item
              container
              justify='space-around'
              alignItems="center"
              className={classes.painel}
              xs={12}
            >
              <Grid item container justify="flex-start" xs={4}>
              </Grid>
              <Grid item container justify="center" xs={4}>
                {props.title}
              </Grid>
              <Grid item container justify="flex-end" xs={4}>
                <Button
                  className={classes.button}
                  style={{ backgroundColor: 'white', border: '1px solid #000000' }}
                  variant="contained"
                  onClick={() => props.changeCustomFilter(props.type, "Limpar", null)}
                >
                  Limpar
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleClose}
                >
                  Feito
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: 'rgba(242, 242, 242, 1)' }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4} style={{ marginTop: 20, display: "flex", justifyContent: 'center' }}>
                <Autocomplete
                  filterOptions={filterSearchOptions}
                  size="small"
                  disableClearable
                  options={props.option}
                  getOptionLabel={options => options[props.label] && options[props.label]}
                  renderInput={(opt) => <TextField {...opt} style={{ width: 300 }} label={'O que você está procurando?'} variant="outlined" />}
                  onChange={(e, v) => props.changeCustomFilter(props.type, v[props.value], v[props.label])}
                />
              </Grid>
              <Grid item xs={4} style={{ marginTop: 20, display: "flex", justifyContent: 'center' }}>
                <Typography className={classes.selectAll}>Selecionar Todos</Typography>
                <Checkbox style={{ color: 'black' }} onClick={() => SelectAll()} checked={checkedSelectAll()} />
              </Grid>
              <Grid item xs={12} style={{ height: "55vh", marginTop: 25 }}>

                {props.filterOptions.length > 0 &&
                  <Grid container alignContent='center' className={classes.chipPainel} >
                    <Button className={classes.arrowButtonLeft} onClick={handleBtnActionLeft}><KeyboardArrowLeftIcon /></Button>
                    <div className={classes.scrollMenu} style={{ transform: `translate(${translateX}px, 0px)`, width: toolsWidth, minWidth: '70vw' }}>
                      {renderFilterOptions()}
                    </div>
                    <Button className={classes.arrowButtonRight} onClick={handleBtnActionRight}><KeyboardArrowRightIcon /></Button>
                  </Grid>
                }

                <Grid container justify='center' style={{ marginTop: props.filterOptions.length > 0 ? 0 : 35 }}>
                  {currentOptions.map(opt => (
                    opt[props.label] && <div
                      key={opt[props.value]}
                      onClick={() => props.changeCustomFilter(props.type, opt[props.value], opt[props.label])}
                      className={includes(props.type, opt[props.value]) ? classes.optCardSelected : classes.optCard}
                    >
                      <Checkbox
                        key={`${opt[props.value]}-checkbox`}
                        checked={includes(props.type, opt[props.value])}
                        style={{ color: 'black' }}
                      />
                      {opt[props.label]}
                    </div>
                  ))}
                </Grid>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: 'rgba(242, 242, 242, 1)', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              page={getCurrentPage(props.type)}
              count={OptionsNumbers.length}
              siblingCount={0} boundaryCount={1}
              onChange={(e, newPage) => handlePage(props.type, newPage)}
            />
          </DialogActions>
        </Dialog>
      </ >
    )
  }

  return (
    <Card className={classes.cardRoot} >
      <CardContent>
        <Grid container justify="space-between">
          <Typography className={classes.title} gutterBottom>
            {props.title}
          </Typography>
          <IconButton size="small" onClick={handleClickOpen} disabled={!props.option.length > 0}>
            <AddIcon />
          </IconButton>
          {filterModal()}
        </Grid>
        <Divider style={{ marginTop: 8 }} />
        <Grid container justify="center">
          {renderFilterOptions()}
        </Grid>
      </CardContent>
    </Card>
  )
}