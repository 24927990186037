import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  MessageSection,
  MessageText,
  MessageTitle,
  ButtonTransparent,
  ButtonWrapper
} from './style';
import NoAuthorizedButton from '../../components/NoAuthorizedButton/index';
import { maisControleService } from '../../../services/axiosServer';
import { useUser } from '../../../hooks/useUser';
import { Loading } from '../../components/Loading';
import { authGuard } from '../../../guard/AuthGuard';
import  ModalAction from '../ModalAction/index'
export default function RequestPermission() {
  const [loading, setLoading] = useState(true);
  const [isEmailResend, setIsEmailResend] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [lastPermissionRequestDate, setLastPermissionRequest] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [userFetched, setUserFetched] = useState(false);
  const { user, registerUserToAccessControl } = useUser();

  const getUserAccessControl = useCallback(
    async (userLocal) => {
      let userResponse;
      const userId = userLocal?.id_usuario;

      if (userLocal) {
        if (userId !== -1) {
          userResponse = { ...userLocal };
        } else {
          const userSomaAuth = await authGuard.getSomaAuthUser();
          if (userSomaAuth) {
            userResponse = await registerUserToAccessControl(
              userSomaAuth,
              true
            );
          }
        }

        setUserSelected(userResponse);
        return userResponse;
      }
    },
    [registerUserToAccessControl]
  );

  const getUserLastRequest = useCallback(async () => {
    if (user !== null) {
      try {
        setLoading(true);
        const userAccessControl = await getUserAccessControl(user);

        const response = await maisControleService.getLastRequestAuthorization(
          userAccessControl.id_usuario
        );

        setLastPermissionRequest(response?.updated_at);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user && !userFetched) {
      getUserLastRequest();
      setUserFetched(true);
    }
    // eslint-disable-next-line
  }, [user]);

  const modalAction = useMemo(
    () => (
      <ModalAction
        isOpen={modalOpen}
        setCloseModal={setModalOpen}
        title={'Solicitação de permissão'}
        message={
          'Pedido enviado há pouco tempo, agora é só esperar. Você receberá uma notificação via e-mail quando a solicitação for respondida'
        }
      />
    ),
    [modalOpen]
  );

  const handleSendAutorizationEmail = async () => {
    const user = userSelected?.id_usuario_soma_auth;

    if (user) {
      setLoading(true);
      try {
        if (userSelected) {
          const response = await maisControleService.requestUserAuthorization({
            idUsuario: userSelected.id_usuario,
            email: userSelected.email,
            name: userSelected.no_usuario,
            idSomaAuth: userSelected.id_usuario_soma_auth
          });

          setLastPermissionRequest(response.updated_at);
        } else {
          throw Error('User not loaded');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResendEmail = async () => {
    if (lastPermissionRequestDate) {
      try {
        if (!userSelected) throw Error('User not Loaded');

        const validDate =
          new Date(lastPermissionRequestDate).getTime() + 60 * 60 * 24 * 1000; // 24 horas deepois da ultima requisicao

        const actualDate = new Date().getTime();

        if (actualDate >= validDate) {
          const response = await maisControleService.requestUserAuthorization({
            id_usuario: userSelected.id_usuario,
            email: userSelected.email,
            name: userSelected.no_usuario,
            id_soma_auth: userSelected.id_usuario_soma_auth
          });

          setLastPermissionRequest(response.updated_at);
          setIsEmailResend(true);
        } else {
          setModalOpen(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setModalOpen(true);
    }
  };

  const handleRenderAutorizationMessage = useCallback(() => {
    if (loading) {
      return <Loading size={45} />;
    }

    if (lastPermissionRequestDate && !isEmailResend) {
      return (
        <>
          <MessageSection>
            <MessageTitle>{'Solicitação Enviada :)'}</MessageTitle>
            <MessageText>
              Você receberá um e-mail quando sua permissão for concedida pelo
              administrador.
            </MessageText>
            <ButtonWrapper>
              <NoAuthorizedButton
                title="Solicitação enviada!"
                handleClick={() => {
                  return;
                }}
                disabled={true}
              />
              <ButtonTransparent onClick={() => handleResendEmail()}>
                Reenviar solicitação
              </ButtonTransparent>
            </ButtonWrapper>
          </MessageSection>
        </>
      );
    } else if (isEmailResend) {
      return (
        <>
          <MessageSection>
            <MessageTitle>{'Solicitação reenviada Obrigada!'}</MessageTitle>
            <ButtonWrapper>
              <NoAuthorizedButton
                title="Solicitação reenviada"
                handleClick={() => {
                  return;
                }}
                disabled={true}
              />
              <ButtonTransparent onClick={() => handleResendEmail()}>
                Reenvio indispovível no momento
              </ButtonTransparent>
            </ButtonWrapper>
          </MessageSection>
        </>
      );
    } else {
      return (
        <>
          <MessageSection>
            <MessageTitle>
              {'Desculpe, você ainda não tem acesso a esse conteúdo :('}
            </MessageTitle>
            <MessageText>
              Mas, não tem problema! Solicite essa permissão ao administrador
              responsável e aguarde, logo será resolvido
            </MessageText>
            <NoAuthorizedButton
              title="Solicitar Permissão"
              handleClick={() => handleSendAutorizationEmail()}
              disabled={!userSelected}
            />
          </MessageSection>
        </>
      );
    }
    // eslint-disable-next-line
  }, [userSelected, lastPermissionRequestDate, isEmailResend, loading]);

  return (
    <>
      {handleRenderAutorizationMessage()}
      {modalAction}
    </>
  );
}
