export const RANKING_LOADING = 'RANKING_LOADING';
export const RANKING_LOADED = 'RANKING_LOADED';

export const HISTORY_LOADING = 'HISTORY_LOADING';
export const HISTORY_LOADED = 'HISTORY_LOADED';

export const SELLERS_LOADING = 'SELLERS_LOADING';
export const SELLERS_LOADED = 'SELLERS_LOADED';

export const SELLER_LOADING = 'SELLER_LOADING';
export const SELLER_LOADED = 'SELLER_LOADED';

export const INDICATORS_BRAND_LOADING = 'INDICATORS_BRAND_LOADING';
export const INDICATORS_BRAND_LOADED = 'INDICATORS_BRAND_LOADED';

export const INDICATORS_BRAND_EVOLUTION_LOADING = 'INDICATORS_BRAND_EVOLUTION_LOADING';
export const INDICATORS_BRAND_EVOLUTION_LOADED = 'INDICATORS_BRAND_EVOLUTION_LOADED';

export const ORDERS_LOADING = 'ORDERS_LOADING';
export const ORDERS_LOADED = 'ORDERS_LOADED';

export const CLOSED_ORDERS_LOADING = 'CLOSED_ORDERS_LOADING';
export const CLOSED_ORDERS_LOADED = 'CLOSED_ORDERS_LOADED';

export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_LOADED = 'AUTH_LOADED';
export const AUTH_RECOVERY_SUCCESS = 'AUTH_RECOVERY_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR = 'AUTH_CLEAR';

export const BRANDS_SELECTED = 'BRANDS_SELECTED';

export const DATE_SELECTED = 'DATE_SELECTED';

export const CREATE_FILTERS = 'CREATE_FILTERS';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS';

export const PRODUCTS = 'PRODUCTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const ORDER_ITEMS_ORDER_FILTER = 'ORDER_ITEMS_ORDER_FILTER';
export const ORDER_ITEMS = 'ORDER_ITEMS';

export const GRID_ITEMS_SIZE = 'GRID_ITEMS_SIZE';