import React from "react";
import { useStyles } from "./style";
import {
  Divider,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";

export default function FilterDataSwitch(props) {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <Grid container alignItems="center" justify="flex-start" style={{ height: 50 }}>
        <Grid item style={{ marginLeft: 24 }}>
          <Typography className={classes.text}>{props.title}</Typography>
        </Grid>
        <Grid item style={{ marginLeft: 290, marginTop: 10 }}>
          <Switch
            color="primary"
            checked={!!props.filters[props.type][0]}
            onChange={() => {
              if (props.filters[props.type][0]) {
                props.filterData.setFilters({
                  ...props.filters,
                  [props.type]: []
                })
                props.filterData.removeFilterLabel({ id: 1 })
              } else {
                props.filterData.setFilters({
                  ...props.filters,
                  [props.type]: [1]
                })
                props.filterData.addFilterLabel({ id: 1, label: "vitrine", filtro: "vitrine", value: 1 })
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}