import styled from '@emotion/styled';

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;

export const ModalHeader = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  position: relative;
`;

export const ModalBody = styled.div`
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  align-items: center;
`;

export const MessageText = styled.p`
  font-size: 14px;
  color: #666666;
  width: 75%;
  margin-top: auto;
  margin-bottom: auto;
`;

export const ModalButton = styled.button`
  width: 90%;
  padding: 12px 16px;
  font-size: 12px;
  border: none;
  background: #d42f18;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    filter: brightness(0.8);
  }
`;
