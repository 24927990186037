import styled from '@emotion/styled';

export const WrapperPage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 70px);
`;

export const MessageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
`;

export const MessageTitle = styled.h1`
  font-size: 41px;
  font-weight: 700;
  width: 100%;
  padding: 0 0 12px;
`;

export const MessageText = styled.p`
  font-size: 17px;
  font-weight: 400;
  width: 100%;
  padding: 20px 0;
  line-height: 150%;
  margin-bottom: 30px;
`;
