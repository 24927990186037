import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontFamily: 'Flama',
      fontSize: 16,
      color: '#000',
      marginLeft: 15
    },
    media: {
      height: 400,
      width: 240,
      marginTop: 10
    },
    cardText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0em',
      textAlign: 'left'
    },
    modalText: {
      fontSize: 13,
      textAlign: 'Left',
      fontFamily: 'Flama',
      marginTop: 6,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0em'
    },
    infoLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '11px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: 'rgba(255, 255, 255, 1)'
    },
    historyCardText: {
      fontFamily: 'Roboto',
      fontSize: 12,
      marginTop: 3
    }
  }),
  { index: 1 }
);
