import api from "./api";

export const findVotes = async (params = {}) => {
  return api()
    .get(`/v0/vote`, {
      params,
    })
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      return err || [];
    });
};

export const voteProduct = async (data = {}) => {
  return api()
    .post(`/v0/vote`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { error: error, statusCode: 400 };
    });
};

export const updateVote = async (data = {}) => {
  return api()
    .put(`/v0/vote`, data)
    .then((response) => {
      return response.data || [];
    })
    .catch((err) => {
      return err || [];
    });
};

export const importCSV = async (params = {}) => {
  return api()
    .get(`/v0/vote/results`, {
      params,
    })
    .then((response) => {
      return response || [];
    })
    .catch((err) => {
      return err || [];
    });
};

