import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  () => ({
    headerIconBtn: {
      margin: '8px',
      float: 'right'
    },
    swipperSlide: {
      display: 'flex',
      justifyContent: 'center'
    },
    list: {
      padding: 0,
      position: 'relative'
    },
    btnsContainer: {
      width: 200,
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 999
    }
  }),
  { index: 1 }
);
