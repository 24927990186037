export const validEmails = [
  'animale.com',
  'mariafilo.com',
  'farmrio.com',
  'somagrupo.com',
  'crisbarros.com',
  'bynv.com',
  'afabula.com',
  'animaleoro.com',
  'foxtonbrasil.com',
  'offpremium.com',
  'ciahering.com',
  'uds.com'
];
